export const reducers = {
  getSettings(state) {
    return state.settings;
  },

  setSettings(state, action) {
    return {
      ...state,
      settings: {
        ...state.settings,
        ...action.payload,
      },
    };
  },

  backup(state, action) {
    return {
      ...state,
      backup: action.payload,
    };
  },

  backupLoading(state, action) {
    return {
      ...state,
      isBackupLoading: action.payload,
    };
  },

  backupUploading(state, action) {
    return {
      ...state,
      isBackupUploading: action.payload,
    };
  },
};
