export enum EAttrs {
  ON = 'on',
  HIDE = 'hide',
  RO = 'RO',
  NN = 'NN',
  PK = 'PK',
  OFF = 'off',
  GROUP = 'group',
  ADDED = 'added',
  MO = 'M-O',
  OM = 'O-M',
  REF = 'REF',
  FK = 'FK',
  QS = 'qs',
  QE = 'qe',
  LAZY = 'lazy',
  SYS = 'SYS',
  KEY = 'KEY',
  FILTER = 'filter',
  SORT = 'sort',
  ASC = 'asc',
  DESC = 'desc',
  NONE = 'none',
  ASC_NULL_FIRST = 'asc_nulls_first',
  ASC_NULL_LAST = 'asc_nulls_last',
  DESC_NULL_FIRST = 'desc_nulls_first',
  DESC_NULL_LAST = 'desc_nulls_last',
}

export enum EPermissions {
  INSERT = 'insert',
  UPDATE = 'update',
  DELETE = 'delete',
}

export enum EColors {
  VOLCANO = 'volcano',
  GEEKBLUE = 'geekblue',
  PURPLE = 'purple',
  GREEN = 'green',
  DUST = 'dust',
  SUNSET = 'sunset',
  CYAN = 'cyan',
  DEFAULT = 'default',
}

export enum ELocales {
  EN = 'en-US',
  RU = 'ru-RU',
  KZ = 'kk-KZ',
  DE = 'de-DE',
}

export enum ELocaleKeys {
  RU = 'ru',
  EN = 'en',
  KZ = 'kz',
  DE = 'de',
}

export enum EByteType {
  RAW = 1,
  IMAGE,
}

export enum EContentType {
  RAW = 1,
  FILE_MANAGER = 2,
  EDITOR = 3,
}

export enum DbTypes {
  OBJECT = 'OBJECT',
  LIST = 'LIST',
  BYTEA = 'bytea',
  JSONB = 'jsonb',
  TIMESTAMP = 'timestamp',
  TIMESTAMPTZ = 'timestamptz',
  INTEGER4 = '_int4',
  FLOAT8 = '_float8',
  UUID = 'uuid',
  FLOAT = 'float8',
  JSON = 'json',
  DATE = 'date',
  TIME = 'time',
}

export enum EDbCommonOperators {
  EQ = '_eq',
  GT = '_gt',
  GTE = '_gte',
  IN = '_in',
  IS_NULL = '_is_null',
  LT = '_lt',
  LTE = '_lte',
  NEQ = '_neq',
  NIN = '_nin',
}

export enum EDbConditions {
  AND = '_and',
  NOT = '_not',
  OR = '_or',
}

export enum EDataType {
  STRING = 'String',
  INTEGER = 'int',
  BIGINT = 'bigint',
  FLOAT = 'float8',
  BOOLEAN = 'boolean',
  TIMESTAMP = 'timestamp',
  TIMESTAMPTZ = 'timestamptz',
  BPCHAR = 'bpchar',
  DATE = 'date',
  TIME = 'time',
  UUID = 'uuid',
}

export enum EDbOnlyStringBPCharOperators {
  ILIKE = '_ilike',
  IREGEX = '_iregex',
  LIKE = '_like',
  NILIKE = '_nilike',
  NIREGEX = '_niregex',
  NLIKE = '_nlike',
  NREGEX = '_nregex',
  NSIMILAR = '_nsimilar',
  REGEX = '_regex',
  SIMILAR = '_similar'
}

export enum EUnit {
  PX = 'px',
  PERCENT = '%',
  EM = 'em',
  REM = 'rem',
  VW = 'vw',
  VH = 'vh',
  VMIN = 'vmin',
  VMAX = 'vmax',
}
