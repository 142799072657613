import type {
  CrudSettings,
  CrudTableSettings,
  ICrudRangeSettings,
} from '@/components/Tools/common/cruds/types';
import {EAttrs, EDbCommonOperators, DbTypes} from '@/types/enums';
import type {TAntTreeNode} from '@/types/crud';
import type {Key} from 'rc-tree/lib/interface';
import type {TTableValuesState} from '@/components/common/table-values/types';
import {cloneDeep, isEmpty} from 'lodash';
import type {SelectValue} from 'antd/es/select';
import type {Moment} from 'moment';
import {wrapSlashes} from '@/components/Tools/Crud/utils/index';

type QueryBuilderType = {
  variableList: TTableValuesState[];
  rangeSettings: ICrudRangeSettings;
  globalRangeAbsolutePeriod: [Moment, Moment];
  crudSettings?: CrudSettings;
  fieldTableSettings?: { conditions: CrudTableSettings['conditions'] };
};

export class QueryBuilder {
  variableList: TTableValuesState[];
  rangeSettings: ICrudRangeSettings;
  globalRangeAbsolutePeriod: [Moment, Moment];
  crudSettings?: CrudSettings;
  fieldTableSettings?: { conditions: CrudTableSettings['conditions'] };

  constructor(arguments_: QueryBuilderType) {
    this.crudSettings = arguments_.crudSettings;
    this.variableList = arguments_.variableList;
    this.rangeSettings = arguments_.rangeSettings;
    this.globalRangeAbsolutePeriod = arguments_.globalRangeAbsolutePeriod;
    this.fieldTableSettings = arguments_.fieldTableSettings;
  }

  buildSortingQuery(sortingQueryString?: string): string {
    if (sortingQueryString) {
      const sortingQueryStringAsArray = sortingQueryString.split(',');
      // если в запросе больше одного уровня вложенности
      if (sortingQueryStringAsArray.length > 2) {
        let resultSortingStringQuery = '';
        let closeBrackets = '';
        for (let i = 0; i <= sortingQueryStringAsArray.length - 2; i++) {
          const currentItem = sortingQueryStringAsArray[i];
          closeBrackets += i === 0 ? '' : '}';
          resultSortingStringQuery +=
            i === sortingQueryStringAsArray.length - 2
              ? `${currentItem},${closeBrackets}`
              : `${currentItem} :{`;
        }
        return resultSortingStringQuery;
      }
      return sortingQueryString;
    }
    const enabledRecords = (this.crudSettings?.tableSettings?.sorting ?? []).filter((record) =>
      record.attrs.includes(EAttrs.ON),
    );
    if (!enabledRecords.length) return '';

    const recordsWithConditions = enabledRecords.filter((record) => record.attrs.length > 1);
    if (!recordsWithConditions.length) return '';

    let queryString = '';
    recordsWithConditions.forEach((record) => {
      queryString += `${record.name}: ${record.order}, `;
    });

    return queryString;
  }

  buildPaginationQuery(isPaginationEnable: boolean, rowsPerPage: number, offset: number): string {
    return isPaginationEnable ? `limit: ${rowsPerPage}, offset: ${offset}` : '';
  }

  buildConditionsQuery(onChangeVariable?: boolean, softDelete?: boolean): string {
    const treeData = this.fieldTableSettings
      ? cloneDeep(this.fieldTableSettings?.conditions?.data ?? [])
      : cloneDeep(this.crudSettings?.tableSettings?.conditions?.data ?? []);

    const parsedFieldsSettings =
      this.crudSettings?.fieldsSettings.map((fieldSetting) => JSON.parse(fieldSetting.value)) ?? [];
    const hasSystemField =
      parsedFieldsSettings.some(
        (fieldSetting) =>
          fieldSetting.state.name === '_sys' && fieldSetting.state.type === DbTypes.JSONB,
      ) && softDelete;

    if (hasSystemField) {
      const expandedKeys = this.crudSettings?.tableSettings?.conditions?.expandedKeys ?? [];
      const checkedKeys = this.crudSettings?.tableSettings?.conditions?.checkedKeys ?? [];
      treeData.forEach((node) => {
        if (expandedKeys.includes(node.key)) node.expanded = true;
      });

      this.setNodeExpandedAndChecked(treeData, expandedKeys, checkedKeys);
      const expandedNodes = this.filterNodesByExpanded(treeData, onChangeVariable);
      const conditionStringArray = this.buildConditionStringArray(expandedNodes);
      conditionStringArray.push(`_not: {_sys: {_has_key: ${wrapSlashes('deleted')}}}`);

      return `_and: {${conditionStringArray.join()}}`;
    }

    // Проверка на наличие дерева условий
    if (!treeData.length) return '';

    // Проверка на наличие раскрытых узлов
    const expandedKeys = this.fieldTableSettings
      ? this.fieldTableSettings?.conditions?.expandedKeys ?? []
      : this.crudSettings?.tableSettings?.conditions?.expandedKeys ?? [];
    if (!expandedKeys.length) return '';

    const checkedKeys = this.fieldTableSettings
      ? this.fieldTableSettings?.conditions?.checkedKeys ?? []
      : this.crudSettings?.tableSettings?.conditions?.checkedKeys ?? [];

    treeData.forEach((node) => {
      if (expandedKeys.includes(node.key)) node.expanded = true;
    });
    if (!treeData.some((node) => node.expanded)) return '';

    this.setNodeExpandedAndChecked(treeData, expandedKeys, checkedKeys);
    const expandedNodes = this.filterNodesByExpanded(treeData, onChangeVariable);
    const conditionStringArray = this.buildConditionStringArray(expandedNodes);

    return `_and: {${conditionStringArray.join()}}`;
  }

  buildClientConditionsQuery(dataObject: any, queryString: string = ''): string {
    for (const key in dataObject) {
      if (dataObject[key].hasOwnProperty('value') && dataObject[key].hasOwnProperty('operation')) {
        queryString += dataObject[key].value.length ? `${key}: {${dataObject[key].operation}: ${wrapSlashes(dataObject[key].value)}},` : '';
      } else if (!isEmpty(dataObject[key])) {
        queryString += `${key}: {${this.buildClientConditionsQuery(dataObject[key], queryString)}}`
      }
    }
    return queryString;
  }

  setNodeExpandedAndChecked(
    treeData: TAntTreeNode[],
    expandedKeys: Key[],
    checkedKeys: Key[],
  ): void {
    for (const node of treeData) {
      if (expandedKeys.includes(node.key)) node.expanded = true;
      if (checkedKeys.includes(node.key)) node.checked = true;
      if (node.children) {
        this.setNodeExpandedAndChecked(node.children, expandedKeys, checkedKeys);
      }
    }
  }

  filterNodesByExpanded(treeData: TAntTreeNode[], onChangeVariable?: boolean): TAntTreeNode[] {
    return treeData.filter((node) => {
      // Если узел имеет дочерние узлы, рекурсивно выполнить функцию для всех дочерних узлов
      if (node.children) {
        node.children = this.filterNodesByExpanded(node.children, onChangeVariable);
      }

      if (node.isLeaf && node.checked) {
        if (!node.isVariable && node.value) {
          const titleAsArray = node.title.split('(');
          node.title = titleAsArray[0];
          return true;
        }
        if (node.isVariable) {
          let value: TTableValuesState['values'] = '';
          if (node.value === 'function.range.left' || node.value === 'function.range.right') {
            const index = node.value === 'function.range.left' ? 0 : 1;
            const dateTime = this.rangeSettings?.isRangeUseGlobalProperty
              ? this.globalRangeAbsolutePeriod?.[index]
              : this.rangeSettings?.rangeAbsolutePeriod?.[index];
            if (dateTime) {
              value = dateTime.format('YYYY-MM-DD HH:mm:ss');
            }
          } else {
            const variable = this.variableList?.find(
              (listVariable) => listVariable.name === node.variableName,
            );
            if (variable) {
              // if (onChangeVariable) {
              value = variable.values;
              // } else {
              //   switch (variable.type) {
              //     case TableValuesStateType.VALUE:
              //     case TableValuesStateType.CUSTOM:
              //       value = variable.values ?? '';
              //       break;
              //     case TableValuesStateType.QUERY:
              //       value = variable.textQuery ?? '';
              //   }
              // }
            }
          }

          if (value) {
            const titleAsArray = node.title.split('(');
            node.title = titleAsArray[0];
            node.value = <string>value;
            return true;
          }
        }
      }
      return node.expanded;
    });
  }

  buildConditionStringArray(treeData: TAntTreeNode[]): string[] {
    return treeData.map((node) => {
      if (Array.isArray(node.value)) node.value = `[${node.value}]`;
      else {
        if (node.title !== EDbCommonOperators.IS_NULL) {
          node.value = `\\"${node.value}\\"`;
        }
      }
      return node.isLeaf
        ? `${node.title}: ${node.value}`
        : `${node.title}: {${node.children && this.buildConditionStringArray(node.children)}}`;
    });
  }

  buildRowsCountQueryString(tableName: SelectValue, conditionsQuery: string) {
    return `{ ${tableName}_aggregate(where: { ${conditionsQuery} }) { aggregate { count } } }`;
  }

  buildQueryParams(paginationQuery: string, sortingQuery: string, conditionsQuery: string): string {
    if (!sortingQuery && !paginationQuery && !conditionsQuery) return '';
    return `(${
      paginationQuery ? paginationQuery + ',' : ''
    } order_by: {${sortingQuery}}, where: {${conditionsQuery}})`;
  }

  replaceVariablesWithValues(treeData: TAntTreeNode[]) {
    treeData.forEach((node) => {
      if (node.isVariable) {
        const nodeValue = node.value;
        const variable = this.variableList?.find((listVariable) => listVariable.name === nodeValue);
        if (variable) {
          let value: any;
          switch (variable.type) {
            case 'value':
              value = (<string>variable.values)?.trim() ?? '';
              break;
            case 'custom':
              value = variable.options ?? '';
              break;
            case 'query':
              value = (<string>variable.textQuery)?.trim() ?? '';
          }
          node.value = value;
        }
      }
      if (node.children) this.replaceVariablesWithValues(node.children);
    });
  }
}
