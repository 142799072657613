import {
  _clearNewToolReducer,
  _clearSaveToolReducer,
  _setSelectToolReducer,
  _setToolModulesReducer,
  _setToolReducer,
  _setToolsReducer,
  _toolsAsync,
  toolsAsync,
  _setUsersAndGroupsReducer,
} from '@/models/tools/actions';
import { selectToolState, toolState } from '@/models/tools/selectors';
import { state } from '@/models/tools/state';
import { users, groups } from '@/requests/auth';
import { tool, tools, toolCreate, toolUpdate, toolDelete } from '@/requests/tools';
import { normalizePath } from '@/third/utils';
import { routes } from '@/utils/routes/path';
import { notification } from 'antd';
import { isEmpty } from 'lodash';
import { getLocale } from 'umi';

export default {
  namespace: 'tools',
  state,
  reducers: {
    setStateReducer(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    setToolReducer(state, action) {
      const data = action.payload;
      let settings = {};
      switch (typeof data?.settings) {
        case 'string':
          settings = JSON.parse(data?.settings || '{}');
          break;
        default:
          settings = data?.settings ?? {};
          break;
      }

      const result = {
        ...state,
        tool: { ...action.payload, settings },
      };

      return result;
    },
    setSaveToolReducer(state, action) {
      return {
        ...state,
        isChange: true,
        saveTool: { ...state.saveTool, ...action.payload },
      };
    },
    setToolsReducer(state, action) {
      return {
        ...state,
        tools: [...action.payload],
      };
    },
    setToolModulesReducer(state, action) {
      return {
        ...state,
        toolModules: [...action.payload],
      };
    },
    clearToolReducer(state, action) {
      return {
        ...state,
        tool: {},
      };
    },
    clearSaveToolReducer(state, action) {
      return {
        ...state,
        isChange: false,
        saveTool: {},
      };
    },
    clearToolSettings(state, action) {
      return {
        ...state,
        tool: {
          ...state.tool,
          settings: action.payload,
        },
      };
    },
    setNewToolReducer(state, action) {
      return {
        ...state,
        newTool: { ...state.newTool, ...action.payload },
      };
    },
    clearNewToolReducer(state, action) {
      return {
        ...state,
        newTool: {},
      };
    },
    setSelectToolReducer(state, action) {
      return {
        ...state,
        tool: {},
        saveTool: {},
        isChange: false,
        selectTool: action.payload,
      };
    },
    setUsersAndGroupsReducer(state, action) {
      return {
        ...state,
        usersAndGroups: action.payload,
      };
    },
    setAccessDefaultValue(state, action) {
      return {
        ...state,
        usersAndGroups: { ...state.usersAndGroups, accessDefaultValue: action.payload },
      };
    },
  },

  effects: {
    ['setQuery']: [function* () {}, { type: 'takeLatest' }],
    /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ *toolAsync ~~~ */

    toolAsync: [
      function* ({ payload }, { call, put }) {
        try {
          const result = yield call(tool, payload);
          const settings = isEmpty(result.settingsJson)
            ? result.settings
            : JSON.parse(result.settingsJson);
          yield put(
            _setToolReducer({
              ...result,
              settings,
            }),
          );
        } catch (error) {
          notification.error({
            description: error.response.errors[0].message,
            message: 'Ошибка!',
          });
        }
      },
      { type: 'takeLatest' },
    ],

    /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ *toolsAsync ~~~ */

    *toolsAsync({ payload }, { call, put }) {
      try {
        const result = yield call(tools, { all: true, ...(payload || {}) });
        yield put(_setToolsReducer(result));
      } catch (error) {
        notification.error({
          description: error.response.errors[0].message,
          message: 'Ошибка!',
        });
      }
    },

    *toolModulesAsync(action, { call, put }) {
      try {
        const result = yield call(toolModules);
        yield put(_setToolModulesReducer(result));
      } catch (error) {
        notification.error({
          description: error.response.errors[0].message,
          message: 'Ошибка!',
        });
      }
    },

    *toolCreateAsync(action, { call, put }) {
      try {
        const result = yield call(toolCreate, { ...action.payload, enabled: true });

        if (action.payload?.isClone) {
          yield call(toolUpdate, action.payload);
        }

        if (result) {
          notification.success({
            description: action.payload.name,
            message: 'Добавлено!',
          });
        } else {
          notification.error({
            description: action.payload.name,
            message: 'Не добавлено!',
          });
        }
        yield put(_clearNewToolReducer({}));
        yield put(_toolsAsync());
        if (result?.name) yield put(_setSelectToolReducer(result.name));
        return true;
      } catch (error) {
        notification.error({
          description: error.response.errors[0].message,
          message: 'Ошибка!',
        });
        return false;
      }
    },

    /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ *toolUpdateAsync ~~~ */

    *toolUpdateAsync(action, { call, put, select }) {
      try {
        const selectTool = action.payload.name || (yield select(selectToolState));
        const currentSettings = yield select(toolState);

        const payload = { name: selectTool, ...action.payload, locale: action.payload.locale };
        const result = yield call(toolUpdate, payload);

        notification.success({
          description: action.payload.name,
          message: result ? 'Изменено!' : 'Не изменено!',
        });

        yield put(_setToolReducer({ ...currentSettings, ...result }));
        yield put(_clearSaveToolReducer());
        const locale = getLocale();
        yield put(_toolsAsync({ locale }));
        return true;
      } catch (error) {
        notification.error({
          description: error.response.errors[0].message,
          message: 'Ошибка!',
        });
        return false;
      }
    },

    /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ *toolCancelAsync ~~~ */

    *toolCancelAsync(action, { put, select }) {
      try {
        const currentSettings = yield select(toolState);
        yield put(
          _setToolReducer({
            ...currentSettings,
            settings: JSON.parse(currentSettings.settingsJson) ?? {},
          }),
        );
        yield put(_clearSaveToolReducer());
        const locale = getLocale();
        yield put(_toolsAsync({ locale }));

        notification.success({
          description: action.payload.name,
          message: 'Отменено!',
        });

        return true;
      } catch (error) {
        notification.error({
          description: error.response.errors[0].message,
          message: 'Ошибка!',
        });
        return false;
      }
    },

    /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ *toolDeleteAsync ~~~ */

    *toolDeleteAsync(action, { call, put }) {
      try {
        const result = yield call(toolDelete, action.payload);
        notification.success({
          description: action.payload.name,
          message: result ? 'Удалено!' : 'Не удалено!',
        });
        yield put(toolsAsync());
        return result;
      } catch (error) {
        notification.error({
          description: error.response.errors[0].message,
          message: 'Ошибка!',
        });
      }
    },

    getUsersAndGroupsAsync: [
      function* ({ payload }, { call, put }) {
        try {
          const allUsers = yield call(users);
          const allGroups = yield call(groups);
          const accessDefaultValue = [];

          const usersData = allUsers.map((user) => ({
            value: JSON.stringify({ id: user.id, name: user.username, type: 'role' }),
            label: user.username,
            roles: user.roles,
          }));
          const groupsData = allGroups.map((group) => ({
            value: JSON.stringify({ id: group.id, name: group.name, type: 'group' }),
            label: group.name,
            roles: group.roles,
          }));

          for (const user of usersData) {
            const findTool = user.roles.find((role) => role.name === `tool_${payload.selectTool}`);
            if (findTool) {
              accessDefaultValue.push(user.value);
            }
          }

          for (const group of groupsData) {
            const findTool = group.roles.find((role) => role.name === `tool_${payload.selectTool}`);
            if (findTool) {
              accessDefaultValue.push(group.value);
            }
          }

          yield put(
            _setUsersAndGroupsReducer({
              usersData,
              groupsData,
              accessDefaultValue,
              noRoles: !accessDefaultValue.length,
            }),
          );
        } catch (error) {
          notification.error({
            message: error.message,
          });
          console.log(error);
        }
      },
      { type: 'takeLatest' },
    ],
  },

  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(({ pathname }) => {
        if (pathname === normalizePath(routes.tools.admin.toolsSettings)) {
          const locale = getLocale();
          dispatch(_toolsAsync({ locale }));
        }
      });
    },
  },
};
