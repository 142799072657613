// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/home/mes/myagent/cache/front/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/home/mes/myagent/_work/1/s/src/pages/ConfigProvider'), loading: LoadingComponent}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/home/mes/myagent/_work/1/s/src/pages/AppRoot'), loading: LoadingComponent}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/home/mes/myagent/_work/1/s/src/pages/AppLoad'), loading: LoadingComponent}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/home/mes/myagent/_work/1/s/src/pages/ThemeProvider'), loading: LoadingComponent})],
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__CustomLayout' */'/home/mes/myagent/_work/1/s/src/layouts/CustomLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/install",
        "access": "canInstaller",
        "routes": [
          {
            "path": "/install/SSO-settings",
            "name": "auth-broker",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tools__System__AuthBrokerSettingsPage' */'/home/mes/myagent/_work/1/s/src/pages/Tools/System/AuthBrokerSettingsPage'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "path": "/errors",
        "name": "errors",
        "icon": "smile",
        "access": "isError",
        "routes": [
          {
            "path": "/errors/sso-unavailable",
            "name": "auth-broker",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Errors__SsoUnavailable' */'/home/mes/myagent/_work/1/s/src/pages/Errors/SsoUnavailable'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/errors/api-unavailable",
            "name": "api-service",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Errors__ApiUnavailable' */'/home/mes/myagent/_work/1/s/src/pages/Errors/ApiUnavailable'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ],
        "hideInMenu": true
      },
      {
        "path": "/admin",
        "name": "admin",
        "access": "canAdmin",
        "icon": "control",
        "routes": [
          {
            "path": "/admin/global-settings",
            "name": "global",
            "icon": "star",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tools__Admin__GlobalSettingsPage' */'/home/mes/myagent/_work/1/s/src/pages/Tools/Admin/GlobalSettingsPage'), loading: LoadingComponent}),
            "access": "canAdmin",
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/hocs/WithAdminAccess'), loading: LoadingComponent})],
            "exact": true
          },
          {
            "path": "/admin/navigation-settings",
            "name": "menu",
            "icon": "folder-open",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tools__Admin__NavigationSettingsPage' */'/home/mes/myagent/_work/1/s/src/pages/Tools/Admin/NavigationSettingsPage'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/hocs/WithAdminAccess'), loading: LoadingComponent})],
            "exact": true
          },
          {
            "path": "/admin/tools-settings",
            "name": "tools",
            "icon": "tool",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tools__Admin__ToolSettingsPage' */'/home/mes/myagent/_work/1/s/src/pages/Tools/Admin/ToolSettingsPage'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/hocs/WithAdminAccess'), loading: LoadingComponent})],
            "exact": true
          },
          {
            "path": "/admin/authorization-settings",
            "name": "auth",
            "icon": "unlock",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tools__Admin__AuthSettingsPage' */'/home/mes/myagent/_work/1/s/src/pages/Tools/Admin/AuthSettingsPage'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/hocs/WithAdminAccess'), loading: LoadingComponent})],
            "exact": true
          },
          {
            "path": "/admin/notification-settings",
            "name": "notifications",
            "icon": "notification",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tools__Admin__NotificationSettingsPage' */'/home/mes/myagent/_work/1/s/src/pages/Tools/Admin/NotificationSettingsPage'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/hocs/WithAdminAccess'), loading: LoadingComponent})],
            "exact": true
          }
        ]
      },
      {
        "path": "/system",
        "name": "system",
        "access": "canSystem",
        "icon": "setting",
        "routes": [
          {
            "path": "/system/SSO-settings",
            "name": "auth-broker",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tools__System__AuthBrokerSettingsPage' */'/home/mes/myagent/_work/1/s/src/pages/Tools/System/AuthBrokerSettingsPage'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/hocs/WithSystemAccess'), loading: LoadingComponent})],
            "exact": true
          },
          {
            "path": "/system/db-settings",
            "name": "database",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tools__System__SystemDatabaseSettingsPage' */'/home/mes/myagent/_work/1/s/src/pages/Tools/System/SystemDatabaseSettingsPage'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/hocs/WithSystemAccess'), loading: LoadingComponent})],
            "exact": true
          },
          {
            "path": "/system/DBS-int-settings",
            "name": "hasura",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tools__System__HasuraSettingsPage' */'/home/mes/myagent/_work/1/s/src/pages/Tools/System/HasuraSettingsPage'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/hocs/WithSystemAccess'), loading: LoadingComponent})],
            "exact": true
          },
          {
            "path": "/system/Ds-int-settings",
            "name": "grafana",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tools__System__GrafanaSettingsPage' */'/home/mes/myagent/_work/1/s/src/pages/Tools/System/GrafanaSettingsPage'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/hocs/WithSystemAccess'), loading: LoadingComponent})],
            "exact": true
          },
          {
            "path": "/system/Rs-ing-settings",
            "name": "report",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tools__System__ReportSettingsPage' */'/home/mes/myagent/_work/1/s/src/pages/Tools/System/ReportSettingsPage'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/hocs/WithSystemAccess'), loading: LoadingComponent})],
            "exact": true
          },
          {
            "path": "/system/DMS-int-settings",
            "name": "document-management",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tools__System__DocumentManagementSettingsPage' */'/home/mes/myagent/_work/1/s/src/pages/Tools/System/DocumentManagementSettingsPage'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/hocs/WithSystemAccess'), loading: LoadingComponent})],
            "exact": true
          },
          {
            "path": "/system/MS-int-settings",
            "name": "mailing",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tools__System__MailingSettingsPage' */'/home/mes/myagent/_work/1/s/src/pages/Tools/System/MailingSettingsPage'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/hocs/WithSystemAccess'), loading: LoadingComponent})],
            "exact": true
          },
          {
            "path": "/system/CES-int-settings",
            "name": "logger",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tools__System__LoggerSettingsPage' */'/home/mes/myagent/_work/1/s/src/pages/Tools/System/LoggerSettingsPage'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/hocs/WithSystemAccess'), loading: LoadingComponent})],
            "exact": true
          }
        ]
      },
      {
        "path": "/tools/:id",
        "name": "tools",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Tools' */'/home/mes/myagent/_work/1/s/src/pages/Tools'), loading: LoadingComponent}),
        "icon": "tool",
        "routes": [
          {
            "exact": true
          }
        ]
      },
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "path": "/user",
            "routes": [
              {
                "name": "login",
                "path": "/user/login",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Login' */'/home/mes/myagent/_work/1/s/src/pages/user/Login'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "path": "/about",
        "name": "about",
        "access": "canSystem",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__AboutPage' */'/home/mes/myagent/_work/1/s/src/pages/AboutPage'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/user-profile",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserProfilePage' */'/home/mes/myagent/_work/1/s/src/pages/UserProfilePage'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__HomePage' */'/home/mes/myagent/_work/1/s/src/pages/HomePage'), loading: LoadingComponent}),
        "name": "main",
        "hideInMenu": true,
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/home/mes/myagent/_work/1/s/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
