export const reducers = {
  setSettings(state, action) {
    return {
      ...state,
      settings: {
        ...state.settings,
        ...action.payload,
      },
    };
  },

  setTables(state, { payload }) {
    return {
      ...state,
      tables: payload,
    };
  },

  setTable(state, { payload }) {
    return {
      ...state,
      selectedTable: payload,
    };
  },

  setViewerTable(state, { payload }) {
    return {
      ...state,
      viewerTable: {
        ...state.viewerTable,
        fields: payload,
      },
    };
  },

  clearViewerTable(state, { payload }) {
    return {
      ...state,
      viewerTable: {
        ...state.viewerTable,
        fields: [],
      },
    };
  },

  setMatrix(state, { payload }) {
    return {
      ...state,
      viewerMatrix: payload,
    };
  },

  setMutations(state, { payload }) {
    return {
      ...state,
      mutations: payload,
    };
  },
};
