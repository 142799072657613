export const state = {
  name: '',
  companyInfo: {
    name: '',
    launchDate: new Date(),
  },
  appName: 'MESone',
  footer: '',
  versions: {
    front: '',
    back: '',
  },
  server: { time: null },
  navigation: [],
};
