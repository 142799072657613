import { client } from './client';
import type { RsReportFormat } from '@/types';
import { gql } from 'graphql-request';
import {TRsServiceInfoResponse} from "@/types/store/report";

type reportType = {
  label: string;
  uri: string;
  description: string;
  resourceType: string;
};

type reportSettingsType = {
  enabled: boolean;
  endpoint: string;
  username: string;
  password: string;
};

export function rsReport(props: {
  format?: RsReportFormat;
  toolName: string;
  uri: string;
  params?: string;
}) {
  const query = gql`
    query ($format: RsReportFormat, $toolName: String!, $uri: String!${
      props.params !== '{}' ? ', $params: String' : ''
    }) {
      rsReport(format: $format, toolName: $toolName, uri: $uri${
        props.params !== '{}' ? ', params: $params' : ''
      }) {
        data
        contentType
      }
    }
  `;
  return new Promise<reportType>((resolve, reject) => {
    client()()
      .request(query, props)
      .then((data: any) => resolve(data?.rsReport))
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function rsFolders(props: any) {
  const query = gql`
    query ($folderUri: String, $recursive: Boolean) {
      rsFolders(folderUri: $folderUri, recursive: $recursive) {
        label
        description
        uri
        resourceType
      }
    }
  `;
  return new Promise<reportType[]>((resolve, reject) => {
    client()()
      .request(query, props)
      .then((data: any) => resolve(data?.rsFolders))
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function rsReports(props: any) {
  const query = gql`
    query ($folderUri: String) {
      rsReports(folderUri: $folderUri) {
        label
        description
        uri
        resourceType
      }
    }
  `;
  return new Promise<reportType[]>((resolve, reject) => {
    client()()
      .request(query, props)
      .then((data: any) => resolve(data?.rsReports))
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function rsSettings(props: any) {
  const query = gql`
    query {
      rsSettings {
        enabled
        endpoint
        username
      }
    }
  `;
  return new Promise<reportSettingsType>((resolve, reject) => {
    client()()
      .request(query)
      .then((data: any) => resolve(data?.rsSettings))
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function rsSetSettings(props: reportSettingsType) {
  const query = gql`
    mutation ($enabled: Boolean, $endpoint: String, $username: String, $password: String) {
      rsSetSettings(
        enabled: $enabled
        endpoint: $endpoint
        username: $username
        password: $password
      ) {
        enabled
        endpoint
        username
      }
    }
  `;
  return new Promise((resolve, reject) => {
    client()()
      .request(query, props)
      .then((data: any) => resolve(data?.rsSetSettings))
      .catch((error: any) => reject(error));
  });
}

export function rsTestConnection(endpoint: string) {
  const variables = { endpoint };
  const query = gql`
    query ($endpoint: String!) {
      rsTestConnection(endpoint: $endpoint)
    }
  `;
  return new Promise<reportSettingsType>((resolve, reject) => {
    client()()
      .request(query, variables)
      .then((data: any) => resolve(data?.rsTestConnection))
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function rsTestService(props = undefined) {
  const query = gql`
    query {
      rsTestService
    }
  `;
  return new Promise<reportSettingsType>((resolve, reject) => {
    client()()
      .request(query, props)
      .then((data: any) => resolve(data?.rsTestService))
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function rsServiceInfo() {
  const query = gql`
    query {
      rsServiceInfo {
        data {
          build
          dateFormatPattern
          datetimeFormatPattern
          edition
          version
        }
        message
        success
      }
    }
  `;

  return new Promise<{ data: TRsServiceInfoResponse }>((resolve, reject) => {
    client()()
      .request(query)
      .then((data: { [x: string]: { data: TRsServiceInfoResponse; }  }) => resolve(data?.['rsServiceInfo']))
      .catch((error: any) => reject(error));
  });
}
