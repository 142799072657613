import type { IGrafanaState } from '@/types/store/grafana';

export const state: IGrafanaState = {
  dashboard: [],
  dashboards: [],
  folders: [],
  foldersIsLoading: false,
  settings: {
    internal: '',
    external: '',
    baseApiUri: 'board',
    apiKey: '',
    enabled: false,
  },
  systemInfo: {
    database: ''
  },
};
