import { ThemeColor, ThemeStyle, Layout } from '@/types/theme';

export interface IGlobalStateSettings {
  logoImageUrl: string | ArrayBuffer | null;
  logoEnabled: boolean;
  nameEnabled: boolean;
  name: string;
  description: string;
  langSelectionEnabled: boolean;
  color: ThemeColor;
  style: ThemeStyle;
  navTheme: 'light' | 'dark';
  layout: Layout;
  rankEnabled: boolean;
  rankVisibilityEnabled: boolean;
  rankValueType: 'absolute' | 'relative';
  rangePresets: string;
  rangeRelativeValue: string;
  rangeAbsoluteValue: string;
  rangeRelativeSelectValue: number;
  defaultLocale: string;
  optionLanguages: string;
}

export const state: IGlobalStateSettings = {
  logoImageUrl: null,
  logoEnabled: false,
  nameEnabled: false,
  name: '',
  description: '',
  langSelectionEnabled: true,
  color: 'default',
  style: 'light',
  navTheme: 'light',
  layout: 'side',
  rankEnabled: false,
  rankVisibilityEnabled: false,
  rankValueType: 'absolute',
  rangePresets: '[]',
  rangeRelativeValue: '[]',
  rangeAbsoluteValue: '[]',
  rangeRelativeSelectValue: 1,
  defaultLocale: 'ru-RU',
  optionLanguages: '[]',
};
