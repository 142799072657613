import { state } from '@/models/global/state';
import { effects } from '@/models/global/effects';
import { subscriptions } from '@/models/global/subscriptions';
import { reducers } from '@/models/global/reducers';

export default {
  namespace: 'global',
  state,
  effects,
  subscriptions,
  reducers,
};
