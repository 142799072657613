import { rsServiceInfo } from '@/requests/reports';
import { notification } from 'antd';

export default {
  namespace: 'rs',

  state: {
    systemInfo: {
      build: "",
      dateFormatPattern: "",
      datetimeFormatPattern: "",
      edition: "",
      version: ""
    },
  },

  reducers: {
    setRsSystemInfo(state, action) {
      return {
        ...state,
        systemInfo: action.payload,
      };
    },
  },

  effects: {
    *getRsSystemInfoAsync({ payload }, { call, put }) {
      try {
        const systemInfo = yield call(rsServiceInfo);
        if (!systemInfo.success) {
          notification.error({
            message: payload.message,
          });
        } else {
          yield put({ type: 'setRsSystemInfo', payload: systemInfo.data });
        }
      } catch (error) {
        notification.error({
          message: payload.message,
        });
      }
    },
  },
};
