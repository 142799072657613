import {cloneDeep, intersectionBy, isArray, isEmpty, isPlainObject, isString, pick, toLower} from 'lodash';
import type {TField, TFields, TFieldsForUpdate, TSelectedTable, TSelectedTableField} from '@/types/crud';
import {DbTypes, EAttrs, EByteType, EColors, EDataType} from '@/types/enums';
import type {SelectValue} from 'antd/es/select';
import type {MouseEvent} from 'react';
import type {
  CrudSettings,
  CrudTableSettings,
  CrudViewerFieldsSettings,
  ICrudRangeSettings,
} from '@/components/Tools/common/cruds/types';
import type {Layout} from 'react-grid-layout';
import type {Moment} from 'moment';
import moment from 'moment';
import type {TTableValuesState} from '@/components/common/table-values/types';
import {QueryBuilder} from '@/components/Tools/Crud/utils/QueryBuilder';
import type {TSortingRules} from '@/components/Tools/Crud/Viewer/types';
import type {TFieldMeta, TPartialField} from '@/components/Tools/Crud/utils/types';
import type {
  TConditionalFormattingRulesState
} from '@/components/Tools/common/cruds/ui/CrudConditionalFormatting/types';
import {getLocale} from '@@/plugin-locale/localeExports';
import {
  DOUBLE_QUOTE,
  DOUBLE_QUOTE_REPLACER,
  ESCAPED_DOUBLE_QUOTE,
  ESCAPED_DOUBLE_QUOTE_PLACEHOLDER,
  ESCAPED_DOUBLE_QUOTE_PLACEHOLDER_REPLACER,
  FIELD_NAME_SYS
} from '@/components/Tools/Crud/utils/constants';
import {
  appStoreOutlined,
  checkFalse,
  checkTrue,
  fileImageOutlined,
  fileTextOutlined
} from '@/components/Tools/Crud/utils/icons';

export const getTableSettings = (settings: CrudSettings): CrudTableSettings => {
  const initialSettings: CrudTableSettings = {
    name: '',
    permissions: {insert: false, update: false, delete: false},
    form: {
      dimensions: {
        minWidth: 500,
        maxWidth: 500,
        minHeight: 500,
        maxHeight: 500,
        columnsQuantity: 12,
      },
    },
    export: {
      csv: false,
      json: false,
      pdf: false,
      xlsx: false,
    },
    decorate: {
      fitAndFill: false,
      rowHeight: 30,
      fix: {
        rowTop: {enable: false, quantity: 1},
        colLeft: {enable: false, quantity: 1},
        colRight: {enable: false, quantity: 1},
      },
    },
    pagination: {
      enable: false,
      rowsPerPage: 0,
    },
    primaryKey: '',
    foreignKey: '',
    toolName: null,
    axisYValue: null,
    nestingLevel: 0,
    conditions: {
      data: [],
      expandedKeys: [],
      checkedKeys: [],
    },
    sorting: [],
    range: {},
    softDelete: false,
  };
  return settings.tableSettings ?? initialSettings;
};

const getParsedLabel = (local = 'ru-RU', label?: string) => {
  try {
    return label && JSON.parse(label)[local];
  } catch (error) {
    return label;
  }
};

export const findRule = (rules: any, key: string) => {
  if (!rules) {
    return;
  }

  for (const rule of rules) {
    // Test current object
    if (rule.key === key) {
      return rule;
    }
    // Test children recursively
    const child: any = findRule(rule.children, key);
    if (child) {
      return child;
    }
  }
};

const resetOrder = (rules: any, fieldName: string) => {
  if (!rules) {
    return;
  }

  for (const rule of rules) {
    // Test current object
    if (rule.key !== fieldName) {
      rule.order = EAttrs.NONE;
    }
    // Test children recursively
    const child: any = resetOrder(rule.children, fieldName);
    if (child) {
      return child;
    }
  }
};

export const getFieldsData = (
  settings: CrudViewerFieldsSettings,
  table: SelectValue | string,
  primaryKey: string | null,
) => {
  const fieldsData = settings.reduce((result, field) => {
    if (field.value.state.attributes.data.includes(EAttrs.ON)) {
      return [
        ...result,
        {
          type: field.value.state.type,
          name: field.value.state.name,
          tableName: field.value.state.tableName,
          label: field.value.state.label,
          visible: field.value.state.attributes.data.includes(EAttrs.ON),
          index: field.value.state.index,
          layout: field.value.state.layout,
          attributes: field.value.state.attributes.data,
          byteType: field.value.state.bt,
          filterOperators: field.value.state.filterOperators
        },
      ];
    }
    return result;
  }, [] as TPartialField[]);

  !fieldsData.some((field) => field.name === primaryKey) &&
  fieldsData.push({
    type: '' as DbTypes,
    name: primaryKey as string,
    visible: false,
    label: '',
    index: 0,
    layout: {} as Layout,
    attributes: [],
    byteType: undefined,
    filterOperators: '_eq'
  });
  return fieldsData;
};

const isConditionTrue = (
  operandOne: string | undefined,
  operandTwo: string | undefined,
  operator: string | undefined,
) => {
  if (operator === '<') {
    return Number(operandOne) < Number(operandTwo);
  }
  if (operator === '>') {
    return Number(operandOne) > Number(operandTwo);
  }
  if (operator === '=') {
    return Number(operandOne) === Number(operandTwo);
  }
  if (operator === '<=') {
    return Number(operandOne) <= Number(operandTwo);
  }
  if (operator === '>=') {
    return Number(operandOne) >= Number(operandTwo);
  }
  if (operator === 'like') {
    return operandOne?.toString().toLowerCase() === operandTwo?.toString().toLowerCase();
  }
  return false;
};

const setFormattingRule = (
  formattingRuleState: { [key: string]: TConditionalFormattingRulesState } | undefined,
  field: TField,
  cell: Tabulator.CellComponent,
) => {
  if (!isEmpty(formattingRuleState)) {
    const formattingRule = formattingRuleState[field.key];
    const row = cell.getRow();
    const data = row.getData();
    const selectedConditionCell = formattingRule?.selectedConditionCell;
    const selectedCondition = formattingRule?.selectedCondition;
    const selectedConditionValue = formattingRule?.selectedConditionValue;
    const conditionValue = selectedConditionCell && data[selectedConditionCell];

    if (formattingRule && formattingRule.isBackgroundColorChecked) {
      if (formattingRule.selectedConditionCell) {
        cell.getElement().style.backgroundColor = isConditionTrue(
          conditionValue,
          selectedConditionValue,
          selectedCondition,
        )
          ? formattingRule.backgroundColor ?? ''
          : '';
      } else {
        cell.getElement().style.backgroundColor = formattingRule.backgroundColor ?? '';
      }
    }

    if (formattingRule && formattingRule.isFontColorChecked) {
      if (formattingRule.selectedConditionCell) {
        cell.getElement().style.color = isConditionTrue(
          conditionValue,
          selectedConditionValue,
          selectedCondition,
        )
          ? formattingRule.fontColor ?? ''
          : '';
      } else {
        cell.getElement().style.color = formattingRule.fontColor ?? '';
      }
    }

    if (formattingRule && formattingRule.isFontChecked) {
      if (formattingRule.selectedConditionCell) {
        if (isConditionTrue(conditionValue, selectedConditionValue, selectedCondition)) {
          cell.getElement().style.fontFamily = formattingRule.selectedFont ?? '';
          cell.getElement().style.fontSize = formattingRule.fontSize
            ? `${formattingRule.fontSize}px`
            : '14px';
          cell.getElement().style.fontWeight = formattingRule.isFontStyleBold ? 'bold' : '';
          cell.getElement().style.fontStyle = formattingRule.isFontStyleItalic ? 'italic' : '';
          cell.getElement().style.textDecoration = formattingRule.isFontStyleUnderline
            ? 'underline'
            : '';
        } else {
          cell.getElement().style.fontFamily = '';
          cell.getElement().style.fontSize = '14px';
          cell.getElement().style.fontWeight = '';
          cell.getElement().style.fontStyle = '';
          cell.getElement().style.textDecoration = '';
        }
      } else {
        cell.getElement().style.fontFamily = formattingRule.selectedFont ?? '';
        cell.getElement().style.fontSize = formattingRule.fontSize
          ? `${formattingRule.fontSize}px`
          : '14px';
        cell.getElement().style.fontWeight = formattingRule.isFontStyleBold ? 'bold' : '';
        cell.getElement().style.fontStyle = formattingRule.isFontStyleItalic ? 'italic' : '';
        cell.getElement().style.textDecoration = formattingRule.isFontStyleUnderline
          ? 'underline'
          : '';
      }
    }
  }
};

type CreateColumnsArguments = {
  fields: TField[];
  parentFieldName?: string;
  fieldTitle?: string;
  sortingIndex?: number;
  createFilterInput?: (key: string, filterOperators: string) => HTMLInputElement;
  setSortingRules?: (sortingRules: TSortingRules) => void;
  sortingRules?: TSortingRules;
  formattingRuleState?: { [key: string]: TConditionalFormattingRulesState };
  objOptions?: Record<string, Record<string, string>[]>;
  createFilterSelect?: (key: string, selectOptions: Record<string, Record<string, string>[]> | string[]) => HTMLSelectElement;
};
type CreateColumns = (args: CreateColumnsArguments) => any;
type ReturnField = {
  title: string;
  field: string;
  type: DbTypes;
  byteType: EByteType | SelectValue;
  attributes: EAttrs[];
  index: number;
  formatter?: ((cell: Tabulator.CellComponent) => any) | string;
  formatterParams?: {
    inputFormat: string;
    outputFormat: string;
    timezone?: string;
  };
  headerFilter?: () => HTMLInputElement | null;
  headerFilterLiveFilter?: boolean;
  headerSort?: boolean;
  sorter?: () => void;
  headerSortTristate?: boolean;
  headerClick?: (e: PointerEvent, column: Tabulator.ColumnComponent) => void;
  titleFormatter?: () => string;
};

export const createColumns: CreateColumns = ({
                                               fields,
                                               parentFieldName = '',
                                               fieldTitle = '',
                                               sortingIndex,
                                               createFilterInput,
                                               setSortingRules,
                                               sortingRules,
                                               formattingRuleState,
                                               objOptions = [],
                                               createFilterSelect
                                             }) => {
  const locale = getLocale();
  let columns: any = fields
    .filter((field) => field.attributes.data.includes(EAttrs.ON))
    .map((field) => {
      const grouped = field.attributes.data.includes(EAttrs.GROUP);
      const isSomeChildEnabled = field.children?.some((child) =>
        child.attributes.data.includes(EAttrs.ON),
      );

      if (field.type === DbTypes.OBJECT && isSomeChildEnabled) {
        const label = getParsedLabel(locale, field.label);
        const title = label || field.name;
        if (grouped) {
          const objValue = {
            title: label || (fieldTitle ? `${fieldTitle}->${title}` : title),
            field: field.name,
            index: sortingIndex ?? field.index,
            type: DbTypes.OBJECT,
            columns: createColumns({
              fields: field.children!,
              parentFieldName: parentFieldName ? `${parentFieldName}.${field.name}` : field.name,
              fieldTitle: title,
              formattingRuleState,
              setSortingRules,
              sortingRules,
              createFilterInput,
            }),
          };

          if (field.attributes.data.includes(EAttrs.FILTER)) {
            objValue.headerFilter = () => createFilterSelect ? createFilterSelect(field.key, objOptions[field.name]) : null;
            objValue.headerFilterLiveFilter = false;
          }

          return objValue

        } else {
          return createColumns({
            fields: field.children!,
            parentFieldName: parentFieldName ? `${parentFieldName}.${field.name}` : field.name,
            fieldTitle: title,
            sortingIndex: field.index,
            formattingRuleState,
            createFilterInput,
            objOptions
          });
        }
      } else if (field.type === DbTypes.LIST && isSomeChildEnabled) {
        const label = getParsedLabel(locale, field.label);
        const title = label || field.name;
        return {
          title: label || (fieldTitle ? `${fieldTitle}->${title}` : title),
          key: field.key,
          name: field.name,
          index: sortingIndex ?? field.index,
          // visible: fieldSetting.value.state.attributes.data.includes(Attrs.ON),
          formatter: (): string => '<button class="tabulator__subtable-toggler"></button>',
          cellClick: (event: MouseEvent<HTMLDivElement>) => {
            const cellElement = <HTMLDivElement>event.target;
            const button = cellElement.getElementsByClassName('tabulator__subtable-toggler')[0];
            button.classList.toggle('tabulator__subtable-toggler_expanded');
            const subTableElement = cellElement.parentElement!.lastChild;
            (<Element>subTableElement).classList.toggle('tabulator__subtable_hidden');
          },
        };
      } else {
        const label = getParsedLabel(locale, field.label);
        const title = label || field.name;
        const returnField: ReturnField = {
          title: label || (fieldTitle ? `${fieldTitle}->${title}` : title),
          field: parentFieldName ? `${parentFieldName}.${field.name}` : field.name,
          type: field.type,
          byteType: field.bt,
          attributes: field.attributes.data,
          index: sortingIndex ?? field.index,
          headerSort: false,
        };

        if (field.attributes.data.includes(EAttrs.FILTER)) {
          returnField.headerFilter = () =>
            createFilterInput ? createFilterInput(field.key, field?.filterOperators ?? '_eq') : null;
          returnField.headerFilterLiveFilter = false;
        }

        if (field.attributes.data.includes(EAttrs.SORT)) {
          const renderTitle = () => {
            const rule = findRule(sortingRules, field.key);
            return `<div style="display: flex; justify-content: space-between">
                      ${title}
                      <div class="arrow-wrapper">
                        <div class="sort-${rule?.order ?? EAttrs.NONE}"></div>
                      </div>
                    </div>`;
          };
          returnField.headerSort = true;
          returnField.headerSortTristate = true;
          returnField.titleFormatter = () => renderTitle();

          returnField.headerClick = (event, column) => {
            const sortingRulesCopy = cloneDeep(sortingRules);
            const sortingRule = findRule(sortingRulesCopy, field.key);
            if (sortingRule) {
              switch (sortingRule.order) {
                case EAttrs.NONE:
                  sortingRule.order = EAttrs.ASC;
                  break;
                case EAttrs.ASC:
                  sortingRule.order = EAttrs.DESC;
                  break;
                case EAttrs.DESC:
                  sortingRule.order = EAttrs.NONE;
                  break;
                default:
                  sortingRule.order = EAttrs.NONE;
              }
            }

            resetOrder(sortingRulesCopy, field.key);

            if (setSortingRules && sortingRulesCopy) {
              setSortingRules(sortingRulesCopy);
            }

            column
              .getTable()
              .element.querySelectorAll('.tabulator-col-sorter .tabulator-arrow')
              .forEach((element) => {
                (element as HTMLElement).style.display = 'none';
              });
          };
          returnField.sorter = () => {
          };
        }

        if (field.type === DbTypes.BYTEA) {
          returnField.formatter = (cell) => {
            setFormattingRule(formattingRuleState, field, cell);
            if (field.bt === EByteType.IMAGE) return fileImageOutlined;
            return fileTextOutlined;
          };
        }

        if (field.type === DbTypes.TIMESTAMP && field.dateOutputFormat ||
          field.type === DbTypes.TIMESTAMPTZ && field.dateOutputFormat ||
          field.type === DbTypes.DATE && field.dateOutputFormat ||
          field.type === DbTypes.TIME && field.dateOutputFormat
        ) {
          returnField.formatter = 'datetime';
          returnField.formatterParams = {
            inputFormat: field.dateOutputFormat,
            outputFormat: field.dateOutputFormat,
          };
        }

        if (field.type === DbTypes.JSONB) {
          returnField.formatter = (cell) => {
            setFormattingRule(formattingRuleState, field, cell);
            return appStoreOutlined;
          };
        }

        if (field.type === DbTypes.JSONB && field.name === FIELD_NAME_SYS && field.contentType?.length) {
          returnField.formatter = (cell) => {
            let resultAttributes = '';
            field.contentType &&
            field.contentType.forEach((option) => {
              const optionArray = option.split('-');
              const actionKey = optionArray[0];
              const valueKey = optionArray[1];
              if (cell.getValue() && cell.getValue()![actionKey] !== undefined) {
                const isMoment = valueKey === 'timestamp';
                return (resultAttributes += `${
                  isMoment
                    ? moment(cell.getValue()![actionKey][valueKey]).format('YYYY MM DD')
                    : cell.getValue()![actionKey][valueKey]
                }, `);
              }
              return (resultAttributes += '');
            });
            setFormattingRule(formattingRuleState, field, cell);
            return resultAttributes.trim().slice(0, -1);
          };
        }

        if (field.type === DbTypes.INTEGER4 || field.type === DbTypes.FLOAT8) {
          returnField.formatter = (cell) => {
            setFormattingRule(formattingRuleState, field, cell);
            return Array.isArray(cell.getValue()) ? cell.getValue().join(', ') : null;
          };
        }

        if (
          field.type !== DbTypes.JSONB &&
          field.type !== DbTypes.BYTEA &&
          field.type !== DbTypes.DATE &&
          field.type !== DbTypes.TIMESTAMP &&
          field.type !== DbTypes.TIME &&
          field.type !== DbTypes.TIMESTAMPTZ &&
          field.type !== DbTypes.INTEGER4 &&
          field.type !== DbTypes.FLOAT8
        ) {
          if (toLower(field.type) === EDataType.BOOLEAN) {
            returnField.hozAlign = "center";

            returnField.formatter = (cell) => {
              const value = cell.getValue()
              if (value) {
                return checkTrue
              } else if (!value && value !== null) {
                return checkFalse
              }
              return ''
            };
          } else {
            returnField.formatter = (cell) => {
              setFormattingRule(formattingRuleState, field, cell);
              return cell.getValue();
            };
          }
        }

        return returnField;
      }
    });

  let objectFields: any = [];
  columns = columns
    .map((column: any) => {
      if (isArray(column)) {
        objectFields = [...objectFields, ...column];
        return;
      }
      return column;
    })
    .filter((column: any) => column);
  columns = columns
    .concat(objectFields)
    .sort((previous: any, next: any) => previous?.index - next?.index);

  return columns;
};

export const getNotEmptyFields = (fields: TFields) => {
  const notEmptyFields = {};
  for (const key in fields) {
    if (fields[key] !== '') {
      notEmptyFields[key] = fields[key];
    }
  }
  return notEmptyFields;
};

export const getChangedFields = (row: TFieldsForUpdate) => {
  const changedFields = {};
  for (const field in row) {
    if (row[field].isChanged) {
      const value = pick(row[field], 'value').value
      changedFields[field] = isString(value) ? value.trim() : value;
    }
  }
  return changedFields;
};

export const getLastPrimaryKey = (rows: [], primaryKey: string) => {
  const primaryKeys = rows.map((row) => row[primaryKey]);
  if (!primaryKeys.length) return 0;
  return Math.max.apply(null, primaryKeys);
};

export const filterRowByVisibleFields = (row: TFields, fields: TPartialField[]) => {
  const visibleFields: TField = {} as TField;
  fields.forEach((field) => {
    visibleFields[field.name] = {
      type: field.type,
      value: row[field.name] === 'NULL' ? '' : row[field.name],
      label: field.label,
      isChanged: false,
      attributes: field.attributes,
      byteType: field.byteType,
    };
  });
  return visibleFields;
};

export const wrapSlashes = (value: any) => `\\"${value}\\"`;
const wrapDoubleSlashes = (value: string) => `\\\\"${value}\\\\"`;
export const wrapTripleSlashes = (value: any) => value.replaceAll('"', '\\\\\\"');

export const generateTimestamp = (format?: 'YYYY-mm-dd HH:mm:ss') => moment().format(format);

const generateSectionCreated = (userInfo: { id: string; user: string }) => {
  return `_sys: { created: { id: ${wrapSlashes(userInfo.id)}, user: ${wrapSlashes(
    userInfo.user,
  )}, timestamp: ${wrapSlashes(generateTimestamp())} } }`;
};


export const generateSectionUpdated = (userInfo: {
  id: string;
  firstName: string;
  lastName: string;
}, withoutSysKey?: boolean) => {
  if (withoutSysKey) {
    return `{ updated: { id: ${wrapSlashes(userInfo.id)}, user: ${wrapSlashes(
      `${userInfo.firstName} ${userInfo.lastName}`,
    )}, timestamp: ${wrapSlashes(generateTimestamp())} } }`;
  }
  return `_append: { _sys: { updated: { id: ${wrapSlashes(userInfo.id)}, user: ${wrapSlashes(
    `${userInfo.firstName} ${userInfo.lastName}`,
  )}, timestamp: ${wrapSlashes(generateTimestamp())} } } }`;
};

export const generateSectionDeleted = (userInfo: {
  id: string;
  firstName: string;
  lastName: string;
}, withoutSysKey?: boolean) => {
  if (withoutSysKey) {
    return `{ deleted: { id: ${wrapSlashes(userInfo.id)}, user: ${wrapSlashes(
      `${userInfo.firstName} ${userInfo.lastName}`,
    )}, timestamp: ${wrapSlashes(generateTimestamp())} } }`;
  }
  return `_append: { _sys: { deleted: { id: ${wrapSlashes(userInfo.id)}, user: ${wrapSlashes(
    `${userInfo.firstName} ${userInfo.lastName}`,
  )}, timestamp: ${wrapSlashes(generateTimestamp())} } } }`;
};

type TBuildQuery = {
  (fields: TFields | {}, fieldsMeta: TFieldMeta[], sysSettings?: { generateSectionFunc: any, withoutSysKey: boolean }): string;
}

export const getClientConditionsString = (key: string, value: string, operation: string) => {
  const keyAsArray = key.split(',')
  let result = `{${operation}: ${wrapSlashes(value)}}`
  for (let i = keyAsArray.length; i >= 1; i--) {
    if (i === 1) {
      result = `${keyAsArray[0]}: ${result},`
    } else if (i !== 1) {
      result = `{${keyAsArray[i - 1]}: ${result}}`
    }
  }

  return result
}

const stringContainsDoubleQuotes = (value: string | object) => {
  if (typeof value === 'string') {
    return value.includes('"');
  }
  return false;
};

export const getFieldsMetaFromObject = (fields: TFieldsForUpdate): TFieldMeta[] => {
  const fieldsMeta: TFieldMeta[] = [];
  for (const key in fields) {
    fieldsMeta.push({name: key, type: fields[key].type});
  }
  return fieldsMeta;
};

export const getFieldsMetaFromArray = (fields: TPartialField[]): TFieldMeta[] => {
  return fields.map((field) => ({
    name: field.name,
    type: field.type,
  }));
};

const jsonStringifyCustom = (obj: any) => {
  return JSON.stringify(obj)
    .replaceAll(ESCAPED_DOUBLE_QUOTE, ESCAPED_DOUBLE_QUOTE_PLACEHOLDER)
    .replaceAll(DOUBLE_QUOTE, DOUBLE_QUOTE_REPLACER)
    .replaceAll(ESCAPED_DOUBLE_QUOTE_PLACEHOLDER, ESCAPED_DOUBLE_QUOTE_PLACEHOLDER_REPLACER);
}

export const buildQuery: TBuildQuery = (
  fields: TFields | {},
  fieldsMeta,
  sysSettings
) => {
  let queryFields: string = '';
  for (const key in fields) {
    const fieldType = fieldsMeta.find((meta) => meta.name === key)?.type;
    const isJson = fieldType === DbTypes.JSON || fieldType === DbTypes.JSONB;

    if (key === FIELD_NAME_SYS) {
      const value = sysSettings?.generateSectionFunc
        ? `_sys: ${sysSettings?.generateSectionFunc(fields['_sys'], sysSettings?.withoutSysKey ?? false)}`
        : generateSectionCreated(fields['_sys']);
      queryFields += value;
    } else if (isJson && fields[key]) {
      const value = jsonStringifyCustom(fields[key]);
      queryFields += `${key}:${wrapSlashes(value)},`;
    } else if (Array.isArray(fields[key])) {
      const field = `{${fields[key].join(',')}}`
      queryFields += `${key}:${wrapSlashes(field)}`
    } else if (moment.isMoment(fields[key])) {
      const valueAsString = moment(fields[key]).format('HH:mm:ss')
      queryFields += `${key}:${wrapSlashes(valueAsString)},`;
    } else if (fields[key] === null || fields[key] === '' || fields[key] === 'empty' || fields[key] === 'пустое') {
      queryFields += `${key}:null,`;
    } else {
      let value = fields[key];
      if (typeof value === 'string' && stringContainsDoubleQuotes(value)) {
        value = wrapTripleSlashes(value);
      }
      queryFields += `${key}:${wrapSlashes(value)},`;
    }
  }
  return queryFields;
};

export const hasSystemField = (fieldsSettings: CrudViewerFieldsSettings) => {
  return fieldsSettings.some(
    (fieldsSetting) =>
      fieldsSetting.value.state.name === '_sys' && fieldsSetting.value.state.type === DbTypes.JSONB,
  );
};

export const orderRules = [
  EAttrs.ON,
  EAttrs.OFF,
  EAttrs.MO,
  EAttrs.OM,
  EAttrs.RO,
  EAttrs.HIDE,
  EAttrs.PK,
  EAttrs.REF,
  EAttrs.NN,
  EAttrs.FK,
  EAttrs.GROUP,
  EAttrs.ADDED,
  EAttrs.QS,
  EAttrs.QE,
  EAttrs.FILTER,
  EAttrs.SORT,
  EAttrs.LAZY,
  EAttrs.SYS,
  EAttrs.KEY,
];

export const extractFieldSettings = (
  field: TField,
  tableName: string | SelectValue,
  foreignKey?: string | undefined,
  isMatrix = false,
) => {
  const prefix = isMatrix ? 'field_matrix_' : 'field_';
  const fieldSettings: { state: Partial<TField> } = {
    state: {
      type: field.type,
      tableName,
      name: field.key,
      key: field.key,
      label: field.label,
      index: field.index,
      attributes: {
        data: field.attributes.data,
      },
      layout: field.layout,
      fk: field.fk,
      bt: field.bt,
      dateOutputFormat: field.dateOutputFormat,
      dateOutputAccuracy: field.dateOutputAccuracy,
      filterOperators: field.filterOperators
    },
  };
  foreignKey && (fieldSettings.state.fk = foreignKey);
  if (field.type === DbTypes.OBJECT || field.type === DbTypes.LIST) {
    fieldSettings.state.children = field.children;
  }
  if (field.type === DbTypes.OBJECT) fieldSettings.state.tableSettings = field.tableSettings;
  if (field.type === DbTypes.JSONB || field.type === DbTypes.JSON) fieldSettings.state.contentType = field.contentType;
  return {
    key: `${prefix}${tableName}_${field.key}`,
    value: JSON.stringify(fieldSettings),
  };
};

export const getColorByAttributeName = (name: EAttrs) => {
  let color: string;
  switch (name) {
    case EAttrs.RO:
    case EAttrs.HIDE:
      color = EColors.VOLCANO;
      break;
    case EAttrs.NN:
    case EAttrs.PK:
    case EAttrs.MO:
    case EAttrs.OM:
    case EAttrs.REF:
    case EAttrs.FK:
    case EAttrs.SYS:
    case EAttrs.ASC:
    case EAttrs.DESC:
    case EAttrs.ASC_NULL_FIRST:
    case EAttrs.ASC_NULL_LAST:
    case EAttrs.DESC_NULL_FIRST:
    case EAttrs.DESC_NULL_LAST:
      color = EColors.GEEKBLUE;
      break;
    case EAttrs.GROUP:
    case EAttrs.ADDED:
    case EAttrs.QS:
    case EAttrs.QE:
    case EAttrs.FILTER:
    case EAttrs.SORT:
    case EAttrs.LAZY:
    case EAttrs.KEY:
      color = EColors.PURPLE;
      break;
    case EAttrs.ON:
      color = EColors.GREEN;
      break;
    default:
      color = EColors.DEFAULT;
  }
  return color;
};

export const queryBuild = (
  fieldsData: TPartialField[],
  selectedTable: TSelectedTableField[],
  table?: string,
) => {
  fieldsData = fieldsData.filter((field) => !(field.attributes ?? []).includes(EAttrs.LAZY));
  const visibleFields = intersectionBy(selectedTable, fieldsData, 'name');

  const query: string | string[] = visibleFields.map((field) => {
    const innerTable = field.type.ofType?.fields;
    if (innerTable) {
      const innerFields = innerTable
        .map((innerField) => {
          if (innerField.type.ofType?.kind === 'OBJECT' || innerField.type.ofType?.kind === 'LIST')
            return;
          return innerField.name;
        })
        .filter((filterField) => filterField !== undefined);
      return [field.name, '{', innerFields.join(' '), '}'].join('');
    }
    return field.name;
  });
  if (!query.length) return '';
  if (table) return ['{', table, '{', ...query, '}', '}'].join(' ');
  else return [...query].join(' ');
};

export const queryBuildV2 = (
  fields: TField[],
  primaryKey: string | null = null,
  conditionsArray?: string[],
): string => {
  const enabledFields = fields.filter((field) => {
    const attributes = field.attributes.data;
    return attributes.includes(EAttrs.ON) && !attributes.includes(EAttrs.LAZY);
  });
  if (!enabledFields.length) return '';
  if (conditionsArray) {
    fields.forEach((field) => {
      if (conditionsArray.includes(field.name)) {
        enabledFields.push(field);
      }
    });
  }
  if (primaryKey) {
    const primaryKeyField = fields.find((field) => field.name === primaryKey);
    if (primaryKeyField && !enabledFields.includes(primaryKeyField)) {
      enabledFields.push(primaryKeyField);
    }
  }

  const query = `{ ${enabledFields
    .map((field) => {
      const isComplexField = field.type === DbTypes.OBJECT || field.type === DbTypes.LIST;
      if (isComplexField) {
        const isSomeChildEnabled = field.children?.some((child) =>
          child.attributes.data.includes(EAttrs.ON),
        );
        return isSomeChildEnabled ? `${field.name}${queryBuildV2(field.children!, null)}` : null;
      }
      return field.name;
    })
    .filter((field) => field)
    .join(' ')} }`;

  return query;
};

type BuildQueryForSelectOptionsArguments = {
  tableName: string;
  currentField?: TField;
  childFields: TField[];
  globalRangeAbsolutePeriod: [Moment, Moment];
  variableListData: TTableValuesState[];
  rangeSettingsData: ICrudRangeSettings;
  foreignKeyField?: string | undefined;
};

export const buildQueryForSelectOptions = (
  arguments_: BuildQueryForSelectOptionsArguments,
): string => {
  const {globalRangeAbsolutePeriod, variableListData, rangeSettingsData} = arguments_;
  const queryBuilder = new QueryBuilder({
    fieldTableSettings: (arguments_.currentField ?? {}).tableSettings ?? {
      conditions: {data: [], expandedKeys: [], checkedKeys: []},
    },
    rangeSettings: rangeSettingsData,
    variableList: variableListData,
    globalRangeAbsolutePeriod,
  });
  const conditionsQuery = queryBuilder.buildConditionsQuery();

  const fieldsNames = arguments_.childFields
    .filter((field) => field.attributes.data.includes(EAttrs.ON))
    .map((field) => {
      const isComplexField = field.type === DbTypes.OBJECT || field.type === DbTypes.LIST;
      if (isComplexField) {
        const isSomeChildEnabled = field.children?.some((child) =>
          child.attributes.data.includes(EAttrs.ON),
        );
        return isSomeChildEnabled
          ? buildQueryForSelectOptions({
            tableName: field.name,
            childFields: field.children ?? [],
            globalRangeAbsolutePeriod,
            variableListData,
            rangeSettingsData,
          })
          : '';
      }
      return field.name;
    })
    .filter((notEmptyString) => notEmptyString);

  if (arguments_.foreignKeyField) {
    if (!fieldsNames.includes(arguments_.foreignKeyField))
      fieldsNames.push(arguments_.foreignKeyField);
  }
  const where = arguments_.currentField ? `(where: { ${conditionsQuery} })` : '';
  return `${arguments_.tableName}${where} { ${fieldsNames.join(',')} }`;
};

export const buildOptionText: any = (enabledFields: (Record<string, any> | string)[], locale?: string) => {
  let currentField: string;
  return enabledFields.map((field: any) => {
    if (isPlainObject(field)) {
      currentField = Object.values<Record<string, any>>(field)
        .map((value) => {
          if (isPlainObject(value)) {
            return buildOptionText(Object.values(value));
          } else if (isArray(value)) {
            return buildOptionText(value);
          }
          return value;
        })
        .filter((value) => value)
        .join();
    } else if (isArray(field)) {
      if (field.length) {
        return field.map((value) => {
          return buildOptionText(Object.values(value));
        });
      } else {
        currentField = '';
      }
    } else {
      currentField = field === null ? locale : field
    }
    return ' ' + currentField;
  });
};

export const isNonNull = (selectedTable: TSelectedTable, fieldName?: string) => {
  return selectedTable.fields.find(field => field.name === fieldName)?.type.kind === 'NON_NULL'
}
