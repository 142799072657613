export default {
  'message.test-connection-success': 'Connection test success',
  'message.test-connection-unsuccess': 'Connection test unsuccess!',
  'message.copiedToClipboard': 'Copied to clipboard',
  'message.error': 'Error',
  'message.error2': '{text} error',
  'message.success': 'Success',
  'message.unsuccess': 'Unsuccess',
  'message.synced': 'Synchronized',
  'message.notsynced': 'Not Synchronized',
  'message.read-file-error': 'File read error',
  'message.password-mismatch': 'Password mismatch',
  'message.something-went-wrong': 'Something went wrong',
  'message.sso-unavailable': 'Auth broker unavailable!',
  'message.api-unavailable': 'API service unavailable!',
  'message.noFieldsSelected': 'No fields to add selected',
  'message.available-formats': 'Available formats: jpg, png, svg',
  'message.allowed-file-size': 'File size no more than 500kb',
  'message.allowed-image-size': 'Maximum image size 250x250px',
  'message.settings-saved-successfully': 'Settings saved successfully',
  'message.settings-saved-failure': 'Failed to get global settings',
  'message.type-value': 'Type value!',
  'message.select-variable': 'Select variable!',
};
