import { gql } from 'graphql-request';
import { client } from '@/requests/client';

export default function dmsFileAdd(props) {
  const query = gql`
    mutation dmsFileAdd(
      $toolName: String!
      $path: String!,
      $file: Upload!,
    ) {
      dmsFileAdd(
        toolName: $toolName
        path: $path
        file: $file
      ){
        success
        message
      }
    }
  `;

  return new Promise((resolve, reject) => {
    client()()
      .request(query, props)
      .then((data) => resolve(data?.dmsFileAdd))
      .catch((error) => reject(error));
  });
}
