import fileManager from './component/fileManager';
import { loggerViewerLocaleRu } from '@/components/Tools/logger-viewer/locales';
import logger from '@/locales/ru-RU/component/logger';

export default {
  // Common
  'component.tagSelect.expand': 'Expand',
  'component.tagSelect.collapse': 'Collapse',
  'component.tagSelect.all': 'All',
  'component.logout': 'Выход',
  'component.save': 'Сохранить',
  'component.clear': 'Очистить',
  'component.test': 'Тест',
  'component.login': 'Логин',
  'component.username': 'Имя пользователя',
  'component.password': 'Пароль',
  'component.create': 'Создать',
  'component.sync': 'Синхронизация',
  'component.delete': 'Удалить',
  'component.move': 'Переместить',
  'component.format': 'Форматировать',
  'component.upload': 'Выгрузить',
  'component.download': 'Загрузить',
  'component.reset': 'Сброс',
  'component.internalUrl': 'Адрес (внутренний)',
  'component.externalUrl': 'Адрес (внешний)',
  'component.finish': 'Завершить',
  'component.enabled': 'Включена',
  'component.disabled': 'Выключена',
  'component.not-available': 'Извините, данная страница вам недоступна',
  'component.home': 'На Главную',
  'component.on': 'Вкл',
  'component.off': 'Выкл',
  'component.add': 'Добавить',
  'component.edit': 'Изменить',
  'component.addition': 'Добавление',
  'component.updating': 'Обновление',
  'component.deleting': 'Удаление',
  'component.confirm-delete-tool-title': 'Вы уверены, что хотите удалить?',
  'component.confirm-delete-menu-title': 'Вы уверены, что хотите удалить меню ',
  'component.confirm-create-menu-title': 'Создать меню?',
  'component.yes': 'Да',
  'component.no': 'Нет',
  'component.cancel': 'Отмена',
  'component.profile': 'Профиль',
  'component.label.first-name': 'Имя',
  'component.label.last-name': 'Фамилия',
  'component.label.email': 'E-mail',
  'component.placeholder.first-name': 'Пример: Иван',
  'component.placeholder.last-name': 'Пример: Иванов',
  'component.placeholder.email': 'Пример: login@mail.ru',
  'component.name_filer': 'Фильтр по имени',
  'component.type_filer': 'Фильтр по типу',
  'component.all': 'Все',
  'component.show_disabled': 'Отображать недоступные',
  'component.preview': 'Предпросмотр',
  'component.name': 'Имя',
  'component.description': 'Описание',
  'component.label': 'Ярлык',
  'component.type': 'Тип',
  'component.menu': 'Меню',
  'component.folder': 'Папка',
  'component.title.settings': 'Настройки',
  'component.title.add_tool': 'Добавить инструмент',
  'component.theme': 'Тема',
  'component.current': 'Текущая',
  'component.light': 'Светлая',
  'component.medium': 'Средняя',
  'component.dark': 'Тёмная',
  'component.full': 'Полный',
  'component.kiosk': 'Киоск',
  'component.kiosk_tv': 'Киоск ТВ',
  'component.view': 'Вид',
  'component.client': 'Клиент',
  'component.client_tooltip': 'Клиенские настройки',
  'component.server': 'Сервер',
  'component.server_tooltip': 'Серверные настройки',
  'component.loading': 'Загрузка...',
  'component.version': 'Версия',
  'component.default': 'По умолчанию',
  'component.title.confirm.share.enabled': 'Are you sure set property to enable?',
  'component.title.confirm.share.disabled': 'Are you sure set property to disable?',
  'component.error.tool-unsupported': 'Инструмент не поддерживается',
  'component.example': 'Пример',
  'component.error': 'Ошибка',
  'component.endpoint': 'Конечная точка',
  'component.report': 'Отчет',
  'component.panel': 'Панель',
  'component.panel_on_click': 'Панель onClick',
  'component.constructor': 'Конструктор',
  'component.variables': 'Переменные',
  'component.range': 'Диапазон',
  'component.select_range': 'Выбор диапазона',
  'component.visible': 'Видимость',
  'component.use_global_property': 'Использовать глобальное свойство',
  'component.default_value': 'Значение по умолчанию',
  'component.presets': 'Предустановки',
  'component.functions': 'Функции',
  'component.Last_5_minutes': 'Последние 5 минут',
  'component.Last_15_minutes': 'Последние 15 минут',
  'component.Last_30_minutes': 'Последние 30 минут',
  'component.Last_1_hour': 'Последний час',
  'component.Last_3_hours': 'Последние 3 часа',
  'component.Last_6_hours': 'Последние 6 часов',
  'component.Last_12_hours': 'Последние 12 часов',
  'component.Last_24_hours': 'Последние 24 часа',
  'component.Last_2_days': 'Последние 2 дня',
  'component.Last_7_days': 'Последние 7 дней',
  'component.Last_30_days': 'Последние 30 дней',
  'component.Last_90_days': 'Последние 90 дней',
  'component.Last_6_months': 'Последние 6 месяцев',
  'component.Last_1_year': 'Последний год',
  'component.Last_2_years': 'Последние 2 года',
  'component.Last_5_years': 'Последние 5 лет',
  'component.Current_day': 'Текущий день',
  'component.Current_week': 'Текущая неделя',
  'component.Current_month': 'Текущий месяц',
  'component.Current_quarter': 'Текущий квартал',
  'component.Current_year': 'Текущий год',
  'component.Yesterday': 'Вчера',
  'component.Last_week': 'Прошлая неделя',
  'component.Last_month': 'Прошлый месяц',
  'component.Last_quarter': 'Прошлый квартал',
  'component.Last_year': 'Прошлый год',
  'component.event': 'Событие',
  'component.events': 'События',
  'component.onClick': 'Клик',
  'component.action': 'Действие',
  'component.sort': 'Сортировка',
  'component.service_unavailable': 'Сервис недоступен',
  'component.show': 'Показать',
  'component.hide': 'Скрыть',
  'component.attributes': 'Атрибуты',
  'component.actions': 'Действия',
  'component.state': 'Состояние',
  'component.multiple_values': 'Несколько значений',
  'component.include': 'Включить',
  'component.select_all': 'Выбрать все',
  'component.values_separated_by_comma': 'Значения разделенные запятой',
  'component.decorate': 'Оформление',
  'component.logo': 'Логотип',
  'component.russian': 'Русский',
  'component.english': 'Английский',
  'component.kazakh': 'Казахский',
  'component.deutsch': 'Немецкий',
  'component.style': 'Стиль',
  'component.navigation': 'Навигация',
  'component.top': 'Сверху',
  'component.left': 'Слева',
  'component.mix': 'Смешанная',
  'component.visibility': 'Видимость',
  'component.select-range': 'Выбор диапазона',
  'component.absolute': 'Абсолютное',
  'component.relative': 'Относительное',
  'component.token': 'Токен',
  'component.from': 'из',
  'component.change-value': 'Изменить значение',
  'component.applicationName': 'Имя приложения',
  'component.source': 'Источник',
  'component.values': 'Значения',
  'component.multi-values': 'Несколько значений',
  'component.include-select-all': 'Включить [Выбрать всё]',
  'component.query': 'Запрос',
  'component.custom': 'Пользовательский',
  'component.value': 'Значение',
  'component.constant': 'Константа',
  'component.link': 'Связная',
  'component.type-value': 'Тип переменной',
  'component.input-variable-name': 'Задайте имя переменной!',
  'component.input-rule-name': 'Задайте имя правила!',
  'component.name-already-exists': 'Запись с таким именем уже существует!',
  'component.date-output-format': 'Формат отображения даты',
  'component.date-output-accuracy': 'Точность',
  'component.invalid-variable-value': 'Не верное значение переменной',
  'component.change': 'Изменить',
  'component.unpin': 'Открепить',
  'component.removed': 'Удален',
  'component.not-removed': 'Не удален',
  'component.added': 'Добавлен',
  'component.not-added': 'Не добавлен',
  'component.changed': 'Изменен',
  'component.not-changed': 'Не изменен',
  'component.user-created': 'пользователь, который добавил',
  'component.user-updated': 'пользователь, который обновил',
  'component.date-updated': 'дата обновления',
  'component.date-created': 'дата добавления',
  'component.conditional-formatting': 'Условное форматирование',
  'component.rule': 'Правило',
  'component.cell': 'Ячейки',
  'component.please-select': 'Выберите из списка',
  'component.formatter': 'Форматер',
  'component.font-color': 'Цвет шрифта',
  'component.background-color': 'Цвет фона',
  'component.font': 'Шрифт',
  'component.condition': 'Условие',
  'component.localization.support': 'Поддержка локализации',
  'component.not-path-for-panel': 'Не указан путь для панели',
  'component.access': 'Доступ',
  'component.info': 'Инфо',
  'component.system-info': 'Системная информация',
  'component.width': 'Ширина',
  'component.empty': 'пустое',
  'component.file': 'Файл',
  'component.not-found': 'не найден',
  'component.clone': 'Клонировать',
  'component.row-number': 'Номер строки',
  'component.invalid-value': 'Введено неверное значение',

  // Grafana
  'component.grafana.internal': 'Адрес сервера(внутренний)',
  'component.grafana.external': 'Внешняя ссылка',
  'component.grafana.basic-api-uri': 'Basic api uri',
  'component.grafana.api-key': 'Api key',
  'component.footer.allRightReserved': 'Все права защищены',
  'component.roles': 'Роли',
  'component.groups': 'Группы',
  'component.tools': 'Инструменты',
  'component.grafana.remove-panel': 'Удалить панель',
  'component.grafana.columns-count': 'Количество столбцов',
  'component.grafana.row-height': 'Высота строк',
  'component.grafana.user-transformation': 'Изменяемые пользователем',
  'component.grafana.stretch-to-window-height': 'Растянуть на высоту окна',
  'component.grafana.edit-panel': 'Редактировать панель',
  'component.grafana.panel-group': 'Группа панелей',
  'component.grafana.use-board': 'Использовать доску',
  'component.grafana.system-info-error': 'Не удалось получить информацию по сервису',

  // System db
  'component.system-db.hostname': 'хост',
  'component.system-db.port': 'порт',
  'component.system-db.name': 'имя базы данных',
  'component.system-db.username': 'имя пользователя',
  'component.system-db.driver': 'драйвер',
  'component.system-db.want-to-delete': 'Эта операция необратима. Вы уверены, что хотите удалить системную базу данных?',
  'component.system-db.want-to-format': 'Эта операция необратима. Вы уверены, что хотите форматировать системную базу данных?',

  // Sso
  'component.sso.realm': 'Область',
  'component.sso.clientId': 'ID клиента',
  'component.sso.port': 'Порт',
  'component.sso.secret': 'Секрет',
  'component.sso.system-info-error': 'Не удалось получить информацию по сервису',

  //Rs
  'component.rs.system-info-error': 'Не удалось получить информацию по сервису',

  // User-profile
  'component.profile.login-name': 'Имя входа',
  'component.profile.new-password': 'Новый пароль',
  'component.profile.new-password-repeat': 'Новый пароль [повтор]',
  'component.profile.change-password': 'Сменить пароль',

  // Hasura
  'component.hasura.service-address': 'Адрес сервиса',
  'component.hasura.server': 'Сервер',
  'component.hasura.server-settings': 'Настройки сервера',
  'component.hasura.select.label': 'Таблица',
  'component.hasura.select.placeholder': 'Выберите таблицу',
  'component.hasura.fields': 'Поля',
  'component.hasura.table.label': 'Ярлык',
  'component.hasura.table.type': 'Тип',
  'component.hasura.table.attributes': 'Атрибуты',
  'component.hasura.table.action': 'Действие',
  'component.hasura.field': 'Поле',
  'component.hasura.no-description': 'Нет описания',
  'component.hasura.fields-not-configured': 'Не настроены поля',
  'component.hasura.form-constructor': 'Конструктор формы ввода/вывода',
  'component.hasura.min-height': 'Мин. высота',
  'component.hasura.min-width': 'Мин. ширина',
  'component.hasura.max-height': 'Макс. высота',
  'component.hasura.max-width': 'Макс. ширина',
  'component.hasura.columns-quantity': 'Кол-во столбцов',
  'component.hasura.missing-primary-key': 'Отсутствует первичный ключ',
  'component.hasura.table-settings': 'Настройки таблицы',
  'component.hasura.decorate': 'Оформление',
  'component.hasura.fitAndFill': 'Строки на всю ширину таблицы',
  'component.hasura.rowHeight': 'Высота строки',
  'component.hasura.fix': 'Фиксировать',
  'component.hasura.rowsTop': 'Строки (сверху)',
  'component.hasura.colsLeft': 'Столбцы (слева)',
  'component.hasura.colsRight': 'Столбцы (справа)',
  'component.hasura.export': 'Экспорт',
  'component.hasura.import': 'Импорт',
  'component.hasura.output': 'Вывод',
  'component.hasura.relation': 'Отношение',
  'component.hasura.restrictions': 'Ограничения',
  'component.hasura.content-type': 'Тип контента',
  'component.hasura.no-value': 'Нет значения',
  'component.hasura.source': 'Источник',
  'component.hasura.matrix': 'Матрица',
  'component.hasura.axisY': 'Ось Y',
  'component.hasura.axisX': 'Ось X',
  'component.hasura.matrix-field': 'Поле матрицы',
  'component.hasura.nesting-level': 'Уровень вложенности',
  'component.hasura.pagination': 'Пагинация',
  'component.hasura.rows-per-page': 'строк на странице',
  'component.hasura.conditions': 'Условия',
  'component.hasura.sorting-type': 'Тип сортировки',
  'component.hasura.soft': 'Мягкое',
  'component.hasura.invalid-json-format': 'Невалидный json-формат',

  // Global
  'component.global.name-restriction': 'Текст не более 50 символов',
  'component.global.desc-restriction': 'Текст не более 255 символов',
  'component.global.language-selection': 'Выбор языка',
  'component.global.default-value': 'Значение по умолчанию',
  'component.global.languages': 'Языки',

  // AuthTools
  'component.auth.users': 'Пользователи',
  'component.auth.disabled-users': 'Заблокированные пользователи ',
  'component.auth.groups': 'Группы',
  'component.auth.disabled-tools': 'Заблокированные инструменты',
  'component.auth.disabled': 'Недоступен',
  'component.auth.enabled': 'Доступен',
  'component.auth.inherited': 'Унаследовано',
  'component.auth.default': 'По умолчанию',
  'component.auth.name-filter': 'Фильтр по именам',
  'component.auth.tools-filter': 'Фильтр по инструментам',

  // Navigation
  'component.navigation.change-directory': 'Изменить директорию',
  'component.navigation.change-tool': 'Изменить инструмент',

  // Crud
  'component.function.pagination-top': 'Верх',
  'component.function.pagination-bottom': 'Низ',
  'component.ui.CrudFormConstructor.tooltipColumnsQuantity': 'Сначала освободите занятое элементами место, а потом уменьшайте значение',

  // Report
  'component.report.path-not-set': 'Не задан путь для отчета!',

  // DataInput
  'component.data-input.choose': 'выбрать',
  'component.data-input.download': 'скачать',
  'component.data-input.upload': 'загрузить',
  'component.data-input.new-file': 'Новый файл',
  'component.data-input.date-of-download': 'дата загрузки',
  'component.data-input.name-of-uploaded-file': 'Имя загруженного файла',
  'component.data-input.no-file-selected': 'Не выбран файл',
  'component.data-input.delete-the-selected-file': 'Удалить выбранный файл?',
  'component.data-input.enter-file-name': 'Введите название файла',

  // DataInputs placeholders
  'component.data.input.int': 'Пример: 123',
  'component.data.input.bigint': 'Пример: 123',
  'component.data.input.string': 'Пример: Символы',
  'component.data.input.float8': 'Пример: 123.321',
  'component.data.input._float8': 'Пример: 123.321',
  'component.data.input._int4': 'Пример: 123',
  'component.data.input.timestamp': 'Выберите дату и время',
  'component.data.input.timestamptz': 'Выберите дату и время',
  'component.data.input.date': 'Выберите дату',
  'component.data.input.bpchar': 'Пример: абв',
  'component.data.input.object': 'Выберите из списка',
  'component.data.input.time': 'Выберите время',
  'component.data.input._varchar': 'Пример: абв',
  'component.data.input.uuid': 'Пример: 123e4567-e89b-12d3-a456-426614174000',
  'component.data.input.json': 'Пример: { "fileName": "my file name", "fileSize": 1024 }',
  'component.data.input.jsonb': 'Пример: { "fileName": "my file name", "fileSize": 1024 }',
  'component.data.input.file-selection': 'Выбор файла',

  ...fileManager,
  ...logger,
  ...loggerViewerLocaleRu,
};
