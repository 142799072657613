export const state = {
  backup: '',
  isBackupLoading: false,
  isBackupUploading: false,
  settings: {
    host: '',
    port: 0,
    database: '',
    username: '',
    password: '',
  },
};
