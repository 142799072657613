// @ts-nocheck

import SmileOutlined from '@ant-design/icons/SmileOutlined';
import ControlOutlined from '@ant-design/icons/ControlOutlined';
import StarOutlined from '@ant-design/icons/StarOutlined';
import FolderOpenOutlined from '@ant-design/icons/FolderOpenOutlined';
import ToolOutlined from '@ant-design/icons/ToolOutlined';
import UnlockOutlined from '@ant-design/icons/UnlockOutlined';
import NotificationOutlined from '@ant-design/icons/NotificationOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined'

export default {
  SmileOutlined,
ControlOutlined,
StarOutlined,
FolderOpenOutlined,
ToolOutlined,
UnlockOutlined,
NotificationOutlined,
SettingOutlined
}
    