import { gql } from 'graphql-request';
import { client } from '@/requests/client';

export default function getFolderTree(props) {
  const query = gql`
    query dmsTree(
      $toolName: String
    ) {
      dmsTree(
        toolName: $toolName
      ){
        success
        message
        data
      }
    }
  `;

  return new Promise((resolve, reject) => {
    client()()
      .request(query, props)
      .then(({ dmsTree }) => {
        return (dmsTree.success)
          ? resolve(JSON.parse(dmsTree.data))
          : reject(dmsTree.message);
      })
      .catch((error) => reject(error.message));
  });
}
