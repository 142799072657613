import { TSelectedTable } from '@/types/crud';

const initialSelectedTable: TSelectedTable = { description: null, fields: [], tableName: '' };

export const state = {
  settings: {
    url: '',
    enabled: false,
    available: false,
  },
  initialSettings: {
    key: 'table',
    value: {
      name: '',
      primaryKey: null,
      permissions: {
        insert: false,
        update: false,
        delete: false,
      },
    },
  },
  filteredTables: [],
  mutations: [],
  selectedTable: initialSelectedTable,
  viewerTable: {
    fields: [],
  },
  viewerMatrix: {},
};
