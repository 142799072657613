export const reducers = {
  setProfile(state, { payload }) {
    return {
      ...state,
      ...payload,
    };
  },
  setToolsReducer(state, action) {
    state.tools = action.payload;
  },
};
