import { client } from './client';
import { gql } from 'graphql-request';
import { ELocales } from '@/types/enums';
import { INavigationSettingsState } from '@/types/store/navigation';

export function navigation(variables: { all: boolean; locale: ELocales }): Promise<string> {
  const query = gql`
    query ($all: Boolean, $locale: String) {
      navigation(all: $all, locale: $locale)
    }
  `;
  return new Promise((resolve, reject) => {
    client()()
      .request(query, variables)
      .then((data: { navigation: string }) => resolve(data?.navigation))
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function navigationCreate(variables: INavigationSettingsState): Promise<string> {
  const query = gql`
    mutation (
      $name: String!
      $description: String
      $parentId: ID
      $position: Int
      $disabled: Boolean
      $locale: String
    ) {
      navigationCreate(
        name: $name
        description: $description
        parentId: $parentId
        position: $position
        disabled: $disabled
        locale: $locale
      )
    }
  `;
  return new Promise((resolve, reject) => {
    client()()
      .request(query, variables)
      .then((data: { navigationCreate: string }) => resolve(data?.navigationCreate))
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function navigationUpdate(variables: INavigationSettingsState): Promise<string> {
  let query = gql`
    mutation ($payload: [NavigationInput!], $locale: String) {
      navigationUpdate(payload: $payload, locale: $locale)
    }
  `;
  return new Promise((resolve, reject) => {
    client()()
      .request(query, variables)
      .then((data: { navigationUpdate: string }) => resolve(data?.navigationUpdate))
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function navigationDelete(variables: { id: string; locale: ELocales }): Promise<boolean> {
  const query = gql`
    mutation ($id: ID!, $locale: String) {
      navigationDelete(id: $id, locale: $locale)
    }
  `;
  return new Promise((resolve, reject) => {
    client()()
      .request(query, variables)
      .then((data: { navigationDelete: boolean }) => resolve(data?.navigationDelete))
      .catch((error: Error) => {
        reject(error);
      });
  });
}
