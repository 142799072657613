import type { TFileInfo } from '@/components/common/data-input/DataInputJsonb/types';
import { client } from '@/requests/client';
import { gql } from 'graphql-request';

export default function dmsFileDynamicAdd({ file }: { file: File }) {
  const query = gql`
    mutation dmsFileDynamicAdd($file: Upload!) {
      dmsFileDynamicAdd(file: $file) {
        success
        message
        data {
          id
          name
          path
        }
      }
    }
  `;

  return new Promise<{ success: boolean; message?: string; data?: TFileInfo }>(
    (resolve, reject) => {
      client()()
        .request(query, { file })
        .then(
          (data: {
            dmsFileDynamicAdd: { success: boolean; message?: string; data?: TFileInfo };
          }) => {
            return resolve(data.dmsFileDynamicAdd);
          },
        )
        .catch((error: Error) => reject(error));
    },
  );
}
