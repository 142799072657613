import { gql } from 'graphql-request';
import { client } from '@/requests/client';

export default function testConnection(props) {
  const query = gql`
    query dmsTestConnection(
      $endpoint: String!
    ){
      dmsTestConnection(
        endpoint: $endpoint
      )
    }
  `;

  return new Promise((resolve, reject) => {
    client()()
      .request(query, props)
      .then((data) => resolve(data?.dmsTestConnection))
      .catch((error) => reject(error));
  });
}
