import { notification } from 'antd';
import {
  systemDatabaseGetSettings,
  systemDatabaseCreate,
  systemDatabaseDrop,
  systemDatabaseReset,
  systemDatabaseSync,
  systemDatabaseCheckConnection,
  systemDatabaseBackup,
  systemDatabaseRestore,
} from '@/requests/systemDb';
import {
  systemDbSetSettings,
  systemDbBackup,
  systemDbBackupLoading,
  systemDbBackupUploading,
} from '@/models/systemDb/actions';

export const effects = {
  *getSettingsAsync(action, { call, put }) {
    try {
      const response = yield call(systemDatabaseGetSettings);
      yield put(systemDbSetSettings(response.systemDatabaseGetSettings));
    } catch (error) {
      notification.error({
        description: error.response?.errors[0].message || error.message,
      });
    }
  },

  *createAsync({ payload }, { call }) {
    try {
      yield call(systemDatabaseCreate);
      notification.success({
        message: payload.successMsg,
      });
    } catch (error) {
      notification.error({
        message: payload.unsuccessMsg,
        description: error.response?.errors[0].message || error.message,
      });
    }
  },

  *dropAsync({ payload }, { call }) {
    try {
      yield call(systemDatabaseDrop);
      notification.success({
        message: payload.successMsg,
      });
    } catch (error) {
      notification.error({
        message: payload.unsuccessMsg,
        description: error.response?.errors[0].message || error.message,
      });
    }
  },

  *resetAsync({ payload }, { call }) {
    try {
      yield call(systemDatabaseReset);
      notification.success({
        message: payload.successMsg,
      });
    } catch (error) {
      notification.error({
        message: payload.unsuccessMsg,
        description: error.response?.errors[0].message || error.message,
      });
    }
  },

  *syncAsync({ payload }, { call }) {
    try {
      yield call(systemDatabaseSync);
      notification.success({
        message: payload.successMsg,
      });
    } catch (error) {
      notification.error({
        message: payload.unsuccessMsg,
        description: error.response?.errors[0].message || error.message,
      });
    }
  },

  *testAsync({ payload }, { call }) {
    try {
      const response = yield call(systemDatabaseCheckConnection, payload);
      if (response.systemDatabaseCheckConnection) {
        notification.success({
          message: payload.successMsg,
          description: payload.host,
        });
      } else {
        notification.error({
          message: payload.unsuccessMsg,
        });
      }
    } catch (error) {
      notification.error({
        message: payload.unsuccessMsg,
        description: error.response?.errors[0].message || error.message,
      });
    }
  },

  *backupAsync({ payload }, { call, put }) {
    try {
      yield put(systemDbBackupLoading(true));
      const response = yield call(systemDatabaseBackup);
      if (response.systemDatabaseBackup) {
        yield put(systemDbBackup(response.systemDatabaseBackup));
      } else {
        notification.error({
          message: payload.unsuccessMsg,
        });
      }
    } catch (error) {
      notification.error({
        message: payload.unsuccessMsg,
        description: error.response?.errors[0].message || error.message,
      });
    } finally {
      yield put(systemDbBackupLoading(false));
    }
  },

  *restoreAsync({ payload }, { call, put }) {
    try {
      yield put(systemDbBackupUploading(true));
      const result = yield call(systemDatabaseRestore, payload);
      if (result.systemDatabaseRestore) {
        notification.success({
          message: payload.successMsg,
        });
      } else {
        notification.error({
          message: payload.unsuccessMsg,
        });
      }
    } catch (error) {
      notification.error({
        message: payload.unsuccessMsg,
        description: error.response?.errors[0].message || error.message,
      });
    } finally {
      yield put(systemDbBackupUploading(false));
    }
  },
};
