export const systemDbGetSettingsAsync = () => ({ type: 'getSettingsAsync' });
export const systemDbSetSettings = (payload) => ({ type: 'setSettings', payload });
export const systemDbCreateAsync = (payload) => ({ type: 'systemDb/createAsync', payload });
export const systemDbDropAsync = (payload) => ({ type: 'systemDb/dropAsync', payload });
export const systemDbResetAsync = (payload) => ({ type: 'systemDb/resetAsync', payload });
export const systemDbSyncAsync = (payload) => ({ type: 'systemDb/syncAsync', payload });
export const systemDbTestAsync = (payload) => ({ type: 'systemDb/testAsync', payload });
export const systemDbBackupAsync = (payload) => ({ type: 'systemDb/backupAsync', payload });
export const systemDbBackupUploadAsync = (payload) => ({ type: 'systemDb/restoreAsync', payload });
export const systemDbBackup = (payload) => ({ type: 'backup', payload });
export const systemDbBackupLoading = (payload) => ({ type: 'backupLoading', payload });
export const systemDbBackupUploading = (payload) => ({ type: 'backupUploading', payload });
