import fileManager from './component/fileManager';
import { loggerViewerLocaleEn } from '@/components/Tools/logger-viewer/locales';
import logger from '@/locales/en-US/component/logger';

export default {
  // Common
  'component.tagSelect.expand': 'Expand',
  'component.tagSelect.collapse': 'Collapse',
  'component.tagSelect.all': 'All',
  'component.logout': 'Logout',
  'component.save': 'Save',
  'component.clear': 'Clear',
  'component.test': 'Test',
  'component.login': 'Login',
  'component.username': 'User name',
  'component.password': 'Password',
  'component.create': 'Create',
  'component.sync': 'Synchronization',
  'component.delete': 'Delete',
  'component.move': 'Move',
  'component.format': 'Format',
  'component.upload': 'Upload',
  'component.download': 'Download',
  'component.reset': 'Reset',
  'component.internalUrl': 'Address (internal)',
  'component.externalUrl': 'Address (external)',
  'component.finish': 'Finish',
  'component.enabled': 'Enabled',
  'component.disabled': 'Disabled',
  'component.not-available': 'Sorry, this page is not available to you',
  'component.home': 'Back Home',
  'component.on': 'On',
  'component.off': 'Off',
  'component.add': 'Add',
  'component.edit': 'Edit',
  'component.addition': 'Addition',
  'component.updating': 'Updating',
  'component.deleting': 'Deleting',
  'component.confirm-delete-tool-title': 'Are you sure delete?',
  'component.confirm-delete-menu-title': 'Are you sure delete menu ',
  'component.confirm-create-menu-title': 'Create menu?',
  'component.yes': 'Yes',
  'component.no': 'No',
  'component.cancel': 'Cancel',
  'component.profile': 'Profile',
  'component.label.first-name': 'First name',
  'component.label.last-name': 'Last name',
  'component.label.email': 'E-mail',
  'component.placeholder.first-name': '(Example: John)',
  'component.placeholder.last-name': 'Example: Smith',
  'component.placeholder.email': 'Example: login@gmail.com',
  'component.roles': 'Roles',
  'component.groups': 'Groups',
  'component.tools': 'Tools',
  'component.name_filer': 'Name filer',
  'component.type_filer': 'Type filter',
  'component.all': 'All',
  'component.show_disabled': 'Show disabled',
  'component.preview': 'Preview',
  'component.name': 'Name',
  'component.description': 'Description',
  'component.label': 'Label',
  'component.type': 'Type',
  'component.menu': 'Menu',
  'component.folder': 'Folder',
  'component.title.settings': 'Settings',
  'component.title.add_tool': 'Add tool',
  'component.theme': 'Theme',
  'component.current': 'Current',
  'component.light': 'Light',
  'component.dark': 'Dark',
  'component.medium': 'Medium',
  'component.full': 'Full',
  'component.kiosk': 'Kiosk',
  'component.kiosk_tv': 'Kiosk TV',
  'component.view': 'View',
  'component.client': 'Client',
  'component.client_tooltip': 'Client settings',
  'component.server': 'Server',
  'component.server_tooltip': 'Server settings',
  'component.loading': 'Loading...',
  'component.version': 'Version',
  'component.default': 'Default',
  'component.title.confirm.share.enabled': 'Are you sure set property to enable?',
  'component.title.confirm.share.disabled': 'Are you sure set property to disable?',
  'component.error.tool-unsupported': 'Tool unsupported',
  'component.example': 'Example',
  'component.error': 'Error',
  'component.endpoint': 'Endpoint',
  'component.report': 'Report',
  'component.panel': 'Panel',
  'component.panel_on_click': 'Panel onClick',
  'component.constructor': 'Constructor',
  'component.variables': 'Variables',
  'component.range': 'Range',
  'component.select_range': 'Select range',
  'component.visible': 'Visible',
  'component.use_global_property': 'Use global property',
  'component.default_value': 'Default value',
  'component.presets': 'Presets',
  'component.functions': 'Functions',
  'component.Last_5_minutes': 'Last 5 minutes',
  'component.Last_15_minutes': 'Last 15 minutes',
  'component.Last_30_minutes': 'Last 30 minutes',
  'component.Last_1_hour': 'Last 1 hour',
  'component.Last_3_hours': 'Last 3 hour',
  'component.Last_6_hours': 'Last 6 hours',
  'component.Last_12_hours': 'Last 12 hours',
  'component.Last_24_hours': 'Last 24 hours',
  'component.Last_2_days': 'Last 2 days',
  'component.Last_7_days': 'Last 7 days',
  'component.Last_30_days': 'Last 30 days',
  'component.Last_90_days': 'Last 90 days',
  'component.Last_6_months': 'Last 6 months',
  'component.Last_1_year': 'Last 1 year',
  'component.Last_2_years': 'Last 2 years',
  'component.Last_5_years': 'Last 5 years',
  'component.Current_day': 'Current day',
  'component.Current_week': 'Current week',
  'component.Current_month': 'Current month',
  'component.Current_quarter': 'Current quarter',
  'component.Current_year': 'Current year',
  'component.Yesterday': 'Yesterday',
  'component.Last_week': 'Last week',
  'component.Last_month': 'Last month',
  'component.Last_quarter': 'Last quarter',
  'component.Last_year': 'Last year',
  'component.event': 'Event',
  'component.events': 'Events',
  'component.onClick': 'onClick',
  'component.action': 'Action',
  'component.sort': 'Sort',
  'component.service_unavailable': 'Service unavailable',
  'component.show': 'Show',
  'component.hide': 'Hide',
  'component.attributes': 'Attributes',
  'component.actions': 'Actions',
  'component.state': 'State',
  'component.multiple_values': 'Multiple values',
  'component.include': 'Include',
  'component.select_all': 'SelectAll',
  'component.values_separated_by_comma': 'Values separated by comma',
  'component.decorate': 'Decorate',
  'component.logo': 'Logo',
  'component.russian': 'Russian',
  'component.english': 'English',
  'component.kazakh': 'Kazakh',
  'component.deutsch': 'Deutsch',
  'component.style': 'Style',
  'component.visibility': 'Visibility',
  'component.select-range': 'Select range',
  'component.absolute': 'Absolute',
  'component.relative': 'Relative',
  'component.from': 'of',
  'component.change-value': 'Change value',
  'component.applicationName': 'Application name',
  'component.source': 'Source',
  'component.values': 'Values',
  'component.multi-values': 'multi values',
  'component.include-select-all': 'Include [Select All]',
  'component.query': 'Query',
  'component.custom': 'Custom',
  'component.value': 'Value',
  'component.constant': 'Constant',
  'component.link': 'Link',
  'component.type-value': 'Type value',
  'component.input-variable-name': 'Input variable name!',
  'component.input-rule-name': 'Input rule name!',
  'component.name-already-exists': 'This name already exists!',
  'component.date-output-format': 'Date output format',
  'component.date-output-accuracy': 'Accuracy',
  'component.invalid-variable-value': 'invalid variable value',
  'component.change': 'Change',
  'component.unpin': 'Unpin',
  'component.removed': 'Deleted',
  'component.not-removed': 'Not Removed',
  'component.added': 'Added',
  'component.not-added': 'Not Added',
  'component.changed': 'Changed',
  'component.not-changed': 'Not changed',
  'component.user-created': 'user added',
  'component.user-updated': 'user updated',
  'component.date-updated': 'date updated',
  'component.date-created': 'date added',
  'component.conditional-formatting': 'Conditional formatting',
  'component.rule': 'Rule',
  'component.cell': 'Cell',
  'component.please-select': 'Please select',
  'component.formatter': 'Formatter',
  'component.font-color': 'Font color',
  'component.background-color': 'Background color',
  'component.font': 'Font',
  'component.condition': 'Condition',
  'component.localization.support': 'Localization support',
  'component.not-path-for-panel': 'No path for the panel',
  'component.access': 'Access',
  'component.info': 'Info',
  'component.system-info': 'System info',
  'component.width': 'Width',
  'component.empty': 'empty',
  'component.file': 'File',
  'component.not-found': 'not found',
  'component.clone': 'Clone',
  'component.row-number': 'Number row',
  'component.invalid-value': 'Invalid value entered',

  // Grafana
  'component.grafana.internal': 'Server address(internal)',
  'component.grafana.external': 'External link',
  'component.grafana.basic-api-uri': 'Basic api uri',
  'component.grafana.api-key': 'Api key',
  'component.footer.allRightReserved': 'All right reserved',
  'component.navigation': 'Navigation',
  'component.top': 'Top',
  'component.left': 'Left',
  'component.mix': 'Mix',
  'component.grafana.remove-panel': 'Удалить панель',
  'component.grafana.columns-count': 'Columns count',
  'component.grafana.row-height': 'Row height',
  'component.grafana.user-transformation': 'User transformation',
  'component.grafana.stretch-to-window-height': 'Stretch to window height',
  'component.grafana.edit-panel': 'Edit panel',
  'component.grafana.panel-group': 'Dashboard',
  'component.grafana.use-board': 'Use Board',
  'component.grafana.system-info-error': 'Failed to get service information',

  // System db
  'component.system-db.hostname': 'hostname',
  'component.system-db.port': 'port',
  'component.system-db.name': 'database name',
  'component.system-db.username': 'username',
  'component.system-db.driver': 'driver',
  'component.system-db.want-to-delete': 'This operation is irreversible. Are you sure you want to delete the system database?',
  'component.system-db.want-to-format': 'This operation is irreversible. Are you sure you want to format the system database?',

  // Sso
  'component.sso.realm': 'Realm',
  'component.sso.clientId': 'Client ID',
  'component.sso.port': 'Port',
  'component.sso.secret': 'Secret',
  'component.sso.system-info-error': 'Failed to get service information',

  // Rs
  'component.rs.system-info-error': 'Failed to get service information',

  // User-profile
  'component.profile.login-name': 'Login name',
  'component.profile.new-password': 'New password',
  'component.profile.new-password-repeat': 'New password [repeat]',
  'component.profile.change-password': 'Change Password',

  // Hasura
  'component.hasura.service-address': 'Service address',
  'component.hasura.server': 'Server',
  'component.hasura.server-settings': 'Server settings',
  'component.hasura.select.label': 'Table',
  'component.hasura.select.placeholder': 'Select table',
  'component.hasura.fields': 'Fields',
  'component.hasura.table.label': 'Label',
  'component.hasura.table.type': 'Type',
  'component.hasura.table.attributes': 'Attributes',
  'component.hasura.table.action': 'Action',
  'component.hasura.field': 'Field',
  'component.hasura.no-description': 'No description',
  'component.hasura.fields-not-configured': 'Fields not configured',
  'component.hasura.form-constructor': 'Constructor form input/output',
  'component.hasura.min-height': 'Min height',
  'component.hasura.min-width': 'Min width',
  'component.hasura.max-height': 'Max height',
  'component.hasura.max-width': 'Max width',
  'component.hasura.columns-quantity': 'Columns quantity',
  'component.hasura.missing-primary-key': 'Missing primary key',
  'component.hasura.table-settings': 'Table settings',
  'component.hasura.decorate': 'Decorate',
  'component.hasura.fitAndFill': 'Lines to full screen width',
  'component.hasura.rowHeight': 'Row height',
  'component.hasura.fix': 'Fix',
  'component.hasura.rowsTop': 'Rows (top)',
  'component.hasura.colsLeft': 'Columns (left)',
  'component.hasura.colsRight': 'Columns (right)',
  'component.hasura.export': 'Export',
  'component.hasura.import': 'Import',
  'component.hasura.output': 'Output',
  'component.hasura.relation': 'Relation',
  'component.hasura.restrictions': 'Restrictions',
  'component.token': 'Token',
  'component.hasura.content-type': 'Content Type',
  'component.hasura.no-value': 'No value',
  'component.hasura.source': 'Source',
  'component.hasura.matrix': 'Matrix',
  'component.hasura.axisY': 'Y axis',
  'component.hasura.axisX': 'X axis',
  'component.hasura.matrix-field': 'Matrix field',
  'component.hasura.nesting-level': 'Nesting level',
  'component.hasura.pagination': 'Pagination',
  'component.hasura.rows-per-page': 'rows per page',
  'component.hasura.conditions': 'Conditions',
  'component.hasura.sorting-type': 'Sorting type',
  'component.hasura.soft': 'Soft',
  'component.hasura.invalid-json-format': 'Invalid json format',

  // Global
  'component.global.name-restriction': 'Text no more than 50 characters',
  'component.global.desc-restriction': 'Text no more than 255 characters',
  'component.global.language-selection': 'Language selection',
  'component.global.default-value': 'Default value',
  'component.global.languages': 'Languages',

  // AuthTools
  'component.auth.users': 'Users',
  'component.auth.disabled-users': 'Disabled users',
  'component.auth.groups': 'Groups',
  'component.auth.disabled-tools': 'Disabled tools',
  'component.auth.disabled': 'Disabled',
  'component.auth.enabled': 'Enabled',
  'component.auth.inherited': 'Inherited',
  'component.auth.default': 'Default',
  'component.auth.name-filter': 'Name filter',
  'component.auth.tools-filter': 'Tools filter',

  // Navigation
  'component.navigation.change-directory': 'Change directory',
  'component.navigation.change-tool': 'Change tool',

  // Crud
  'component.function.pagination-top': 'Top',
  'component.function.pagination-bottom': 'Bottom',
  'component.ui.CrudFormConstructor.tooltipColumnsQuantity':
    'Free up the space used by the elements first, than decrease the value',

  // Report
  'component.report.path-not-set': 'The path for the report is not set!',

  // DataInput
  'component.data-input.choose': 'choose',
  'component.data-input.download': 'download',
  'component.data-input.upload': 'upload',
  'component.data-input.new-file': 'New file',
  'component.data-input.date-of-download': 'date of download',
  'component.data-input.name-of-uploaded-file': 'Name of the uploaded file',
  'component.data-input.no-file-selected': 'No file selected',
  'component.data-input.delete-the-selected-file': 'Delete the selected file?',
  'component.data-input.enter-file-name': 'Enter file name',

  // DataInputs placeholders
  'component.data.input.int': 'Example: 123',
  'component.data.input.bigint': 'Example: 123',
  'component.data.input.string': 'Example: Symbols',
  'component.data.input.float8': 'Example: 123.321',
  'component.data.input._float8': 'Example: 123.321',
  'component.data.input._int4': 'Example: 123',
  'component.data.input.timestamp': 'Select date and time',
  'component.data.input.timestamptz': 'Select date and time',
  'component.data.input.date': 'Select date',
  'component.data.input.bpchar': 'Example: abc',
  'component.data.input.object': 'Please select',
  'component.data.input.time': 'Select time',
  'component.data.input._varchar': 'Example: abc',
  'component.data.input.uuid': 'Example: 123e4567-e89b-12d3-a456-426614174000',
  'component.data.input.json': 'Example: { "fileName": "my file name", "fileSize": 1024 }',
  'component.data.input.jsonb': 'Example: { "fileName": "my file name", "fileSize": 1024 }',
  'component.data.input.file-selection': 'File selection',

  ...fileManager,
  ...logger,
  ...loggerViewerLocaleEn,
};
