export const reducers = {
  setSettings(state, { payload }) {
    state.settings = { ...state.settings, ...payload };
  },

  setDashboard(state, { payload }) {
    state.dashboard = payload;
  },

  setDashboards(state, { payload }) {
    state.dashboards = payload;
  },

  setFolders(state, { payload }) {
    state.folders = payload;
  },

  setFoldersIsLoading(state, { payload }) {
    state.foldersIsLoading = payload;
  },
  setGrafanaSystemInfo(state, { payload }) {
    state.systemInfo = payload
  }
};
