import { setGlobalSettingsAction } from '@/models/global/actions';
import { globalSettingsUpdateAll, getGlobalSettings } from '@/requests/global';
import { IMessage } from '@/types/message';
import { notification } from 'antd';
import { Saga } from '@redux-saga/types';
import { SagaReturnType } from 'redux-saga/effects';
type GlobalSettings = SagaReturnType<typeof getGlobalSettings>;

export const effects = {
  *updateGlobalSettingsEffect(action: { payload: string; messages: IMessage }, saga: Saga) {
    try {
      const response: GlobalSettings = yield saga.call(globalSettingsUpdateAll, action.payload);
      if (response.success) {
        notification.success({
          message: action.messages.success,
        });
      } else {
        notification.error({
          message: response.message,
        });
      }
    } catch ({ message }) {
      notification.error({ message });
    }
  },

  *getGlobalSettingsEffect(action: { messages: IMessage }, saga: Saga) {
    try {
      const response: GlobalSettings = yield saga.call(getGlobalSettings);
      if (response?.success) {
        yield saga.put(setGlobalSettingsAction(response.data));
      } else {
        notification.error({
          message: action.messages.unsuccess,
        });
      }
    } catch ({ message }) {
      notification.error({ message });
    }
  },
};
