import {
  _setFolderTree,
  _setError,
  _setSelectedFolder, _setUploadedFileInfo,
} from '@/models/fileManager/actions';
import {
  getFolderTree,
  dmsFolder,
  dmsFolderDelete,
  dmsFolderAdd,
  dmsFileAdd,
  dmsFileDelete,
} from '@/requests/dms';
import dmsFileDynamicAdd from '@/requests/dms/dmsFileDynamicAdd';
import type {
  TFolderTreeItem,
  TFolder,
  TFileManagerResponseError,
  TFileManagerFolderDeleteResponse,
  TFileManagerFolderCreateResponse,
  TFileManagerFileAddResponse,
  TFileManagerFileDeleteResponse,
  TFileManagerFileUploadResponse,
} from '@/types/store/fileManager';
import { notification } from 'antd';

export const effects = {
  getFolderTreeAsync: [
    function* ({ payload }: { payload: { toolName: string } }, { call, put }: any) {
      try {
        const res: TFolderTreeItem = yield call(getFolderTree, payload);
        yield put(_setFolderTree(res));
      } catch (message: any) {
        console.log(`getFolderTreeAsync: ${message}`);
        notification.error({
          message,
        });
        yield put(_setError(message));
      }
    },
    { type: 'takeLatest' },
  ],

  getSelectedDirContentAsync: [
    function* ({ payload }: { payload: { toolName: string; path: string } }, { call, put }: any) {
      try {
        const selectedDirContent: TFolder | TFileManagerResponseError = yield call(
          dmsFolder,
          payload,
        );
        if ((selectedDirContent as TFileManagerResponseError).message) {
          yield put(_setError((selectedDirContent as TFileManagerResponseError).message));
        } else {
          yield put(_setSelectedFolder(selectedDirContent as TFolder));
        }
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    },
    { type: 'takeLatest' },
  ],

  deleteFolderAsync: [
    function* ({ payload }: { payload: { toolName: string; path: string } }, { call }: any) {
      try {
        const response: TFileManagerFolderDeleteResponse = yield call(dmsFolderDelete, payload);
        if (response.success) {
          notification.success({
            message: 'Директория успешно удалена.',
          });
        } else {
          notification.error({
            message: response.message,
          });
        }
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    },
    { type: 'takeLatest' },
  ],

  createFolderAsync: [
    function* ({ payload }: { payload: { toolName: string; path: string } }, { call }: any) {
      try {
        const response: TFileManagerFolderCreateResponse = yield call(dmsFolderAdd, payload);
        if (response.success) {
          notification.success({
            message: 'Директория успешно добавлена.',
          });
        } else {
          notification.error({
            message: response.message,
          });
        }
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    },
    { type: 'takeLatest' },
  ],

  addFileAsync: [
    function* (
      { payload }: { payload: { toolName: string; path: string; file: File } },
      { call }: any,
    ) {
      try {
        const response: TFileManagerFileAddResponse = yield call(dmsFileAdd, payload);
        if (response.success) {
          notification.success({
            message: 'Файл успешно добавлен.',
          });
        } else {
          notification.error({
            message: 'Ошибка',
            description: 'Не удалось добавить файл.',
          });
        }
      } catch (error: any) {
        notification.error({
          message: 'Ошибка',
          description: error.message,
        });
      }
    },
    { type: 'takeLatest' },
  ],

  addFileDynamicAsync: [
    function* ({ payload }: { payload: { file: File } }, { call, put }: any) {
      try {
        const response: TFileManagerFileUploadResponse = yield call(dmsFileDynamicAdd, payload);
        if (response.success) {
          yield put(_setUploadedFileInfo(response.data));
        } else {
          notification.error({
            message: response.message,
          });
        }
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    },
    { type: 'takeLatest' },
  ],

  deleteFileAsync: [
    function* ({ payload }: { payload: { toolName: string; path: string } }, { call }: any) {
      try {
        const response: TFileManagerFileDeleteResponse = yield call(dmsFileDelete, payload);
        if (response.success) {
          notification.success({
            message: 'Файл успешно удален.',
          });
        } else {
          notification.error({
            message: 'Ошибка',
            description: 'Не удалось удалить файл.',
          });
        }
      } catch (error: any) {
        notification.error({
          message: 'Ошибка',
          description: error.message,
        });
      }
    },
    { type: 'takeLatest' },
  ],
};
