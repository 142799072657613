import { client } from '@/requests/client';
import { gql } from 'graphql-request';

export function dbsSettings() {
  const query = gql`
    query {
      dbsSettings {
        available
        enabled
        url
      }
    }
  `;
  return client()().request(query);
}

export function dbsTestConnection(variables) {
  const query = gql`
    query ($url: String!) {
      dbsTestConnection(url: $url)
    }
  `;
  return client()().request(query, variables);
}

export function dbsSetSettings(variables) {
  const query = gql`
    mutation ($enabled: Boolean, $url: String) {
      dbsSetSettings(enabled: $enabled, url: $url) {
        available
        enabled
        url
      }
    }
  `;
  return client()().request(query, variables);
}

export function dbsGetTables(variables) {
  const q = '{__schema {queryType {fields {name args {name} } } } }';
  const query = gql`
    query ($toolName: String!) {
      dbsRequire(toolName: $toolName, query:"${q}" )
    }
  `;
  return client()().request(query, variables);
}

export function dbsGetMutations(variables) {
  const q = '{__schema {mutationType {fields {name args {name} } } } }';
  const query = gql`
      query($toolName: String!) {
          dbsRequire(toolName: $toolName, query:"${q}" )
      }
  `;
  return client()().request(query, variables);
}

export function dbsGetTable(tableName, variables) {
  const query = gql`
    query($toolName: String!) {
        dbsRequire(toolName:$toolName, query: "{__type(name:\\"${tableName}\\") {name description fields {name description type {kind name description ofType {kind name description fields {name description type { kind name description ofType { kind name description } } } } } } } }")
    }
  `;
  return client()().request(query, variables);
}

export function dbsGetTableMeta(tableName, variables) {
  const query = gql`
    query($toolName: String!) {
        dbsRequire(toolName:$toolName, query: "{__type(name:\\"${tableName}\\") {name description fields {name description type {kind name description ofType {kind name description } } } } }")
    }
  `;
  return client()().request(query, variables);
}

export function dbsGetRowsCount(query, variables) {
  const q = gql`
    query ($toolName: String!) {
      dbsRequire(toolName: $toolName, query: "${query}")
    }
  `;
  return client()().request(q, variables);
}

export function dbsGetViewerTable(fields, variables) {
  const query = gql`
    query($toolName: String!) {
      dbsRequire(toolName: $toolName, query: "${fields}")
    }
  `;
  return client()().request(query, variables);
}

export function dbsGetMatrixTable(query, variables) {
  const q = gql`
    query($toolName: String!) {
      dbsRequire(toolName: $toolName, query: "${query}")
    }
  `;
  return client()().request(q, variables);
}

export function dbsGetFieldById(pkField, fieldName, tableName, variables) {
  // tableName(where: {pkField.name: {_eq: pkField.value}}) { fieldName }
  const q = `{ ${tableName}(where: {${pkField.name}: {_eq: ${pkField.value} } }) { ${fieldName} } }`;
  const query = gql`
    query($toolName: String!) {
      dbsRequire(toolName: $toolName, query: "${q}")
    }
  `;
  return client()().request(query, variables);
}

export function dbsAddRow({ mutation, args, returning }, variables) {
  const q = `mutation { insert_${mutation}(objects: {${args}}) { affected_rows } }`;
  console.log(q);
  const query = gql`
    query($toolName: String!) {
      dbsRequire(toolName: $toolName, query: "${q}")
    }
  `;
  return client()().request(query, variables);
}

export function dbsDeleteRow({ mutation, primaryKey, returning }, variables) {
  const q = `mutation { delete_${mutation}(where: { ${primaryKey.key}: {_eq: \\"${primaryKey.value}\\" } }) { affected_rows } }`;
  console.log(q);
  const query = gql`
    query($toolName: String!) {
      dbsRequire(toolName: $toolName, query: "${q}")
    }
  `;
  return client()().request(query, variables);
}

export function dbsUpdateRow({ mutation, primaryKey, fields, returning, append }, variables) {
  const pkKey = primaryKey.key;
  const pkValue = primaryKey.value;
  const q = `mutation { update_${mutation}(where: {${pkKey}: {_eq: \\"${pkValue}\\" }}, _set: { ${fields} }, ${append}) { affected_rows } }`;
  console.log(q);
  const query = gql`
    query($toolName: String!) {
      dbsRequire(toolName: $toolName, query: "${q}")
    }
  `;
  return client()().request(query, variables);
}

export function dbsSoftDeleteRow({ mutation, primaryKey, append, set = '' }, variables) {
  const pkKey = primaryKey.key;
  const pkValue = primaryKey.value;
  const q = `mutation { update_${mutation}(where: {${pkKey}: {_eq: \\"${pkValue}\\" }}, _set: {${set}}, ${append}) { affected_rows } }`;
  console.log(q);
  const query = gql`
    query($toolName: String!) {
      dbsRequire(toolName: $toolName, query: "${q}")
    }
  `;
  return client()().request(query, variables);
}
