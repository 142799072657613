import { routes } from '@/utils/routes/path';
import { grafanaGetSettingsAsync } from '@/models/grafana/actions';

export const subscriptions = {
  setup({ dispatch, history }) {
    history.listen(({ pathname }) => {
      if (
        pathname === routes.tools.system.grafanaSettings ||
        pathname === `${routes.tools.system.grafanaSettings}/`
      ) {
        dispatch(grafanaGetSettingsAsync());
      }
    });
  },
};
