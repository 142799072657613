import { client } from '@/requests/client';
import { gql } from 'graphql-request';

export async function userGetProfile() {
  const query = gql`
    query {
      me {
        id
        username
        firstName
        lastName
        email
        groups {
          id
          name
          tools {
            id
            name
            description
            enabled
            label
            moduleName
            navigationId
            single
          }
          roles {
            id
            name
          }
        }
        tools {
          id
          name
          description
          enabled
          label
          moduleName
          navigationId
          single
        }
        roles {
          id
          name
        }
      }
    }
  `;
  return client()().request(query);
}

export async function userSetProfile(variables) {
  const query = gql`
    mutation ($firstName: String, $lastName: String, $email: String) {
      userSetProfile(firstName: $firstName, lastName: $lastName, email: $email)
    }
  `;
  return client()().request(query, variables);
}

export async function userSetPassword(variables) {
  const query = gql`
    mutation ($password: String) {
      userSetPassword(password: $password)
    }
  `;
  return client()().request(query, variables);
}
