import { state } from '@/models/user/state';
import { reducers } from '@/models/user/reducers';
import { effects } from '@/models/user/effects';
import { subscriptions } from '@/models/user/subscriptions';

export default {
  namespace: 'user',
  state,
  reducers,
  effects,
  subscriptions,
};
