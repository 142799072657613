import KeyCloakService from '@/services/keycloak';

async function init(settings) {
  //await KeyCloakService.loadSettings();
  KeyCloakService.setSettings(settings);
  let kcSettings = KeyCloakService.getSettings();

  if (kcSettings.api.status) {
    if (kcSettings.auth.status) {
      await KeyCloakService.initKeycloak(() => {});
      if (KeyCloakService.isLoggedIn()) {
        let token = KeyCloakService.getToken();
        localStorage.setItem('token', token);
      } else {
        KeyCloakService.doLogin();
      }
    }
  }
}

const updateToken = async (minValidity = -1) => {
  let token = await KeyCloakService.updateToken(minValidity);
  return new Promise((resolve, reject) => {
    if (token !== undefined) {
      localStorage.setItem('token', token);
      resolve(token);
    } else {
      localStorage.removeItem('token');
      reject(undefined);
    }
  });
};

const updateTokenTimer = (ms = 15000) => {
  return setInterval(() => {
    KeyCloakService.getUserInfo()
      ?.then(() => {
        let tokenInfo = getTokenInfo();
        if (tokenInfo) {
          let currentUnixTime = Math.round(new Date().getTime() / 1000);
          let expireUnixTime = tokenInfo?.exp;
          if (currentUnixTime > expireUnixTime) {
            updateToken()
              .then(() => {})
              .catch(() => {
                KeyCloakService?.doLogout();
              });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        updateToken()
          .then(() => {})
          .catch(() => {
            KeyCloakService?.doLogout();
          });
      });
  }, ms);
};

const isLoggedIn = () => KeyCloakService.isLoggedIn();

function getTokenInfo() {
  return KeyCloakService?.tokenParsed();
}

function getUser() {
  let tokenInfo = getTokenInfo();
  if (tokenInfo) {
    return {
      name: tokenInfo?.name,
      userid: tokenInfo?.sub,
      email: tokenInfo?.email,
      signature: tokenInfo?.jti,
      title: tokenInfo?.preferred_username,
      roles: [
        ...tokenInfo?.realm_access.roles,
        !KeyCloakService.service.finishSettings && 'installer',
        !KeyCloakService.service.available && 'sso-error',
      ],
    };
  }
  return {};
}

const auth = {
  init,
  getUser,
  getTokenInfo,
  isLoggedIn,
  updateToken,
  updateTokenTimer,
};

export default auth;
