import type { TFolderTreeNode } from '@/components/Tools/FileManager/types';
import type { TFileInfo } from '@/components/common/data-input/DataInputJsonb/types';
import type { Maybe } from '@/types';
import type { IFileManagerSettingsState, TFolder } from '@/types/store/fileManager';

export const reducers = {
  setFolderTree(
    state: IFileManagerSettingsState,
    { payload }: { payload: TFolderTreeNode },
  ): IFileManagerSettingsState {
    return {
      ...state,
      folderTree: payload,
    };
  },

  setSelectedFolder(
    state: IFileManagerSettingsState,
    { payload }: { payload: { success: boolean; message: Maybe<string>; data: TFolder } },
  ): IFileManagerSettingsState {
    return {
      ...state,
      selectedFolder: payload.data,
    };
  },

  setError(
    state: IFileManagerSettingsState,
    { payload }: { payload: string },
  ): IFileManagerSettingsState {
    return {
      ...state,
      error: payload,
    };
  },

  setUploadedFileInfo(
    state: IFileManagerSettingsState,
    { payload }: { payload: TFileInfo | null },
  ): IFileManagerSettingsState {
    return {
      ...state,
      uploadedFileInfo: payload,
    };
  },
};
