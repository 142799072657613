export const _setStateReducer = (payload) => ({ type: 'setStateReducer', payload });
export const setStateReducer = (payload) => ({ type: 'tools/setStateReducer', payload });

export const _setToolReducer = (payload) => ({ type: 'setToolReducer', payload });
export const setToolReducer = (payload) => ({ type: 'tools/setToolReducer', payload });

export const _clearToolReducer = (payload) => ({ type: 'clearToolReducer', payload });
export const clearToolReducer = (payload) => ({ type: 'tools/clearToolReducer', payload });

export const _setToolsReducer = (payload) => ({ type: 'setToolsReducer', payload });
export const setToolsReducer = (payload) => ({ type: 'tools/setToolsReducer', payload });

export const _setNewToolReducer = (payload) => ({ type: 'setNewToolReducer', payload });
export const setNewToolReducer = (payload) => ({ type: 'tools/setNewToolReducer', payload });

export const _clearNewToolReducer = (payload) => ({ type: 'clearNewToolReducer', payload });
export const clearNewToolReducer = (payload) => ({ type: 'tools/clearNewToolReducer', payload });

export const _setSaveToolReducer = (payload) => ({ type: 'setSaveToolReducer', payload });
export const setSaveToolReducer = (payload) => ({ type: 'tools/setSaveToolReducer', payload });
export const clearToolSettings = (payload) => ({
  type: 'tools/clearToolSettings',
  payload,
});

export const _clearSaveToolReducer = (payload) => ({ type: 'clearSaveToolReducer', payload });
export const clearSaveToolReducer = (payload) => ({ type: 'tools/clearSaveToolReducer', payload });

export const _setSelectToolReducer = (payload) => ({ type: 'setSelectToolReducer', payload });
export const setSelectToolReducer = (payload) => ({ type: 'tools/setSelectToolReducer', payload });

export const _setToolModulesReducer = (payload) => ({ type: 'setToolModulesReducer', payload });
export const setToolModulesReducer = (payload) => ({
  type: 'tools/setToolModulesReducer',
  payload,
});

export const _toolAsync = (payload) => ({ type: 'toolAsync', payload });
export const toolAsync = (payload) => ({ type: 'tools/toolAsync', payload });

export const _toolsAsync = (payload) => ({ type: 'toolsAsync', payload });
export const toolsAsync = (payload) => ({ type: 'tools/toolsAsync', payload });

export const _toolModulesAsync = (payload) => ({ type: 'toolModulesAsync', payload });
export const toolModulesAsync = (payload) => ({ type: 'tools/toolModulesAsync', payload });

export const _toolCreateAsync = (payload) => ({ type: 'toolCreateAsync', payload });
export const toolCreateAsync = (payload) => ({ type: 'tools/toolCreateAsync', payload });

export const _toolUpdateAsync = (payload) => ({ type: 'toolUpdateAsync', payload });
export const toolUpdateAsync = (payload) => ({ type: 'tools/toolUpdateAsync', payload });

export const toolCancelAsync = (payload) => ({ type: 'tools/toolCancelAsync', payload })

export const _toolDeleteAsync = (payload) => ({ type: 'toolDeleteAsync', payload });
export const toolDeleteAsync = (payload) => ({ type: 'tools/toolDeleteAsync', payload });

export const _toolSetSettingsAsync = (payload) => ({ type: 'toolSetSettingsAsync', payload });
export const toolSetSettingsAsync = (payload) => ({ type: 'tools/toolSetSettingsAsync', payload });

export const getUsersAndGroupsAsync = (payload) => ({ type: 'tools/getUsersAndGroupsAsync', payload });
export const setUsersAndGroupsReducer = (payload) => ({ type: 'tools/setUsersAndGroupsReducer', payload });
export const _setUsersAndGroupsReducer = (payload) => ({ type: 'setUsersAndGroupsReducer', payload });
export const setAccessDefaultValueReducer = (payload) => ({ type: 'tools/setAccessDefaultValue', payload })
