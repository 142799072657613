import { notification } from 'antd';
import app from './services/app';

export async function getInitialState() {
  return { ...(await app.init()) };
}

export const request = {
  errorHandler: (error) => {
    const { response } = error;

    if (!response) {
      notification.error({
        description: response,
        message: 'Error',
      });
    }

    throw error;
  },
};

/*export const layout = async ({ initialState }) => {
  let result = {
    pageTitleRender: false,
    disableContentMargin: false,
    footerRender: () => <Footer />,
    links: [],
    menuHeaderRender: undefined,
    // 自定义 403 页面
    //unAccessible: <div>unAccessible</div>,
    ...initialState?.settings,
  };

  let authTrue = {
    rightContentRender: () => <RightContent />,
  };

  if (!KeyCloakService.getSettings()?.finish) {
    console.log('/auth/SSO-settings');
    history.push('/auth/SSO-settings');
  }

  if (KeyCloakService.isLoggedIn()) {
    return {
      ...result,
      ...authTrue,
    };
  }

  return result;
};*/
