import { gql } from 'graphql-request';
import { client } from '@/requests/client';

export default function dmsFolder(props) {
  const query = gql`
    query dmsFolder(
      $toolName: String!,
      $path: String!,
    ) {
      dmsFolder(
        toolName: $toolName
        path: $path
      ){
        success
        message
        data{
          name
          path
          date
          children{
            type
            name
            mimetype
            size
            date
          }
        }
      }
    }
  `;

  return new Promise((resolve, reject) => {
    client()()
      .request(query, props)
      .then((data) => resolve(data?.dmsFolder))
      .catch((error) => reject(error));
  });
}
