import Keycloak from 'keycloak-js';
import { ssoGetSettings } from '@/requests/auth';
import { cConsole, getAbsoluteUrl } from '@/third/utils';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { testApiServer } from '@/requests/app';

let service = {
  api: { status: false },
  available: { status: false },
  finish: { status: false },
  settings: {},
};

let _kc = undefined;
//let _kc = new Keycloak('kc.json');

const API_TYPE = {
  GET: 'GET',
  POST: 'POST',
};

const API_LIST = {
  userInfo: (url = service.settings.url, realm = service.settings.clientId) =>
    `${url}/realms/${realm}/protocol/openid-connect/userinfo`,
};

/*
 * ${url}/realms/${realm}/protocol/openid-connect/userinfo
 * */
const api = (type = API_TYPE.POST, url, headers = undefined, data = {}) => {
  switch (type) {
    case API_TYPE.GET:
      let dataStr = '';
      if (Object.keys(data).length) {
        dataStr = '?';
        Object.keys(data).forEach((key, index) => {
          dataStr = (index > 0 ?? '&') + key + '=' + dataStr[key];
        });
      }
      return axios.get(API_LIST.userInfo() + dataStr, { headers });
    case API_TYPE.POST:
      return axios.post(API_LIST.userInfo(), data, { headers });
    default:
      return;
  }
};

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (trueCallback, falseCallback = () => {}) => {
  try {
    let settings = getSettings();
      _kc = new Keycloak({
        url: getAbsoluteUrl(settings.settings['url']),
        realm: settings.settings['realm'],
        clientId: settings.settings['clientId'],
      });
      return _kc
        .init({
          //flow: 'implicit',
          //adapter: 'cordova-native',
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
          //checkLoginIframe: false,
          //onLoad: 'login-required',
          //silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
          pkceMethod: 'S256',
        })
        .then((data) => {
          return trueCallback();
        });

  } catch (e) {
    console.log(e);
  }
};

const updateToken = async (minValidity = -1) => {
  return (await _kc.updateToken(minValidity)) ? _kc.token : undefined;
};

const pingService = () => {
  return true;
};

const getSettings = () => {
  return service;
};

const setSettings = (settings) => {
  service = settings;
};

const doLogin = () => _kc?.login();

const doLogout = () => _kc?.logout();

const getToken = () => _kc?.token;

const authenticated = () => _kc?.authenticated;

const isLoggedIn = () => !!_kc?.token;

const getUsername = () => _kc?.tokenParsed?.preferred_username;

const getUserRoles = () => {
  Object.keys(_kc?.tokenParsed).map((key) => {
    cConsole.log(key, _kc?.tokenParsed[key]);
  });
};
const tokenParsed = () => _kc?.tokenParsed;
const getUserInfo = (props) => _kc?.loadUserInfo(props);
const getUserProfile = (props) => _kc?.loadUserProfile(props);

const hasRole = (roles) => roles.some((role) => _kc?.hasRealmRole(role));

const KeyCloakService = {
  initKeycloak,
  authenticated,
  getSettings,
  setSettings,
  pingService,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getUsername,
  hasRole,
  getUserInfo,
  getUserProfile,
  getUserRoles,
  service,
  refreshToken: () => _kc?.refreshToken,
  updateToken,
  tokenParsed,
};

export default KeyCloakService;
