import { wrapSlashes } from '@/components/Tools/Crud/utils';
import type { Maybe } from '@/types';
import { ELocaleKeys, ELocales } from '@/types/enums';
import { isArray, isPlainObject } from 'lodash';
import type { ChangeEvent, Dispatch, SetStateAction } from 'react';

let isDevelopment = process.env.NODE_ENV === 'development';

let env = {};

try {
  env = {
    ...ENV,
  };
} catch (e) {
  console.log(e);
}

const currentLocation = env.APP_API_URL || window.location.origin;

export const roles = {
  admin: 'admin',
  system: 'system',
  user: 'user',
};

export function cConsole() {
  return { log: (...args: any) => isDevelopment && console.log(...args) };
}

export function normalizePath(path: string) {
  if (path[path.length - 1] === '/') {
    return path.substr(0, path.length - 1);
  }
  return path;
}

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

function getValueLambda(
  node: Record<string, any>,
  names: any[],
  iteration = 0,
): { find: boolean; data?: any } {
  let currentName = names[0];
  if (node.hasOwnProperty(currentName)) {
    let currentNode = node[currentName];
    if (names.length > 1) {
      return getValueLambda(
        currentNode,
        names.filter((item, index) => index !== 0),
        iteration++,
      );
    } else {
      return { find: true, data: currentNode };
    }
  } else {
    return { find: false };
  }
}

function createObjectLambda(defaultNode, node, names, value, allData = false) {
  let currentName = names[0];
  let currentDefaultNode = defaultNode?.[currentName];
  let currentNode = node?.[currentName];

  if (names.length > 1) {
    return {
      [currentName]: {
        ...currentNode,
        ...createObjectLambda(
          currentDefaultNode,
          currentNode,
          names.filter((item, index) => index !== 0),
          value,
          allData,
        ),
      },
    };
  } else {
    return { [currentName]: value };
  }
}

function convertValue(value, defaultValue) {
  if (value) {
    switch (typeof defaultValue) {
      case 'number':
        return Number(value);
      case 'boolean':
        return Boolean(value);
      case 'string':
        return String(value);
      default:
        return value;
    }
  }
}

type TGetStateValueArgs<TSave, TState> = {
  states: [TSave, TState];
  name: string;
  defaultValue: string | number | boolean | any[];
  separator?: string;
};

export const getStateValue: <TSave, TState>(args: TGetStateValueArgs<TSave, TState>) => any = ({
  states = [],
  name,
  defaultValue = '',
  separator = '.',
}) => {
  let names = name.split(separator);
  if (states.length >= 1) {
    let value = undefined;
    states.find((state) => {
      let result = getValueLambda(state, names);
      if (result.find) {
        value = result?.data;
        return true;
      }
    });
    return convertValue(value, defaultValue);
  }
  return defaultValue;
};

type TSetStateValue<TSave, TState> = {
  defaultState: TState;
  state: TSave | TState;
  e: ChangeEvent<HTMLInputElement>;
  setState: Dispatch<SetStateAction<TSave>> | undefined;
  separator?: string;
};

export const setStateValue: <TSave, TState>(args: TSetStateValue<TSave, TState>) => void = ({
  defaultState,
  state,
  e,
  setState = undefined,
  separator = '.',
}) => {
  if (setState) {
    let eValue = e.target.hasOwnProperty('checked') ? e.target.checked : e.target.value;
    let eName = e.target.name;
    let result = createObjectLambda(defaultState, state, eName.split(separator), eValue);
    if ((defaultState as Object).hasOwnProperty(eName)) {
      eValue = convertValue(eValue, defaultState[eName]);
    }
    setState((prev) => {
      return { ...prev, ...result };
    });
  }
};

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const canRole = (roles: string[], role: string) => roles?.includes(role) || false;

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export function ping(ip, callback) {
  let img = new Image();
  if (!this.inUse) {
    this.inUse = true;
    this.callback = callback;
    this.ip = ip;

    let _that = this;

    img = new Image();

    img.onload = function () {
      console.log('onload', _that);
    };
    img.onerror = function () {
      console.log('onerror', _that);
    };
    this.start = new Date().getTime();
    img.src = ip;
    this.timer = setTimeout(function () {
      console.log('', _that);
    }, 1500);
  }
}

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const getAbsoluteUrl = (url) => {
  if (url) {
    let originUrl = currentLocation;
    let abs = url.substring(0, 1);
    if (abs === '/') {
      let absUrl = url;
      let modUrl = originUrl;
      if (modUrl[modUrl.length - 1] === '/') {
        modUrl = modUrl.substring(0, originUrl.length - 1);
      }
      return `${modUrl}${absUrl}`;
    }
  }
  return url;
};

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const dataToChunkObject = ({
  prefix = '',
  name = 'string',
  postfix = '',
  length = 256,
  data,
}) => {
  let newData = JSON.stringify(data);
  let meta = {
    name: prefix + name + postfix,
    length: newData.length,
    type: typeof data,
    chunks: Math.ceil(newData.length / length),
  };
  const newArray = new Array(meta.chunks);
  let newObject = {};

  for (let i = 0; i < meta.chunks; i++) {
    let start = i * length;
    newObject[`${meta.name}_chunk[${i}]`] = newData.substr(start, length);
  }
  return { [`${meta.name}_meta`]: JSON.stringify(meta), ...newObject };
};

export const chunkObjectToData = ({ name = 'string', key = 'key', value = 'value', data }) => {
  let chunkObject = {};
  if (Array.isArray(data)) {
    let newData = data
      .filter((item) => item[key].substr(0, `${name}_`.length) === `${name}_`)
      .forEach((item) => {
        chunkObject[item[key]] = item[value];
      });
  } else {
    chunkObject = { ...data };
  }

  if (chunkObject.hasOwnProperty(`${name}_meta`)) {
    let meta = JSON.parse(chunkObject?.[`${name}_meta`] || '{}');
    if (meta?.chunks) {
      let stringData = '';
      for (let i = 0; i < meta.chunks; i++) {
        stringData = stringData + chunkObject?.[`${meta.name}_chunk[${i}]`] || '';
      }
      return JSON.parse(stringData || '');
    }
  }
  return undefined;
};

export const strToBool = (value: string | number) => Boolean(Number(value));

export const getBlobUrlFromHexString = (hexString: string) => {
  hexString = hexString.replace(/[^A-Fa-f0-9]/g, '');
  if (hexString.length % 2) {
    console.log('невалидная строка');
    return;
  }

  const binary = [];
  for (let i = 0; i < hexString.length / 2; i++) {
    let h = hexString.substr(i * 2, 2);
    binary[i] = parseInt(h, 16);
  }

  const byteArray = new Uint8Array(binary);

  return window.URL.createObjectURL(new Blob([byteArray], { type: 'application/octet-stream' }));
};

export const jsonParseSafely = (str: Maybe<string>): Maybe<string> => {
  if (str === null) return str;
  try {
    return JSON.parse(str!);
  } catch (error) {
    return str;
  }
};

export const getLocaleKeyByLocale = (locale: ELocales): ELocaleKeys => {
  let localeKey: ELocaleKeys;
  switch (locale) {
    case ELocales.RU:
      localeKey = ELocaleKeys.RU;
      break;
    case ELocales.EN:
      localeKey = ELocaleKeys.EN;
      break;
    case ELocales.KZ:
      localeKey = ELocaleKeys.KZ;
      break;
    case ELocales.DE:
      localeKey = ELocaleKeys.DE;
      break;
    default:
      localeKey = ELocaleKeys.RU;
  }
  return localeKey;
};

export const isDevEnv = (): boolean => process.env.NODE_ENV === 'development';

export const getValueForLocale = (value: string | undefined, locale: ELocales) => {
  const currentValue = jsonParseSafely(value);

  if (currentValue && isPlainObject(currentValue)) {
    return currentValue[locale] ?? currentValue[ELocales.RU] ?? '';
  }

  return currentValue;
};

export const setValueForLocale = (
  jsonString: string | undefined,
  locale: ELocales,
  value: string,
) => {
  const currentValue = jsonParseSafely(jsonString);

  if (currentValue && isPlainObject(currentValue)) {
    currentValue[locale] = value;
    return JSON.stringify(currentValue);
  }

  return JSON.stringify({ [locale]: value });
};

export const isJSONString = (JSONString: any) => {
  try {
    JSON.parse(JSONString);
  } catch (e) {
    return false;
  }
  return true;
};

export const arrayParse = (arr: any) => {
  return `[${arr
    .map((item: any) => {
      if (isArray(item)) {
        return arrayParse(item);
      }

      if (isPlainObject(item)) {
        return objectParse(item);
      }

      return `${typeof item === 'string' ? wrapSlashes(item) : item}`;
    })
    .join(', ')}]`;
};

export const objectParse: string = (obj: any) => {
  if (isPlainObject(obj)) {
    return `{${Object.entries(obj)
      .map(([key, value]) => {
        if (isArray(value)) {
          return `${key}: ${arrayParse(value)}`;
        }
        if (isPlainObject(value)) {
          return `${key}: ${objectParse(value)}`;
        }
        return `${key}: ${typeof value === 'string' ? wrapSlashes(value) : value}`;
      })
      .join(', ')}}`;
  }
  return '';
};

export const setLocalSelectedTool = (obj: any, reset: boolean = false) => {
  if (localStorage?.localSelectedTool && isJSONString(localStorage.localSelectedTool) && !reset) {
    const localSelectedTool = JSON.parse(localStorage.localSelectedTool);
    localStorage.setItem('localSelectedTool', JSON.stringify({ ...localSelectedTool, ...obj }));
  } else {
    localStorage.setItem('localSelectedTool', JSON.stringify(obj));
  }
};

export const getLocalSelectedTool = (field: string) => {
  if (localStorage?.localSelectedTool && isJSONString(localStorage.localSelectedTool)) {
    const localSelectedTool = JSON.parse(localStorage.localSelectedTool);
    return localSelectedTool[field];
  }
  return undefined;
};

export const isJSONStringRegex = (JSONString: any) => {
  return /^[\],:{}\s]*$/.test(
    JSONString.replace(/\\["\\\/bfnrtu]/g, '@')
      .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
      .replace(/(?:^|:|,)(?:\s*\[)+/g, ''),
  );
};
