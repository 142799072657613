import { ssoGetSettings, ssoGetSystemInfo, ssoTestConnection } from '@/requests/auth';
import { notification } from 'antd';
import { routes } from '@/utils/routes/path';
import { getSettingsAsync } from '@/models/sso/actions';

export default {
  namespace: 'sso',

  state: {
    error: {
      status: false,
      text: '',
    },
    test: {
      external: {
        success: null,
      },
      internal: {
        success: null,
      },
    },
    settings: {
      available: {},
      finish: false,
      url: '',
      realm: '',
      clientId: '',
      backendHost: '',
      backendClientId: '',
      backendSecret: '',
    },
    systemInfo: {
      fileEncoding: '',
      javaHome: '',
      javaRuntime: '',
      javaVendor: '',
      javaVersion: '',
      javaVm: '',
      javaVmVersion: '',
      osArchitecture: '',
      osName: '',
      osVersion: '',
      serverTime: '',
      uptime: '',
      uptimeMillis: '',
      userDir: '',
      userLocale: '',
      userName: '',
      userTimezone: '',
      version: '',
    }
  },

  reducers: {
    setSettings(state, action) {
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload,
        },
      };
    },
    setError(state, action) {
      return {
        ...state,
        error: {
          status: true,
          text: action.payload,
        },
      };
    },
    setSystemInfo(state, action) {
      return {
        ...state,
        systemInfo: action.payload,
      };
    },
  },

  effects: {
    *testUrlFrontendAsync({ payload }, { call, put }) {
      try {
        const response = yield call(ssoTestConnection, payload);
        if (response) {
          notification.success({
            description: payload?.successMsg,
            message: payload?.url,
          });
        } else {
          notification.error({
            description: payload?.unsuccessMsg,
            message: payload?.url,
          });
        }
      } catch (error) {
        notification.error({
          description: error.response.errors[0].message,
          message: 'Не удалось сохранить настройки!',
        });
      }
    },

    *getSettingsAsync(action, { call, put }) {
      try {
        const response = yield call(ssoGetSettings);
        yield put({ type: 'setSettings', payload: { ...response } });
      } catch (error) {
        notification.error({
          description: error.response.errors[0].message,
          message: 'Не удалось получить настройки!',
        });
      }
    },

    *getSystemInfoAsync({ payload }, { call, put }) {
      try {
        const systemInfo = yield call(ssoGetSystemInfo);
        if (!systemInfo) {
          notification.error({
            message: payload.message,
          });
        } else {
          yield put({ type: 'setSystemInfo', payload: systemInfo });
        }
      } catch(error) {
        notification.error({
          message: payload.message,
        });
      }
    },
  },

  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(({ pathname }) => {
        if (
          pathname === routes.tools.system.ssoSettings ||
          pathname === routes.tools.system.ssoSettingsNotFinish
        ) {
          dispatch(getSettingsAsync());
        }
      });
    },
  },
};
