import type { Saga } from '@redux-saga/types';
import type { AnyAction } from 'redux';
import type { SagaReturnType } from 'redux-saga/effects';
import type { IMessage } from '@/types/message';
import type { DsSettings, QueryDsTestConnectionArgs } from '@/types';

import {
  dsDashboard,
  grafanaFolders, grafanaGetSystemInfo,
  grafanaSetSettings,
  grafanaSettings,
  grafanaTestConnection,
  grafanaTestService,
} from '@/requests/grafana';
import {
  _setDashboards,
  _setFolders,
  grafanaSetDashboard,
  grafanaSetSettings as grafanaSetSettingsAction, grafanaSetSystemInfo,
} from '@/models/grafana/actions';
import { notification } from 'antd';
import type {TDsServiceInfoResponse} from "@/types/store/grafana";

export const effects = {
  *getFoldersAsync(action: AnyAction, saga: Saga) {
    try {
      yield saga.put({ type: 'setFoldersIsLoading', payload: true });
      const response: SagaReturnType<typeof grafanaFolders> = yield saga.call(grafanaFolders);
      yield saga.put(_setFolders(response));
      yield saga.put({ type: 'setFoldersIsLoading', payload: false });
    } catch (error) {
      notification.error({
        description: error.response.errors[0].message,
        message: 'Не удалось получить настройки!',
      });
    }
  },

  *getDashboardsAsync(action: AnyAction, saga: Saga) {
    try {
      const response: SagaReturnType<typeof dsDashboard> = yield saga.call(dsDashboard);
      yield saga.put(_setDashboards(response));
    } catch (error) {
      notification.error({
        description: error.response.errors[0].message,
        message: 'Не удалось получить настройки!',
      });
    }
  },

  *getSettingsAsync(action: AnyAction, saga: Saga) {
    try {
      const response: SagaReturnType<typeof grafanaSettings> = yield saga.call(grafanaSettings);
      yield saga.put(grafanaSetSettingsAction(response));
    } catch (error) {
      notification.error({
        description: error.response.errors[0].message,
        message: 'Не удалось получить настройки!',
      });
    }
  },

  *setSettingsAsync({ payload }: { payload: DsSettings }, saga: Saga) {
    try {
      yield saga.call(grafanaSetSettings, payload);
      notification.success({
        message: 'Успешно!',
        description: 'Настройки сохранены!',
      });
    } catch (error) {
      notification.error({
        message: 'Не удалось сохранить настройки!',
        description: error.response.errors[0].message,
      });
    }
  },

  *testConnectionAsync(
    { payload }: { payload: QueryDsTestConnectionArgs & IMessage },
    saga: Saga,
  ): Generator {
    try {
      const result = yield saga.call(grafanaTestConnection, payload);
      if (result) {
        notification.success({
          message: payload.success,
        });
      } else {
        notification.error({
          message: payload.unsuccess,
        });
      }
    } catch (error) {
      notification.error({
        message: payload.unsuccess,
        description: error.response?.errors[0].message || error.message,
      });
    }
  },

  *testServiceAsync({ payload }: any, { call }: any): Generator {
    try {
      const result = yield call(grafanaTestService, payload);
      if (result) {
        notification.success({
          message: payload.success,
        });
      } else {
        notification.error({
          message: payload.unsuccess,
        });
      }
    } catch (error) {
      notification.error({
        message: payload.unsuccess,
        description: error.response?.errors[0].message || error.message,
      });
    }
  },

  *getDashboardAsync({ payload }: AnyAction, saga: Saga) {
    try {
      const result: SagaReturnType<typeof dsDashboard> = yield saga.call(dsDashboard, payload);
      yield saga.put(grafanaSetDashboard(result));
    } catch (error) {
      notification.error({
        message: payload.unsuccess,
        description: error.response?.errors[0].message || error.message,
      });
    }
  },

  *grafanaGetSystemInfoAsync({ payload }: AnyAction, saga: Saga) {
    try {
      const systemInfo: TDsServiceInfoResponse = yield saga.call(grafanaGetSystemInfo);
      if (!systemInfo.success) {
        notification.error({
          message: payload.message,
        });
      } else {
        yield saga.put(grafanaSetSystemInfo(systemInfo.data));
      }
    } catch (error) {
      notification.error({
        message: payload.message,
      });
    }
  },
};
