import { notification } from 'antd';
import { hasuraState } from '@/models/hasura/selectors';
import {
  dbsSetSettings,
  dbsTestConnection,
  dbsSettings,
  dbsGetTables,
  dbsGetTable,
  dbsGetViewerTable,
  dbsGetMutations,
  dbsAddRow,
  dbsDeleteRow,
  dbsGetTableMeta,
  dbsGetMatrixTable,
} from '@/requests/hasura';
import {
  hasuraSetSettings,
  _hasuraSetTables,
  _hasuraSetTable,
  _hasuraSetViewerTable,
  _hasuraSetMutations,
  _hasuraSetMatrix, _hasuraClearViewerTable,
} from '@/models/hasura/actions';
import { DbTypes } from '@/types/enums';

function* setChildren({ field, nestingLevel, toolName, call, fields, key }) {
  const isObject = field.type.kind === DbTypes.OBJECT || field.type.ofType?.kind === DbTypes.OBJECT;
  const isAggregateField = field.type.ofType?.name?.endsWith('_aggregate');
  field.key = key;

  if (isObject && nestingLevel > 0 && !isAggregateField) {
    let tableName = field.type.description;
    tableName = tableName?.match(/[\w]*\.[\w]*/g);
    tableName = tableName?.[0]?.replace(/\./g, '_') ?? tableName;

    field.type.ofType = field.type.ofType ?? {};
    field.type.ofType.name = field.type.ofType.name ?? tableName;
    field.tableName = field.type.ofType.name ?? tableName;
    // field.type.ofType.name = tableName; // TODO пока не удалять

    let tableMetaChild = yield call(dbsGetTableMeta, field.type.ofType.name, {
      toolName,
    });
    tableMetaChild = JSON.parse(tableMetaChild.dbsRequire).data.__type;
    field.type.ofType.fields = tableMetaChild.fields;

    for (const child of field.type.ofType.fields) {
      yield setChildren({
        field: child,
        nestingLevel: nestingLevel - 1,
        toolName,
        call,
        fields: field.type.ofType.fields,
        key: `${key},${child.name}`,
      });
    }
  } else if (field.type.ofType?.kind === DbTypes.LIST && nestingLevel > 0) {
    const aggregateField = fields?.find(
      (currentField) => currentField.name === `${field.name}_aggregate`,
    );

    let tableName = aggregateField?.type?.description;
    /*tableName = tableName?.match(/[\w]*\.[\w]*!/g);*/ // TODO старый способ парсинга имени таблицы (не универсальный)
    tableName = tableName?.match(/"(\S*)"/g);
    tableName = tableName?.[0]?.split('"');
    tableName = tableName?.[1]?.replace(/\./g, '_') ?? tableName;

    let tableMetaChild = yield call(dbsGetTableMeta, tableName, {
      toolName,
    });
    tableMetaChild = JSON.parse(tableMetaChild.dbsRequire).data.__type;

    field.tableName = tableName;
    field.type.ofType = field.type.ofType ?? {};
    field.type.ofType.fields = tableMetaChild.fields;
    field.type.ofType.name = field.name;

    for (const child of field.type.ofType.fields) {
      yield setChildren({
        field: child,
        nestingLevel: nestingLevel - 1,
        toolName,
        call,
        fields: tableMetaChild.fields,
        key: `${key},${child.name}`,
      });
    }
  } else {
    return;
  }
}

export const effects = {
  *getSettingsAsync({ payload }, { call, put }) {
    try {
      const { dbsSettings: settings } = yield call(dbsSettings);
      yield put(hasuraSetSettings(settings));
    } catch (error) {
      notification.error({
        message: payload.unsuccess,
        description: error.response?.errors[0].message || error.message,
      });
    }
  },

  *setSettingsAsync({ payload }, { call, put }) {
    try {
      const { dbsSetSettings: settings } = yield call(dbsSetSettings, payload);
      yield put(hasuraSetSettings(settings));
      notification.success({
        message: payload.success,
      });
    } catch (error) {
      notification.error({
        message: payload.unsuccess,
        description: error.response?.errors[0].message || error.message,
      });
    }
  },

  *tesConnectionAsync({ payload }, { call }) {
    try {
      const { dbsTestConnection: connection } = yield call(dbsTestConnection, payload);
      if (!connection) {
        notification.warning({
          message: payload.unsuccess,
        });
      } else {
        notification.success({
          message: payload.success,
        });
      }
    } catch (error) {
      notification.error({
        message: payload.unsuccess,
        description: error.response?.errors[0].message || error.message,
      });
    }
  },

  *getTablesAsync({ payload }, { call, put }) {
    try {
      let { dbsRequire } = yield call(dbsGetTables, payload);
      dbsRequire = JSON.parse(dbsRequire);
      const {
        __schema: {
          queryType: { fields: tables },
        },
      } = dbsRequire.data;
      yield put(_hasuraSetTables(tables));
    } catch (error) {
      notification.error({
        message: error.message,
      });
    }
  },

  *getMutationsAsync({ payload }, { call, put }) {
    try {
      let { dbsRequire } = yield call(dbsGetMutations, payload);
      dbsRequire = JSON.parse(dbsRequire);
      const {
        __schema: {
          mutationType: { fields: mutations },
        },
      } = dbsRequire.data;
      yield put(_hasuraSetMutations(mutations));
    } catch (error) {
      console.log(error.message);
    }
  },

  // TODO мертвый код
  *getTableAsync({ payload }, { call, put }) {
    try {
      let { dbsRequire } = yield call(dbsGetTable, payload.tableName, {
        toolName: payload.toolName,
      });
      dbsRequire = JSON.parse(dbsRequire);
      const { __type: tableInfo } = dbsRequire.data;
      yield put(_hasuraSetTable(tableInfo));
    } catch (error) {
      notification.error({
        message: error.message,
      });
    }
  },

  *getTableMetaAsync({ payload }, { call, put }) {
    const nestingLevel = payload.nestingLevel ?? 0;

    try {
      let { dbsRequire } = yield call(dbsGetTableMeta, payload.tableName, {
        toolName: payload.toolName,
      });
      dbsRequire = JSON.parse(dbsRequire);
      const { __type: tableMeta } = dbsRequire.data;

      if (tableMeta) {
        for (const field of tableMeta.fields) {
          yield setChildren({
            field,
            nestingLevel,
            toolName: payload.toolName,
            call,
            fields: tableMeta.fields,
            key: field.name,
          });
        }
        yield put(_hasuraSetTable({ tableName: '', description: null, fields: [] }));
        yield put(_hasuraSetTable(tableMeta));
      } else {
        yield put(_hasuraSetTable({ tableName: '', description: null, fields: [] }));
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: error.message,
      });
    }
  },

  *getViewerTableAsync({ payload }, { call, put }) {
    try {
      let { dbsRequire } = yield call(dbsGetViewerTable, payload.query, {
        toolName: payload.toolName,
      });
      dbsRequire = JSON.parse(dbsRequire);

      if (dbsRequire.errors) {
        notification.error({
          message: dbsRequire.errors[0].message,
        });
      } else {
        const fields = dbsRequire.data[payload.table];
        yield put(_hasuraSetViewerTable(fields));
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: error.message,
      });
    }
  },

  *clearViewerTableAsync({ payload }, { put }) {
    try {
        yield put(_hasuraClearViewerTable());
    } catch (error) {
      console.log(error);
      notification.error({
        message: error.message,
      });
    }
  },

  *getMatrixAsync({ payload }, { call, put }) {
    try {
      const { dbsRequire } = yield call(dbsGetMatrixTable, payload.query, {
        toolName: payload.toolName,
      });
      const data = JSON.parse(dbsRequire).data;
      yield put(_hasuraSetMatrix(data));
    } catch (error) {
      notification.error({
        message: error.message,
      });
      console.log(error);
    }
  },

  *addRowAsync({ payload }, { call, put, select }) {
    try {
      let { dbsRequire } = yield call(dbsAddRow, payload);
      dbsRequire = JSON.parse(dbsRequire);
      if (dbsRequire?.errors) {
        notification.warning({
          message: dbsRequire.errors[0].message,
        });
      } else {
        const row = dbsRequire.data[`insert_${payload.mutation}`]?.returning;
        let {
          viewerTable: { fields },
        } = yield select(hasuraState);
        yield put(_hasuraSetViewerTable([...fields, ...row]));
        notification.success({
          message: payload.messages.success,
        });
      }
    } catch (error) {
      notification.error({
        message: error.message,
      });
    }
  },

  *deleteRowAsync({ payload }, { call }) {
    try {
      yield call(dbsDeleteRow, {
        mutation: payload.mutation,
        primaryKey: payload.primaryKey,
        returning: payload.returning,
      });
      payload.deleteRow();
      notification.success({
        message: payload.messages.success,
      });
    } catch (error) {
      notification.error({
        message: error.message,
      });
    }
  },
};
