import { _navigationAsync, _setNavigationReducer } from '@/models/navigation/actions';
import { state } from '@/models/navigation/state';
import {
  navigation,
  navigationCreate,
  navigationDelete,
  navigationUpdate,
} from '@/requests/navigation';
import { normalizePath } from '@/third/utils';
import { routes } from '@/utils/routes/path';
import { notification } from 'antd';
import _ from 'lodash';
import { getLocale } from 'umi';

export default {
  namespace: 'navigation',

  state,

  reducers: {
    setNavigationReducer(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },

  effects: {
    *navigationAsync(action, { call, put }) {
      try {
        let result = yield call(navigation, { all: true, locale: 'ru-RU', ...action?.payload });
        yield put(_setNavigationReducer({ data: JSON.parse(result || '[]') }));
      } catch (error) {
        notification.error({
          description: error.response.errors[0].message,
          message: 'Не удалось получить настройки!',
        });
      }
    },

    *navigationCreateAsync(action, { call, put }) {
      try {
        let result = yield call(navigationCreate, action.payload);
        //yield put(_setNavigationReducer({ data: JSON.parse(result || '[]') }));
        notification.success({
          message: action.payload.name,
          description: result ? action.payload.messages.added : action.payload.messages.notAdded,
        });
        return JSON.parse(result);
      } catch (error) {
        notification.error({
          description: error.response.errors[0].message,
          message: action.payload.messages.error,
        });
      }
    },

    *navigationUpdateAsync(action, { call, put }) {
      try {
        let result = '{}';
        let payload = Array.isArray(action.payload.data)
          ? action.payload.data
          : [action.payload.data];
        let newPayload = payload.map((item) => _.omit(item, ['children', 'key']));
        result = yield call(navigationUpdate, {
          payload: newPayload,
          locale: action.payload.locale,
        });
        result = JSON.parse(result);
        notification.success({
          message: action.payload.data.name,
          description: result
            ? action.payload.messages.changed
            : action.payload.messages.notChanged,
        });
        return result;
      } catch (error) {
        notification.error({
          description: error.response.errors[0].message,
          message: action.payload.messages.error,
        });
      }
    },

    *navigationDeleteAsync(action, { call, put }) {
      try {
        let result = yield call(navigationDelete, action.payload);
        notification.success({
          message: action.payload.name,
          description: result
            ? action.payload.messages.removed
            : action.payload.messages.notRemoved,
        });
        return result;
      } catch (error) {
        notification.error({
          description: error.response.errors[0].message,
          message: action.payload.messages.error,
        });
      }
    },
  },

  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(({ pathname }) => {
        if (pathname === normalizePath(routes.tools.admin.navigationSettings)) {
          const locale = getLocale();
          dispatch(_navigationAsync({ locale }));
        }
      });
    },
  },
};
