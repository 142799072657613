import { getServerTime, getVersion } from '@/requests/app';
import { notification } from 'antd';
import front from '@/version.js';
import { state } from './state';
import { navigation } from '@/requests/navigation';
import { _setAppNavigationReducer } from '@/models/app/actions';

export default {
  namespace: 'app',

  state,

  reducers: {
    setSettings(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },

    setServerTime(state, action) {
      return {
        ...state,
        server: { ...state.server, time: action.payload },
      };
    },

    setAppNavigationReducer(state, action) {
      return {
        ...state,
        navigation: action.payload,
      };
    },
  },

  effects: {
    *getVersionAsync(action, { call, put }) {
      try {
        const response = yield call(getVersion);
        yield put({ type: 'setSettings', payload: { versions: { front, back: response } } });
      } catch (error) {
        notification.error({
          description: error.response.errors[0].message,
          message: 'Не удалось получить версию!',
        });
      }
    },

    *getServerTimeAsync(action, { call, put }) {
      try {
        const response = yield call(getServerTime);
        yield put({ type: 'setServerTime', payload: response });
      } catch (error) {
        notification.error({
          description: error.response.errors[0].message,
          message: 'Не удалось ServerTime!',
        });
      }
    },

    *navigationAsync(action, { call, put }) {
      try {
        let result = yield call(navigation, { all: false, ...action?.payload });

        yield put(_setAppNavigationReducer(JSON.parse(result || '[]')));
      } catch (error) {
        notification.error({
          message: action?.payload?.unsuccess,
          description: error.response?.errors[0].message || error.message,
        });
      }
    },
  },

  subscriptions: {},
};
