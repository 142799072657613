import { state } from '@/models/systemDb/state';
import { reducers } from '@/models/systemDb/reducers';
import { effects } from '@/models/systemDb/effects';
import { subscriptions } from '@/models/systemDb/subscriptions';

export default {
  namespace: 'systemDb',
  state,
  reducers,
  effects,
  subscriptions,
};
