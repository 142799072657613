export const newToolState = {
  navigationId: '',
  enabled: '',
  moduleName: '',
  name: '',
  label: '',
  description: '',
  settings: '',
  single: '',
};
export const state = {
  tools: [],
  toolModules: [],
  tool: {
    access: [],
  },
  isChange: false,
  saveTool: {},
  newTool: newToolState,
  selectTool: '',
  usersAndGroups: {
    usersData: [],
    groupsData: [],
    accessDefaultValue: [],
    noRoles: false,
  },
};
