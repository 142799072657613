import { gql } from 'graphql-request';
import { client } from '@/requests/client';

export default function getSettings(props) {
  const query = gql`
    query dmsSettings {
      dmsSettings{
        enabled
        endpoint
        username
      }
    }
  `;

  return new Promise((resolve, reject) => {
    client()()
    .request(query, props)
    .then((data) => resolve(data?.dmsSettings))
    .catch((error) => reject(error));
  });
}
