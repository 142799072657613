import type { DsDashboard, DsFolder, DsSettings } from '@/types';
import type {
  IGrafanaSetFoldersAction,
  IGrafanaSetSettingsAction,
  IGrafanaTestConnectionAction,
  IGrafanaSettingsState
} from '@/types/store/grafana';

export const grafanaGetSettingsAsync = () => ({ type: 'getSettingsAsync' });

export const grafanaSetSettings = (payload: DsSettings): IGrafanaSetSettingsAction => ({
  type: 'setSettings',
  payload,
});

export const grafanaSetSettingsAsync = (payload: IGrafanaSettingsState) => ({
  type: 'grafana/setSettingsAsync',
  payload,
});

export const grafanaTestConnectionAsync = (payload: { url: string }): IGrafanaTestConnectionAction => ({
  type: 'grafana/testConnectionAsync',
  payload,
});

export const grafanaSetDashboard = (payload: DsDashboard) => ({ type: 'setDashboard', payload });

export const grafanaSetSystemInfo = (payload: any) => ({ type: 'setGrafanaSystemInfo', payload });

export const _setFolders = (payload: DsFolder[]): IGrafanaSetFoldersAction => ({
  type: 'setFolders',
  payload,
});

// TODO any
export const setFolders = (payload: any) => ({ type: 'grafana/setFolders', payload });

export const _setDashboards = (payload: DsDashboard) => ({ type: 'setDashboards', payload });
// TODO any
export const setDashboards = (payload: any) => ({ type: 'grafana/setDashboards', payload });

export const grafanaGetSystemInfoAsync = (payload: any) => ({ type: 'grafana/grafanaGetSystemInfoAsync', payload });
