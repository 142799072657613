import { notification } from 'antd';
import { userGetProfile, userSetProfile, userSetPassword } from '@/requests/user';
import { setProfile } from '@/models/user/actions';
import { tools } from '@/requests/tools';
import { _setToolsReducer } from '@/models/tools/actions';

export const effects = {
  *getProfile({ payload }, { call, put }) {
    try {
      const { me } = yield call(userGetProfile);
      //const resTools = yield call(tools, { all: false });
      //yield put(setProfile({ ...me, tools: resTools }));
      yield put(setProfile({ ...me }));
    } catch (error) {
      notification.error({
        message: payload.unsuccess,
        description: error.response?.errors[0].message || error.message,
      });
    }
  },

  *setProfileAsync({ payload }, { call }) {
    try {
      yield call(userSetProfile, payload);
      notification.success({
        message: payload.success,
      });
    } catch (error) {
      notification.error({
        message: payload.unsuccess,
        description: error.response?.errors[0].message || error.message,
      });
    }
  },

  *setPasswordAsync({ payload: { password, ...messages } }, { call }) {
    try {
      yield call(userSetPassword, { password });
      notification.success({
        message: messages.success,
      });
    } catch (error) {
      notification.error({
        message: messages.unsuccess,
        description: error.response?.errors[0].message || error.message,
      });
    }
  },

  *toolsAsync({ payload }, { call, put }) {
    try {
      let result = yield call(tools, { all: false, ...(payload || {}) });
      yield put(_setToolsReducer(result));
    } catch (error) {
      notification.error({
        message: payload.unsuccess,
        description: error.response?.errors[0].message || error.message,
      });
    }
  },
};
