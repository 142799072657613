import type { IMessage } from '@/types/message';

export const updateGlobalSettingsAction = (payload: string, messages: IMessage) => ({
  type: 'global/updateGlobalSettingsEffect',
  payload,
  messages,
});

export const getGlobalSettingsAction = (messages: IMessage) => ({
  type: 'global/getGlobalSettingsEffect',
  messages,
});

export const setGlobalSettingsAction = (payload: string) => ({
  type: 'globalSettingsUpdateReducer',
  payload,
});

export const _setGlobalSettingsAction = (payload: string) => ({
  type: 'global/globalSettingsUpdateReducer',
  payload,
});
