import { gql } from 'graphql-request';
import { client } from '@/requests/client';

export default function folderDelete(props) {
  const query = gql`
    mutation dmsFolderDelete(
      $toolName: String!
      $path: String!,
    ) {
      dmsFolderDelete(
        toolName: $toolName
        path: $path
      ){
        success
        message
      }
    }
  `;

  return new Promise((resolve, reject) => {
    client()()
      .request(query, props)
      .then((data) => resolve(data?.dmsFolderDelete))
      .catch((error) => reject(error));
  });
}
