import { state } from '@/models/hasura/state';
import { reducers } from '@/models/hasura/reducers';
import { effects } from '@/models/hasura/effects';
import { subscriptions } from '@/models/hasura/subscriptions';

export default {
  namespace: 'hasura',
  state,
  reducers,
  effects,
  subscriptions,
};
