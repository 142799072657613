import { ResponseType, ClientType } from '@/types/client';
import axios from 'axios';
import { GraphQLClient } from 'graphql-request';

export const GRAPHQL = 'GRAPHQL';
export const REST_POST = 'REST_POST';
export const REST_GET = 'REST_GET';
export const REST_GET_FILE = 'REST_GET_FILE';
export const REST_GET_URI = 'REST_GET_URI';
export const REST_POST_URI = 'REST_POST_URI';

let env = { WS_URL: '', APP_API_URL: '', APP_REST_REF: '', APP_GRAPHQL_REF: '' };

try {
  env = {
    ...env,
    ...(ENV || {}),
  };
} catch (e) {
  console.log(e);
}

const url = env.APP_API_URL || window.location.origin;

export function setHeaders() {
  let newHeader = {};
  newHeader = { ...newHeader, 'Access-Control-Allow-Origin': '*' };
  newHeader = { ...newHeader, 'Access-Control-Allow-Methods': '*' };
  newHeader = {
    ...newHeader,
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With,Authorization,  Content-Type, Accept',
  };
  const token = localStorage.getItem('token');
  if (token) {
    newHeader = { ...newHeader, Authorization: `Bearer ${localStorage.getItem('token')}` };
  }
  return newHeader;
}

export const client = (clientType: ClientType = GRAPHQL): ResponseType => {
  try {
    switch (clientType) {
      case REST_POST:
        return (rUrl, rVariables, rOptions = {}) => {
          return axios.post(url + env.APP_REST_REF + rUrl, rVariables, {
            headers: setHeaders(),
            ...rOptions,
          });
        };
      case REST_GET:
        return (rUrl, rVariables, rOptions = {}) => {
          return axios.get(`${url + env.APP_REST_REF + rUrl}?${rVariables}`, {
            headers: setHeaders(),
            ...rOptions,
          });
        };
      case REST_GET_FILE:
        return (rUrl?, rVariables?, rOptions = {}) => {
          const newUrl = `${url + env.APP_REST_REF + rUrl}/?${rVariables}`;
          const link = document.createElement('a');
          link.href = newUrl;
          document.body.appendChild(link);
          link.click();
          link.remove();
        };
      case REST_GET_URI:
        return (rUrl, rVariables, rOptions = {}) => {
          return axios.get(`${rUrl}${rVariables ? '?' + rVariables : ''}`, {
            headers: setHeaders(),
            ...rOptions,
          });
        };
      case REST_POST_URI:
        return (rUrl, rVariables, rOptions = {}) => {
          return axios.post(rUrl, rVariables, {
            headers: setHeaders(),
            ...rOptions,
          });
        };
      case GRAPHQL:
      default:
        return (rUrl = url + env.APP_GRAPHQL_REF, rOptions = { headers: setHeaders() }) =>
          new GraphQLClient(rUrl, { ...rOptions });
    }
  } catch (e) {
    console.log('error', e);
  }
};
