import type { IFileManagerSettingsState } from '@/types/store/fileManager';
import type { TFolderTreeNode } from '@/components/Tools/FileManager/types';

export const state: IFileManagerSettingsState = {
  folderTree: {} as TFolderTreeNode,
  selectedFolder: {
    name: '',
    date: '',
    path: '',
    children: [],
  },
  error: null,
  uploadedFileInfo: null,
};
