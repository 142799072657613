export const state = {
  id: '',
  username: '',
  firstName: '',
  lastName: '',
  email: '',
  groups: [],
  roles: [],
  tools: [],
};
