import { client } from './client';
import type { Response } from '@/types';
import { gql } from 'graphql-request';

export function getGlobalSettings() {
  const query = gql`
    query {
      globalSettings {
        data
        message
        success
      }
    }
  `;
  return new Promise<Response>((resolve, reject) => {
    client()()
      .request(query)
      .then((data: { globalSettings: Response | PromiseLike<Response> }) => resolve(data.globalSettings))
      .catch((error: Error) => reject(error));
  });
}

export function globalSettingsUpdateAll(jsonString: string) {
  const variables = { jsonString };
  const query = gql`
    mutation ($jsonString: String!) {
      globalSettingsUpdateAll(jsonString: $jsonString) {
        data
        message
        success
      }
    }
  `;
  return new Promise<Response>((resolve, reject) => {
    client()()
      .request(query, variables)
      .then((data: { globalSettingsUpdateAll: Response | PromiseLike<Response> }) => resolve(data.globalSettingsUpdateAll))
      .catch((error: Error) => reject(error));
  });
}
