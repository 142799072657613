import { client } from './client';
import type {
  DsDashboard,
  DsFolder,
  DsSettings,
  MutationDsSetSettingsArgs,
  QueryDsTestConnectionArgs,
} from '@/types';
import type { GraphQLClient } from 'graphql-request';
import { gql } from 'graphql-request';

const gqClient = client()() as GraphQLClient;

export function grafanaFolders() {
  const query = gql`
    query {
      dsFolders {
        id
        uid
        title
      }
    }
  `;
  return new Promise<DsFolder[]>((resolve, reject) => {
    gqClient
      .request(query)!
      .then((data) => resolve(data?.dsFolders))
      .catch((error) => {
        reject(error);
      });
  });
}

export function grafanaSettings() {
  const query = gql`
    query {
      dsSettings {
        internal
        external
        enabled
        __typename
      }
    }
  `;
  return new Promise<DsSettings>((resolve, reject) => {
    client()()
      .request(query)
      .then((data) => resolve(data.dsSettings))
      .catch((error) => {
        reject(error);
      });
  });
}

export function grafanaSetSettings(variables: DsSettings) {
  const query = gql`
    mutation ($internal: String, $external: String, $enabled: Boolean, $apiKey: String) {
      dsSetSettings(internal: $internal, external: $external, enabled: $enabled, apiKey: $apiKey) {
        internal
        external
        enabled
      }
    }
  `;
  return new Promise<MutationDsSetSettingsArgs>((resolve, reject) => {
    client()()
      .request(query, variables)
      .then((data) => resolve(data?.dsSetSettings))
      .catch((error) => {
        reject(error);
      });
  });
}

export function dsDashboard(variables: DsDashboard) {
  const query = gql`
    query ($uid: String!) {
      dsDashboard(uid: $uid) {
        id
        uid
        title
        url
        panels {
          id
          title
          gridPos
          scopedVars
        }
        folder {
          id
          uid
          title
        }
      }
    }
  `;
  return new Promise<DsDashboard>((resolve, reject) => {
    gqClient
      .request(query, variables)
      .then((data) => resolve(data?.dsDashboard))
      .catch((error) => {
        reject(error);
      });
  });
}

export function dsDashboards(variables: DsDashboard[]) {
  const query = gql`
    query ($folderId: ID) {
      dsDashboards(folderId: $folderId) {
        id
        uid
        title
        url
        panels {
          id
          title
          gridPos
          scopedVars
        }
      }
    }
  `;
  return new Promise<DsDashboard[]>((resolve, reject) => {
    gqClient
      .request(query, variables)
      .then((data) => resolve(data?.dsDashboards))
      .catch((error) => {
        reject(error);
      });
  });
}

export function grafanaTestConnection(variables: QueryDsTestConnectionArgs) {
  const query = gql`
    query ($url: String!) {
      dsTestConnection(url: $url)
    }
  `;
  return new Promise<boolean>((resolve, reject) => {
    client()()
      .request(query, variables)
      .then((data) => resolve(data?.dsTestConnection))
      .catch((error) => reject(error));
  });
}

export function grafanaTestService() {
  const query = gql`
    query {
      dsTestService
    }
  `;
  return new Promise<boolean>((resolve, reject) => {
    client()()
      .request(query)
      .then((data) => resolve(data?.dsTestService))
      .catch((error) => reject(error));
  });
}

export function grafanaGetSystemInfo() {
  const query = gql`
    query {
      dsServiceInfo {
        data {
          database
        }
        message
        success
      }
    }
  `;

  return new Promise((resolve, reject) => {
    client()()
      .request(query)
      .then((data: { [x: string]: unknown; }) => resolve(data?.['dsServiceInfo']))
      .catch((error: any) => reject(error));
  });
}
