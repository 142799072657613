export const reducers = {
  setUser(state, action) {
    return {
      ...state,
      user: {
        ...state.user,
        ...action.payload
      },
    };
  },
};
