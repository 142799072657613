import { state } from '@/models/auth/state';
import { reducers } from '@/models/auth/reducers';
//import { effects } from '@/models/auth/effects';
//import { subscriptions } from '@/models/auth/subscriptions';

export default {
  namespace: 'auth',
  state,
  reducers,
  //effects,
  //subscriptions,
};
