export const routes = {
  tools: {
    system: {
      grafanaSettings: '/system/Ds-int-settings',
      hasuraSettings: '/system/DBS-int-settings',
      systemDbSettings: '/system/db-settings',
      ssoSettings: '/system/SSO-settings',
      ssoSettingsNotFinish: '/install/SSO-settings',
    },
    admin: {
      navigationSettings: '/admin/navigation-settings',
      toolsSettings: '/admin/tools-settings',
    },
  },
  root: '/',
  about: '/about',
  userProfile: '/user-profile',
};
