import { IGlobalStateSettings } from '@/models/global/state';

export const reducers = {
  globalSettingsUpdateReducer(state: IGlobalStateSettings, action: { payload: string }) {
    return {
      ...state,
      ...JSON.parse(action.payload),
    };
  },
};
