import { client } from './client';
import { gql } from 'graphql-request';

export async function systemDatabaseGetSettings() {
  const query = gql`
    query {
      systemDatabaseGetSettings {
        driver
        host
        port
        database
        username
      }
    }
  `;
  return await client()().request(query);
}

export async function systemDatabaseCreate() {
  const query = gql`
    mutation {
      systemDatabaseCreate
    }
  `;
  return await client()().request(query);
}

export async function systemDatabaseDrop() {
  const query = gql`
    mutation {
      systemDatabaseDrop
    }
  `;
  return await client()().request(query);
}

export async function systemDatabaseReset() {
  const query = gql`
    mutation {
      systemDatabaseReset
    }
  `;
  return await client()().request(query);
}

export async function systemDatabaseCheckConnection(props) {
  const variables = { ...props };
  const query = gql`
    query ($host: String!, $username: String!, $password: String!) {
      systemDatabaseCheckConnection(host: $host, username: $username, password: $password)
    }
  `;
  return await client()().request(query, variables);
}

export async function systemDatabaseSync() {
  const query = gql`
    mutation systemDatabaseSync {
      systemDatabaseSync
    }
  `;
  return await client()().request(query);
}

export async function systemDatabaseBackup() {
  const query = gql`
    query {
      systemDatabaseBackup
    }
  `;
  return await client()().request(query);
}

export async function systemDatabaseRestore(props) {
  const variables = { ...props };
  const query = gql`
    mutation ($utf8String: String!) {
      systemDatabaseRestore(utf8String: $utf8String)
    }
  `;
  return await client()().request(query, variables);
}
