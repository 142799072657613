import { effects } from '@/models/fileManager/effects';
import { reducers } from '@/models/fileManager/reducers';
import { state } from '@/models/fileManager/state';

export default {
  namespace: 'fileManager',
  state,
  effects,
  reducers,
};
