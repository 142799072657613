import { gql } from 'graphql-request';
import { client } from '@/requests/client';

export default function setSettings(props) {
  const query = gql`
    mutation dmsSetSettings(
      $enabled: Boolean,
      $endpoint: String,
      $username: String,
      $password: String,
    ) {
      dmsSetSettings(
        enabled: $enabled
        endpoint: $endpoint
        username: $username
        password: $password
      ){
        enabled
        endpoint
        username
      }
    }
  `;

  return new Promise((resolve, reject) => {
    client()()
    .request(query, props)
    .then((data) => resolve(data?.dmsSetSettings))
    .catch((error) => reject(error));
  });
}
