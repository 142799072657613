import { gql } from 'graphql-request';
import { client } from '@/requests/client';

export default function testService(props) {
  const query = gql`
    query dmsTestService{
      dmsTestService
    }
  `;

  return new Promise((resolve, reject) => {
    client()()
      .request(query, props)
      .then((data) => resolve(data?.dmsTestService))
      .catch((error) => reject(error));
  });
}
