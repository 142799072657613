const Settings = {
  primaryColor: '#1890ff',
  contentWidth: 'Fluid',
  colorWeak: false,
  pwa: false,
  // logo: '/kazprom.svg',
  title: 'MESone',
  iconfontUrl: '',
  siderWidth: 320,
};
export default Settings;
