export const hasuraGetSettingsAsync = (payload) => ({ type: 'hasura/getSettingsAsync', payload });
export const hasuraTestConnection = (payload) => ({ type: 'hasura/tesConnectionAsync', payload });
export const hasuraSetSettingsAsync = (payload) => ({ type: 'hasura/setSettingsAsync', payload });
export const hasuraSetSettings = (payload) => ({ type: 'setSettings', payload });
export const hasuraGetTables = (payload) => ({ type: 'hasura/getTablesAsync', payload });
export const _hasuraSetTables = (payload) => ({ type: 'setTables', payload });
export const hasuraGetTable = (payload) => ({ type: 'hasura/getTableAsync', payload });
export const _hasuraSetTable = (payload) => ({ type: 'setTable', payload });
export const hasuraGetTableMeta = (payload) => ({ type: 'hasura/getTableMetaAsync', payload });
export const hasuraSetTable = (payload) => ({ type: 'hasura/setTable', payload });
export const hasuraGetViewerTable = (payload) => ({ type: 'hasura/getViewerTableAsync', payload });
export const hasuraClearViewerTable = () => ({ type: 'hasura/clearViewerTableAsync' });
export const hasuraGetMatrixAsync = (payload) => ({
  type: 'hasura/getMatrixAsync',
  payload,
});
export const _hasuraSetViewerTable = (payload) => ({ type: 'setViewerTable', payload });
export const _hasuraClearViewerTable = () => ({ type: 'clearViewerTable' });
export const _hasuraSetMatrix = (payload) => ({ type: 'setMatrix', payload });
export const hasuraGetMutations = (payload) => ({ type: 'hasura/getMutationsAsync', payload });
export const _hasuraSetMutations = (payload) => ({ type: 'setMutations', payload });
export const hasuraAddRow = (payload) => ({ type: 'hasura/addRowAsync', payload });
export const hasuraDeleteRow = (payload) => ({ type: 'hasura/deleteRowAsync', payload });
