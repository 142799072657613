import { client } from './client';
import { gql } from 'graphql-request';
import { IToolSettingsState } from '@/types/store/tool';
import { Maybe } from '@/types';
import { ELocales } from '@/types/enums';

export function tool(variables: { name: string; locale: ELocales }): Promise<IToolSettingsState> {
  const { name, locale = ELocales.RU } = variables;
  const query = gql`
    query ($name: String, $locale: String!) {
      tool(name: $name, locale: $locale) {
        id
        name
        moduleName
        label
        description
        enabled
        navigationId
        single
        settings {
          key
          value
        }
        settingsJson
      }
    }
  `;
  return new Promise((resolve, reject) => {
    client()()
      .request(query, { name, locale })
      .then((data: { tool: IToolSettingsState }) => resolve(data?.tool))
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function tools(variables: {
  all: boolean;
  locale: ELocales;
}): Promise<IToolSettingsState[]> {
  const query = gql`
    query ($all: Boolean, $locale: String) {
      tools(all: $all, locale: $locale) {
        id
        name
        moduleName
        label
        description
        enabled
        navigationId
        single
        settings {
          key
          value
        }
        settingsJson
      }
    }
  `;
  return new Promise((resolve, reject) => {
    client()()
      .request(query, variables)
      .then((data: { tools: IToolSettingsState[] }) => resolve(data?.tools))
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function toolCheckName(variables: { name: string }): Promise<boolean> {
  const query = gql`
    query ($name: String!) {
      toolCheckName(name: $name)
    }
  `;
  return new Promise((resolve, reject) => {
    client()()
      .request(query, variables)
      .then((data: { toolCheckName: boolean }) => resolve(data?.toolCheckName))
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function toolCreate(variables: {
  name: string;
  moduleName: string;
  enabled: boolean;
}): Promise<IToolSettingsState> {
  const query = gql`
    mutation (
      $name: String!
      $moduleName: String!
      $label: String
      $description: String
      $enabled: Boolean
      $navigationId: ID
      $single: Boolean
    ) {
      toolCreate(
        name: $name
        moduleName: $moduleName
        label: $label
        description: $description
        enabled: $enabled
        navigationId: $navigationId
        single: $single
      ) {
        name
        moduleName
        label
        description
        enabled
        navigationId
        single
        settings {
          key
          value
        }
        settingsJson
      }
    }
  `;
  return new Promise((resolve, reject) => {
    client()()
      .request(query, variables)
      .then((data: { toolCreate: IToolSettingsState }) => resolve(data?.toolCreate))
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function toolUpdate(variables: {
  name: string;
  settingsJson: string;
  settingKeyList: Maybe<{ key: string; value: string }[]>;
  locale: ELocales;
}): Promise<IToolSettingsState> {
  const query = gql`
    mutation (
      $id: String
      $name: String!
      $moduleName: String
      $label: String
      $description: String
      $enabled: Boolean
      $navigationId: ID
      $settingsJson: String
      $locale: String!
      $access: [String]
    ) {
      toolUpdate(
        id: $id
        name: $name
        moduleName: $moduleName
        label: $label
        description: $description
        enabled: $enabled
        navigationId: $navigationId
        settingsJson: $settingsJson
        locale: $locale
        access: $access
      ) {
        name
        moduleName
        label
        description
        enabled
        navigationId
        single
        settings {
          key
          value
        }
        settingsJson
      }
    }
  `;
  return new Promise((resolve, reject) => {
    client()()
      .request(query, variables)
      .then((data: { toolUpdate: IToolSettingsState }) => resolve(data?.toolUpdate))
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function toolDelete(variables: IToolSettingsState): Promise<boolean> {
  const query = gql`
    mutation ($name: String!) {
      toolDelete(name: $name)
    }
  `;
  return new Promise((resolve, reject) => {
    client()()
      .request(query, variables)
      .then((data: { toolDelete: boolean }) => resolve(data?.toolDelete))
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function toolAddUser(variables: { userId: string; toolName: string }): Promise<boolean> {
  const query = gql`
    mutation ($userId: String!, $toolName: String!) {
      toolAddUser(userId: $userId, toolName: $toolName)
    }
  `;
  return new Promise((resolve, reject) => {
    client()()
      .request(query, variables)
      .then((data: { toolAddUser: boolean }) => resolve(data?.toolAddUser))
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function toolRemoveUser(variables: { userId: string; toolName: string }): Promise<boolean> {
  const query = gql`
    mutation ($userId: String!, $toolName: String!) {
      toolRemoveUser(userId: $userId, toolName: $toolName)
    }
  `;
  return new Promise((resolve, reject) => {
    client()()
      .request(query, variables)
      .then((data: { toolRemoveUser: boolean }) => resolve(data?.toolRemoveUser))
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function toolAddGroup(variables: { groupName: string; toolName: string }): Promise<boolean> {
  const query = gql`
    mutation ($groupName: String!, $toolName: String!) {
      toolAddGroup(groupName: $groupName, toolName: $toolName)
    }
  `;
  return new Promise((resolve, reject) => {
    client()()
      .request(query, variables)
      .then((data: { toolAddGroup: boolean }) => resolve(data?.toolAddGroup))
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export function toolRemoveGroup(variables: {
  groupName: string;
  toolName: string;
}): Promise<boolean> {
  const query = gql`
    mutation ($groupName: String!, $toolName: String!) {
      toolRemoveGroup(groupName: $groupName, toolName: $toolName)
    }
  `;
  return new Promise((resolve, reject) => {
    client()()
      .request(query, variables)
      .then((data: { toolRemoveGroup: boolean }) => resolve(data?.toolRemoveGroup))
      .catch((error: Error) => {
        reject(error);
      });
  });
}
