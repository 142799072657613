import { INavigationSettingsState } from '@/types/store/navigation';
import { ELocales } from '@/types/enums';
import {
  TNavigationCreateAsync,
  TNavigationDeleteAsync,
  TNavigationUpdateAsync,
} from '@/models/navigation/types';

export const _navigationAsync = (payload: { all: boolean; locale: ELocales }) => ({
  type: 'navigationAsync',
  payload,
});

export const navigationAsync = (payload: { all: boolean; locale: ELocales }) => ({
  type: 'navigation/navigationAsync',
  payload,
});

export const _setNavigationReducer = (payload: { data: INavigationSettingsState[] }) => ({
  type: 'setNavigationReducer',
  payload,
});

export const setNavigationReducer = (payload: { data: INavigationSettingsState[] }) => ({
  type: 'navigation/setNavigationReducer',
  payload,
});

export const navigationCreateAsync: TNavigationCreateAsync = (payload) => ({
  type: 'navigation/navigationCreateAsync',
  payload,
});

export const navigationUpdateAsync: TNavigationUpdateAsync = (payload) => ({
  type: 'navigation/navigationUpdateAsync',
  payload,
});

export const navigationDeleteAsync: TNavigationDeleteAsync = (payload) => ({
  type: 'navigation/navigationDeleteAsync',
  payload,
});
