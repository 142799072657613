import { client } from './client';
import { gql } from 'graphql-request';

export function loginRequest({ login, password, domain_id = null, remember = false }) {
  domain_id = domain_id !== null ? Number(domain_id) : domain_id;
  let variables = { login, password, domain_id, remember };
  const query = gql`
    query ($login: String!, $password: String!, $domain_id: Int, $remember: Boolean) {
      authLogin(login: $login, password: $password, domain_id: $domain_id, remember: $remember) {
        id
        first_name
        last_name
        second_name
        login
        token
        avatar
        domain_id
        structure_enterprise_id
      }
    }
  `;
  return new Promise((resolve, reject) => {
    client()()
      .request(query, variables)
      .then((data) => resolve(data?.['authLogin']))
      .catch((error) => reject(error));
  });
}

export function logoutRequest(props = {}) {
  let variables = { ...props };
  const query = gql`
    query {
      authLogout {
        id
      }
    }
  `;
  return new Promise((resolve, reject) => {
    client()()
      .request(query, variables)
      .then((data) => resolve(data?.['authLogout']))
      .catch((error) => reject(error));
  });
}

export function refreshTokenRequest(props = {}) {
  let variables = { ...props };
  const query = gql`
    query {
      authRefreshToken
    }
  `;
  return new Promise((resolve, reject) => {
    client()()
      .request(query, variables)
      .then((data) => resolve(data?.['authRefreshToken']))
      .catch((error) => reject(error));
  });
}

export function authPasswordPolicy(props = {}) {
  let variables = { ...props };
  const query = gql`
    query {
      authPasswordPolicy
    }
  `;
  return new Promise((resolve, reject) => {
    client()()
      .request(query, variables)
      .then((data) => resolve(data?.['authPasswordPolicy']))
      .catch((error) => reject(error));
  });
}

export function ssoAvailable() {
  const query = gql`
    query {
      ssoGetSettings {
        available {
          status
          messages {
            type
            text
          }
        }
      }
    }
  `;
  return new Promise((resolve, reject) => {
    client()(undefined, {})
      .request(query)
      .then((data) => resolve(data?.['ssoGetSettings']))
      /*.then((data) =>
        resolve({
          available: {
            status: false,
            messages: [
              {
                type: 'success',
                text: 'Сервер доступен.',
              },
            ],
          },
        }),
      )*/
      .catch((error) => reject(error));
  });
}

export function ssoGetSettings() {
  const query = gql`
    query {
      ssoGetSettings {
        realm
        url
        clientId
        backendHost
        backendClientId
      }
    }
  `;
  return new Promise((resolve, reject) => {
    client()(undefined, {})
      .request(query)
      .then((data) => resolve(data?.['ssoGetSettings']))
      /*.then((data) =>
        resolve({
          finish: false,
          realm: 'MESoneDEV',
          url: '/auth',
          clientId: 'webUI',
          backendHost: 'http://192.168.3.221:8080',
          backendClientId: 'back-end-client',
        }),
      )*/
      .catch((error) => reject(error));
  });
}

export function ssoTestConnection({ url }) {
  let variables = { url };
  const query = gql`
    query ($url: String!) {
      ssoTestConnection(url: $url)
    }
  `;
  return new Promise((resolve, reject) => {
    client()()
      .request(query, variables)
      .then((data) => resolve(data?.['ssoTestConnection']))
      .catch((error) => reject(error));
  });
}

export function ssoTestService() {
  let variables = {};
  const query = gql`
    query {
      ssoTestService
    }
  `;
  return new Promise((resolve, reject) => {
    client()()
      .request(query, variables)
      .then((data) => resolve(data?.['ssoTestService']))
      .catch((error) => reject(error));
  });
}

export function groups() {
  let variables = {};
  const query = gql`
    query {
      groups {
        id
        name
        roles {
          id
          name
        }
      }
    }
  `;
  return new Promise((resolve, reject) => {
    client()()
      .request(query, variables)
      .then((data) => resolve(data?.['groups']))
      .catch((error) => reject(error));
  });
}

export function users() {
  let variables = {};
  const query = gql`
    query {
      users {
        id
        enabled
        username
        firstName
        lastName
        email
        groups {
          id
          name
        }
        roles {
          id
          name
        }
        tools {
          id
          name
        }
      }
    }
  `;
  return new Promise((resolve, reject) => {
    client()()
      .request(query, variables)
      .then((data) => resolve(data?.['users']))
      .catch((error) => reject(error));
  });
}

export function ssoGetSystemInfo() {
  const query = gql`
    query {
      ssoGetSystemInfo {
        fileEncoding
        javaHome
        javaRuntime
        javaVendor
        javaVersion
        javaVm
        javaVmVersion
        osArchitecture
        osName
        osVersion
        serverTime
        uptime
        uptimeMillis
        userDir
        userLocale
        userName
        userTimezone
        version
      }
    }
  `;

  return new Promise((resolve, reject) => {
    client()()
      .request(query)
      .then((data) => resolve(data?.['ssoGetSystemInfo']))
      .catch((error) => reject(error));
  });
}
