import { state } from '@/models/grafana/state';
import { effects } from '@/models/grafana/effects';
import { subscriptions } from '@/models/grafana/subscriptions';
import { reducers } from '@/models/grafana/reducers';

export default {
  namespace: 'grafana',
  state,
  effects,
  subscriptions,
  reducers,
};
