import { client } from './client';
import { gql } from 'graphql-request';
import axios from 'axios';

export function testApiServer() {
  let variables = {};
  const query = gql`
    query {
      __schema {
        __typename
      }
    }
  `;
  return new Promise((resolve, reject) => {
    client()(undefined, { headers: {} })
      .request(query, variables)
      .then((data) => resolve(data?.['__schema']))
      .catch((error) => reject(error));
  });
}

export function getVersion() {
  let variables = {};
  const query = gql`
    query {
      getVersion
    }
  `;
  return new Promise((resolve, reject) => {
    client()()
      .request(query, variables)
      .then((data) => resolve(data?.['getVersion']))
      .catch((error) => reject(error));
  });
}

export function getServerTime() {
  let variables = { type: 'now' };
  const query = gql`
    query ($type: DateType!) {
      getServerTime(type: $type)
    }
  `;
  return new Promise((resolve, reject) => {
    client()()
      .request(query, variables)
      .then((data) => resolve(data?.['getServerTime']))
      .catch((error) => reject(error));
  });
}

export function testUrlBackend(url) {
  let variables = { url };
  const query = gql`
    query ($url: String) {
      testUrlBackend(url: $url)
    }
  `;
  return new Promise((resolve, reject) => {
    client()()
      .request(query, variables)
      .then((data) => resolve(data?.['testUrlBackend']))
      .catch((error) => reject(error));
  });
}

function ping(url, pong) {
  let started = new Date().getTime();

  let http = new XMLHttpRequest();

  http.open('GET', url, /*async*/ true);
  http.setRequestHeader('Access-Control-Allow-Origin', '*');
  http.onreadystatechange = function () {
    if (http.readyState == 4) {
      console.log('ping true', http);
      let ended = new Date().getTime();

      let milliseconds = ended - started;

      if (pong != null) {
        pong(milliseconds);
      }
    }
  };
  try {
    http.send(null);
  } catch (exception) {}
}

export function testUrlFrontend(url, headers = undefined) {
  let headersInit = {
    method: 'GET',
    path: '/auth',
    scheme: 'https',
    'Content-Type': 'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers':
      'access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,referrer-policy,x-content-type-options,x-frame-options,x-xss-protection',
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, HEAD, OPTIONS',
    accept: 'application/json, text/plain, */*',
    'access-control-allow-origin': '*',
    'referrer-policy': 'no-referrer',
    'x-content-type-options': 'nosniff',
    'x-frame-options': 'SAMEORIGIN',
    'x-xss-protection': '1; mode=block',
  };
  return axios.get(url, {
    headers: headers || headersInit,
  });
}
