/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */

const canRole = (roles, role) => roles.includes(role);

export default function access(initialState) {
  let roles = initialState?.userInfo?.realm_access?.roles;
  return {
    canAdmin: canRole(roles || [], 'admin'),
    canSystem: canRole(roles || [], 'system'),
    canInstaller: canRole(roles || [], 'installer'),
    isError: canRole(roles || [], 'sso-error'),
  };
}
