import { routes } from '@/utils/routes/path';
import { systemDbGetSettingsAsync } from '@/models/systemDb/actions';

export const subscriptions = {
  setup({ dispatch, history }) {
    history.listen(({ pathname }) => {
      if (
        pathname === routes.tools.system.systemDbSettings ||
        pathname === `${routes.tools.system.systemDbSettings}/`
      ) {
        dispatch(systemDbGetSettingsAsync());
      }
    });
  },
};
