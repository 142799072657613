import authService from '@/services/auth';
import { testApiServer } from '@/requests/app';
import { ssoAvailable, ssoGetSettings } from '@/requests/auth';
import KeyCloakService from '@/services/keycloak';

const borderVisible = false;
export const border = borderVisible ? { border: '1px solid #aaa' } : {};

export async function apiServiceStatus() {
  return await testApiServer().then(
    (resolve) => {
      return { status: true };
    },
    (reject) => {
      return { status: false };
    },
  );
}

export async function authServiceStatus() {
  return await ssoAvailable().then(
    (resolve) => {
      return resolve?.available;
    },
    (reject) => {
      return {};
    },
  );
}

export async function authServiceSettings() {
  return await ssoGetSettings().then(
    (resolve) => {
      return resolve;
    },
    (reject) => {
      return {};
    },
  );
}

async function init() {
  let api = await apiServiceStatus();
  let auth = await authServiceStatus();

  let userInfo = {};
  let currentUser = {};

  if (api.status) {
    let ssoSettings = await ssoGetSettings();
    let settings = {
      api: api,
      auth: auth,
      finish: { status: ssoSettings.finish },
      settings: { ...ssoSettings },
    };
    await authService.init(settings);
    userInfo = await authService.getTokenInfo();
    currentUser = await authService.getTokenInfo();
  }

  return {
    settings: {},
    services: {
      api,
      auth,
    },
    userInfo,
    currentUser,
  };
}

function status() {
  let kcSettings = KeyCloakService.getSettings();
  kcSettings.finish.status = true;
  kcSettings.settings = {...kcSettings.settings, finish: true};

  return {
    settings: kcSettings,
    services: {
      status:
        kcSettings.api.status === true &&
        kcSettings.auth?.status === true,
      api: { status: kcSettings?.api?.status || false },
      auth: { status: kcSettings?.auth?.status || false },
    },
    finish: kcSettings.finish,
  };
}

const app = {
  init,
  status,
  apiServiceStatus,
  authServiceStatus,
  authServiceSettings,
};

export default app;
