import type { TFolderTreeItem, TFolder } from '@/types/store/fileManager';
import { TFileInfo } from '@/components/common/data-input/DataInputJsonb/types';

export const getFolderTreeAsync = (payload?: { toolName: string }) => ({
  type: 'fileManager/getFolderTreeAsync',
  payload,
});

export const getSelectedDirContentAsync = (payload: { toolName: string; path: string }) => {
  return {
    type: 'fileManager/getSelectedDirContentAsync',
    payload,
  };
};

export const deleteFolderAsync = (payload: { toolName: string; path: string }) => {
  return {
    type: 'fileManager/deleteFolderAsync',
    payload,
  };
};

export const createFolderAsync = (payload: { toolName: string; path: string }) => {
  return {
    type: 'fileManager/createFolderAsync',
    payload,
  };
};

export const addFileAsync = (payload: { toolName: string; path: string; file: File }) => {
  return {
    type: 'fileManager/addFileAsync',
    payload,
  };
};

export const addFileDynamicAsync = (payload: { file: File }) => {
  return {
    type: 'fileManager/addFileDynamicAsync',
    payload,
  };
};

export const deleteFileAsync = (payload: { toolName: string; path: string }) => {
  return {
    type: 'fileManager/deleteFileAsync',
    payload,
  };
};

export const _setFolderTree = (payload: TFolderTreeItem) => ({ type: 'setFolderTree', payload });

export const _setError = (payload: string) => ({ type: 'setError', payload });

export const _setSelectedFolder = (payload: TFolder) => ({ type: 'setSelectedFolder', payload });

export const _setUploadedFileInfo = (payload: TFileInfo | null) => ({ type: 'setUploadedFileInfo', payload });

export const setUploadedFileInfo = (payload: TFileInfo | null) => ({ type: 'fileManager/setUploadedFileInfo', payload });
